/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ScrollToTop from "../ScrollToTop";
import { useFormik } from "formik";
import FormLoader from "../../../common/FormLoader";
import Config from "../../../API/Config";
import {
  ArtistGetById,
  UpdateArtistInfById,
  UpdateModelInfById,
} from "../../../API/ArtistSignUpAPI";
import { useData } from "../../../Context/DataContext ";
import { format } from "date-fns";
import usePageSEO from "../../usepageSEO/Index";
import { getAllCategory } from "../../../API/CategoryAPi";
import { getAllCollbration } from "../../../API/CollabrationAPi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import userImage from "../../../Images/usericon.png";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import axios from "axios";

const ArtistProfile = () => {
  usePageSEO("Viral kar | Dashboard", "Viral kar | Dashboard", [
    "Viral kar | Dashboard",
  ]);
  
  const [ThumbImagePreview, setThumbImagePreview] = useState();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const Id = Config.getId(); // Assuming Config is defined and imported properly
  const Status = Config.getStatus();
  const { CityData } = useData(); // Assuming useData provides the necessary data
  const navigate = useNavigate();
  const [countryid, setCountryid] = useState(null);
  const [stateid, setStateId] = useState(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://admin.viralkar.com/front/api/all-country",
      );
      setCountries(response.data.responsedata);
      console.log(response)
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  console.log(countryid);
  

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-state/${countryId}`,
      );
      setStates(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-cities/${stateId}`,
      );
      setCities(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };
  
  // Formik Initialization
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      DOB: "",
      Gender: "",
      CityId: "",
      Instagram: "",
      Facebook: "",
      Youtube: "",
      Twitter: "",
      Linkedin: "",
      Other: "",
      ProfileImage: "",
      HidImg: "",
      Category: [],
      CollaborationNames: [],
      CollaborationsType: [],
      Bio: "",
      Experience: "",
      PreviousProjects: "",
      Awards: "",
      NotableWorks: "",
      CollaborationIndustries: "",
      AvailableforTravel: "",
      Exhibitions: "",
      VideoUrl: "",
      Website: "",
    },
  
    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        // Append form values to formData as needed
        for (const key in values) {
          formData.append(key, values[key]);
        }
        
        const result = await UpdateArtistInfById(Id, formData);
        console.log(result); 
        await FatchData(); // Refetch data after submission to refresh form values
  
        // Show success message
        Swal.fire({
          title: 'Success!',
          text: 'Artist information updated successfully.',
          icon: 'success',
          confirmButtonText: 'Okay'
        });
  
      } catch (error) {
        console.error("Error updating model information:", error);
        // Show error message
        Swal.fire({
          title: 'Error!',
          text: 'Failed to update artist information. Please try again.',
          icon: 'error',
          confirmButtonText: 'Okay'
        });
      } finally {
        setIsFormLoading(false);
      }
    },
  });
  
  // Fetch data function
  const FatchData = async () => {
    try {
      const result = await ArtistGetById(Id);
  
      // Check and set profile image
      if (result.ProfileImage) {
        setThumbImagePreview(result.Path + result.ProfileImage);
      }
  
      // Format the date correctly for the form
      const parsedDate = result.DOB ? new Date(result.DOB) : new Date();
      const formattedDate = format(parsedDate, "yyyy-MM-dd");
  
      // Set formik values with the fetched data
      formik.setValues({
        Name: result.Name || "",
        Email: result.Email || "",
        Phone: result.Phone || "",
        DOB: formattedDate || "",
        Gender: result.Gender || "",
        CityName: result.CityName || "",
        Instagram: result.Instagram || "",
        Facebook: result.Facebook || "",
        Youtube: result.YouTube || "",
        Twitter: result.Twitter || "",
        Linkedin: result.Linkedin || "",
        Other: result.Other || "",
        ProfileImage: result.ProfileImage || "",
        HidImg: result.Hid_Image || "",
        Category: result.Category || [],
        CollaborationNames: result.CollaborationNames || "",
        CollaborationsType: result.CollaborationsType || [],
        Bio: result.Bio || "",
        Experience: result.Experience || "",
        PreviousProjects: result.PreviousProjects || "",
        Awards: result.Awards || "",
        NotableWorks: result.NotableWorks || "",
        CollaborationIndustries: result.CollaborationIndustries || "",
        Exhibitions: result.Exhibitions || "",
        VideoUrl: result.VideoUrl || "",
        Website: result.Website || "",
        AvailableforTravel: result.AvailableforTravel || "",
      });
      
  
      if (result.Country) {
        setCountryid(result.Country);
        formik.setFieldValue("Country", result.Country);
        fetchStates(result.Country);
        console.log("Country set:", result.Country);
      }
      if (result.State) {
        setStateId(result.State);
        formik.setFieldValue("State", result.State);
        fetchCities(result.State);
        console.log("State set:", result.State);
      }
      if (result.City) {
        formik.setFieldValue("City", result.City);
        console.log("City set:", result.City);
      }
      sessionStorage.setItem("getvendorStatus", result.Status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    FatchData();
    fetchCountries()
  }, [Id]);
  
  useEffect(() => {
    if (ThumbImagePreview) {
      sessionStorage.setItem("ProfileImage", ThumbImagePreview);
    }
  }, [ThumbImagePreview]);
  
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResult] = await Promise.all([getAllCategory()]);
        setCategoryData(categoryResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  
  const [otherCategory, setOtherCategory] = useState("");
  
  const handleCheckboxChange = async (event) => {
    const { value, checked } = event.target;
    const { Category } = formik.values;
  
    const stringValue = value;
  
    if (checked) {
      if (!Category.includes(stringValue) && Category.length < 5) {
        formik.setFieldValue("Category", [...Category, stringValue]);
      }
    } else {
      formik.setFieldValue(
        "Category",
        Category.filter((category) => category !== stringValue),
      );
    }
  
    await formik.validateField("Category");
  };
  
  const [CollaborationData, setCollaborationData] = useState([]);
  useEffect(() => {
    const fetchCollaborationData = async () => {
      try {
        const [CollaborationResult] = await Promise.all([getAllCollbration()]);
        setCollaborationData(CollaborationResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCollaborationData();
  }, []);
  
  const handleLanguageCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const { CollaborationsType } = formik.values;
  
    if (checked) {
      if (!CollaborationsType.includes(value)) {
        formik.setFieldValue("CollaborationsType", [
          ...CollaborationsType,
          value,
        ]);
      }
    } else {
      const newCollaborationsType = CollaborationsType.filter(
        (id) => id !== value,
      );
      if (value === "Other") {
        formik.setFieldValue("OtherCollaborationsType", "");
      }
      formik.setFieldValue("CollaborationsType", newCollaborationsType);
    }
  };
  
  // Profile Image Section
  
  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        navigate("/"); // Redirect to home after logout
      }
    });
  };
  
  const location = useLocation();
  const getPathByType = (type) => {
    switch (type) {
      case "I":
        return "/profile/influencer"; // Path for Influencer
      case "M":
        return "/profile/model"; // Path for Model
      case "A":
        return "/profile/artist"; // Path for Artist
      default:
        return "/profileview"; // Default path if Type does not match
    }
  };
  const type = Config.getType();
  const menuItems = [{ path: getPathByType(type), label: "Profile" }];
  
  const isActive = (path) => {
    return location.pathname === path;
  };
   


  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="">
        <ScrollToTop />
        <div className="container mx-auto py-20">
          <div className="grid grid-cols-1 lg:grid-cols-4 md:gap-10 px-2 md:px-0">
            <div className="">
              <div className="mb-5 md:mb-0 sticky top-24">
                <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                  <ul className="flex mb-5 list-none flex-col" role="tablist">
                    <img
                      src={
                        ThumbImagePreview === "undefined"
                          ? userImage
                          : ThumbImagePreview
                      }
                      alt="User Profile"
                      className="rounded-full h-32 w-32 mb-5 shadow-2xl p-2 mx-auto border object-cover object-top"
                    />
                    {menuItems.map((item, index) => (
                      <li
                        key={index}
                        className={`last:mr-0 text-center border dark:border-zinc-600 my-1.5 ${
                          isActive(item.path) ? "bg-themecolor1 text-white" : ""
                        }`}
                      >
                        <Link
                          to={item.path}
                          className={`text-xs font-bold uppercase px-0 py-4 border-none block leading-normal ${
                            isActive(item.path)
                              ? "bg-themecolor1 text-white"
                              : ""
                          }`}
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                    <li className="last:mr-0 my-1.5 text-center border dark:border-zinc-600 cursor-pointer">
                      <div
                        onClick={logout}
                        className="text-xs font-bold uppercase px-0 py-4 border-none block leading-normal"
                      >
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-span-3">
              <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
                <div className="bg-dark-light">
                  <form onSubmit={formik.handleSubmit}>
                    <input
                      type="hidden"
                      name="HidImg"
                      value={formik.values.HidImg}
                    />
                    <div className="">
                      <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                        {Status === 0 ? (
                          <div
                            className="bg-orange-100 border-t-2 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                            role="alert"
                          >
                            <div className="flex justify-center">
                              <p className="font-semibold text-sm">
                                Your Profile is Under Review
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                            role="alert"
                          >
                            <div className="flex justify-center">
                              <p className="font-bold">Your Profile is Approved</p>
                            </div>
                          </div>
                        )}
                        <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                          Details
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          <div className="my-1">
                            <label className="text-base" htmlFor="">
                              Name
                            </label>
                            <input
                              type="text"
                              name="Name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Name}
                              placeholder="Name"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Name && formik.errors.Name ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Name}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label htmlFor="">Email</label>
                            <input
                              type="email"
                              name="Email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Email}
                              disabled
                              placeholder="Email Address"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Email && formik.errors.Email ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Email}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label htmlFor="">Phone</label>
                            <input
                              type="text"
                              name="Phone"
                              disabled
                              readOnly
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Phone}
                              placeholder="Phone"
                              className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Phone && formik.errors.Phone ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Phone}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label htmlFor="">DOB</label>
                            <input
                              type="date"
                              name="DOB"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.DOB}
                              placeholder="DOB"
                              className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.DOB && formik.errors.DOB ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.DOB}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label htmlFor="">Profile Picture </label>
                            <input
                              type="file"
                              name="ProfileImage"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "ProfileImage",
                                  event.currentTarget.files[0],
                                );
                                setThumbImagePreview(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0],
                                  ),
                                ); // Show a preview of the new image
                              }}
                              onBlur={formik.handleBlur}
                              className="text-sm text-grey-500 file:mr-5 mt-3 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold file:text-white
                                file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none border-0 rounded"
                            />
                            {formik.touched.ProfileImage &&
                            formik.errors.ProfileImage ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.ProfileImage}
                              </div>
                            ) : null}
                          </div>
 

                          <div className="my-1">
                            <label className="text-base">Gender</label>
                            <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                              <div className="mr-3">
                                <input
                                  type="radio"
                                  id="Male"
                                  name="Gender"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value="M"
                                  checked={formik.values.Gender === "M"}
                                  className="mr-2"
                                />
                                <label htmlFor="Male">Male</label>
                              </div>
                              <div className="mr-3">
                                <input
                                  type="radio"
                                  id="Female"
                                  name="Gender"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value="F"
                                  checked={formik.values.Gender === "F"}
                                  className="mr-3"
                                />
                                <label htmlFor="Female">Female</label>
                              </div>
                              <div className="">
                                <input
                                  type="radio"
                                  id="Other"
                                  name="Gender"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value="O"
                                  checked={formik.values.Gender === "O"}
                                  className="mr-3"
                                />
                                <label htmlFor="Other">Other</label>
                              </div>
                            </div>

                            {formik.touched.Gender && formik.errors.Gender ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Gender}
                              </div>
                            ) : null}
                          </div>

                          {/* Country Dropdown */}
                          <select
                            className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            name="Country"
                            value={formik.values.Country || ""}
                            onChange={(e) => {
                              const countryId = e.target.value;
                              formik.setFieldValue("Country", countryId);  // Set the selected country ID in Formik
                              setCountryid(countryId);  // Update the state to track selected country
                              fetchStates(countryId);  // Fetch states for the selected country
                            }}
                          >
                            <option value="" label="Select Country" />
                            {countries.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                          {formik.errors.Country && <div className="text-red-500">{formik.errors.Country}</div>}

                          {/* State Dropdown */}
                          
                          <select
                            className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            name="State"
                            value={formik.values.State || ""}
                            onChange={(e) => {
                              const stateId = e.target.value;
                              formik.setFieldValue("State", stateId);  // Set the selected state ID in Formik
                              setStateId(stateId);  // Update the state to track selected state
                              fetchCities(stateId);  // Fetch cities for the selected state
                            }}
                            disabled={!countryid}  // Disable if country is not selected
                          >{}
                            <option value="" label="Select State" />
                            {states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                          {formik.errors.State && <div className="text-red-500">{formik.errors.State}</div>}

                          {/* City Dropdown */}
                          <select
                            className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            name="City"
                            value={formik.values.City || ""}
                            onChange={(e) => formik.setFieldValue("City", e.target.value)}  // Set the selected city in Formik
                            disabled={!stateid}  // Disable if state is not selected
                          >
                            <option value="" label="Select City" />
                            {cities.map((city) => (
                              <option key={city.id} value={city.id}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                          {formik.errors.City && <div className="text-red-500">{formik.errors.City}</div>}
                        </div>
                        <div>
                          <p className="text-center text-xl py-1.5 my-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                            Choose Your Category
                          </p>

                          <div className="grid grid-cols-1 md:grid-cols-4">
                            {categoryData[2]?.ChildCat?.map((val, index) => (
                              <div
                                className="flex items-center my-2"
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  name="Category"
                                  value={val.Id}
                                  checked={formik.values.Category.includes(
                                    val.Id.toString(),
                                  )}
                                  id={val.Title}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="text-sm text-gray-500 font-normal"
                                  htmlFor={val.Title}
                                >
                                  {val.Title}
                                </label>
                              </div>
                            ))}

                            {/* Input for Other Category */}

                            <div className="flex items-center my-2">
                              <input
                                type="checkbox"
                                className="mr-2"
                                name="Category"
                                value="Other"
                                checked={formik.values.Category.includes(
                                  "Other",
                                )}
                                id="Other"
                                onChange={handleCheckboxChange} // You can manage the "Other" checkbox separately if needed
                              />
                              <label
                                className="text-sm text-gray-500 font-normal"
                                htmlFor="Other"
                              >
                                Other
                              </label>
                            </div>
                          </div>

                          <div className="mt-4">
                            {formik.values.Category.includes("Other") && (
                              <div className="grid items-center ">
                                <input
                                  type="text"
                                  className="border border-gray-300 rounded-md p-2"
                                  placeholder="Please specify"
                                  value={otherCategory}
                                  onChange={(e) => {
                                    setOtherCategory(e.target.value);
                                    formik.setFieldValue(
                                      "OtherCategory",
                                      e.target.value,
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-2 mb-2">
                          <label className="text-base" htmlFor="">
                            Artistic Description/Bio
                          </label>
                          <textarea
                            type="text"
                            name="Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Bio}
                            placeholder="Bio"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                        </div>
                        <p className="text-center text-xl py-1.5 my-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                          Experience and Achievements
                        </p>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                          <div className=" ">
                            <div className="mt-2 mb-2">
                              <label className="text-base" htmlFor="">
                                Years of Experience
                              </label>
                              <input
                                type="text"
                                name="Experience"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Experience}
                                placeholder="Experience"
                                className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              />
                            </div>
                          </div>

                          <div className="">
                            <div className="mt-2 mb-2">
                              <label className="text-base" htmlFor="">
                                Previous Collaborations/Projects
                              </label>
                              <textarea
                                type="text"
                                name="PreviousProjects"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.PreviousProjects}
                                placeholder="PreviousProjects"
                                className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              />
                            </div>
                          </div>

                          <div className="">
                            <div className="mt-2 mb-2">
                              <label className="text-base" htmlFor="">
                                Awards and Recognitions
                              </label>
                              <textarea
                                type="text"
                                name="Awards"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Awards}
                                placeholder="Awards"
                                className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              />
                            </div>
                          </div>

                          <div className="">
                            <div className="mt-2 mb-2">
                              <label className="text-base" htmlFor="">
                                Notable Works
                              </label>
                              <textarea
                                type="text"
                                name="NotableWorks"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.NotableWorks}
                                placeholder="NotableWorks"
                                className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              />
                            </div>
                          </div>
                        </div>

                        <p className="text-center text-xl py-1.5 my-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                          Portfolio Information
                        </p>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                          <div className="my-1">
                            <label className="text-base">
                              Portfolio Website/Blog URL
                            </label>
                            <input
                              type="text"
                              name="Website"
                              value={formik.values.Website}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Please Enter Portfolio Website/Blog URL"
                              className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                            />
                          </div>

                          <div className="my-1">
                            <label className="text-base">
                              Showreel/Video Link
                            </label>
                            <input
                              type="text"
                              name="VideoUrl"
                              value={formik.values.VideoUrl}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Please Enter Showreel/Video Link"
                              className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                            />
                          </div>

                          <div className="my-1">
                            <label className="text-base">Exhibitions</label>
                            <input
                              type="text"
                              name="Exhibitions"
                              value={formik.values.Exhibitions}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Please Enter Exhibitions"
                              className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                            />
                            {formik.touched.Exhibitions &&
                            formik.errors.Exhibitions ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Exhibitions}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <p className="text-center text-xl py-1.5 my-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                          Collaboration Preferences
                        </p>

                        {/* Collaboration Types */}
                        <div className="my-1">
                          <div className="grid grid-cols-1 md:grid-cols-4">
                            {CollaborationData[2]?.ChildCat?.map((val) => (
                              <div
                                className="flex items-center my-1"
                                key={val.Id}
                              >
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  name="CollaborationsType"
                                  value={val.Id}
                                  checked={formik.values.CollaborationsType.includes(
                                    val.Id.toString(),
                                  )}
                                  id={`collab-${val.Id}`}
                                  onChange={handleLanguageCheckboxChange}
                                />
                                <label
                                  className="text-sm text-gray-500 font-normal"
                                  htmlFor={`collab-${val.Id}`}
                                >
                                  {val.Title}
                                </label>
                              </div>
                            ))}
                            <div className="flex items-center my-1">
                              <input
                                type="checkbox"
                                className="mr-2"
                                name="CollaborationsType"
                                value="Other"
                                id="collab-other"
                                checked={formik.values.CollaborationsType.includes(
                                  "Other",
                                )}
                                onChange={handleLanguageCheckboxChange}
                              />
                              <label
                                className="text-sm text-gray-500 font-normal"
                                htmlFor="collab-other"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                        {formik.touched.CollaborationsType &&
                          formik.errors.CollaborationsType && (
                            <div className="text-red-500 text-sm">
                              {formik.errors.CollaborationsType}
                            </div>
                          )}
                        {formik.values.CollaborationsType.includes("Other") && (
                          <div className="my-2">
                            <input
                              type="text"
                              placeholder="Please specify"
                              className={`w-full border rounded-lg p-2 ${
                                formik.touched.OtherCollaborationsType
                                  ? "border-gray-300"
                                  : "border-gray-300"
                              }`}
                              onChange={formik.handleChange}
                              value={formik.values.OtherCollaborationsType}
                              name="OtherCollaborationsType"
                            />
                          </div>
                        )}

                        <div className="grid grid-cols-1 lg:grid-cols-2 my-4">
                          <div className="my-auto">
                            <div className="my-1">
                              <label className="text-base">
                                Available for Travel{" "}
                              </label>
                              <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                                {["Yes", "No"].map((option) => (
                                  <div className="mr-3" key={option}>
                                    <input
                                      type="radio"
                                      id={`available-${option}`}
                                      name="AvailableforTravel"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={option}
                                      checked={
                                        formik.values.AvailableforTravel ===
                                        option
                                      }
                                      className="mr-2"
                                    />
                                    <label htmlFor={`available-${option}`}>
                                      {option}
                                    </label>
                                  </div>
                                ))}
                              </div>
                              {formik.touched.AvailableforTravel &&
                              formik.errors.AvailableforTravel ? (
                                <div className="text-red-500 text-sm">
                                  {formik.errors.AvailableforTravel}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="">
                            <div className="">
                              <div className="mt-2 mb-2">
                                <label className="text-base" htmlFor="">
                                  Preferred Brands/Industries
                                </label>
                                <input
                                  type="text"
                                  name="CollaborationIndustries"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.CollaborationIndustries}
                                  placeholder="CollaborationIndustries"
                                  className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg mt-4">
                          Social Media Details
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                          <div className="my-1">
                            <label htmlFor="">Instagram</label>
                            <input
                              type="text"
                              name="Instagram"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Instagram}
                              placeholder="Instagram"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Instagram &&
                            formik.errors.Instagram ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Instagram}
                              </div>
                            ) : null}
                          </div>

                          <div className="my-1">
                            <label htmlFor="">Youtube</label>
                            <input
                              type="text"
                              name="Youtube"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Youtube}
                              placeholder="Youtube"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Youtube && formik.errors.Youtube ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Youtube}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label htmlFor="">Facebook</label>
                            <input
                              type="text"
                              name="Facebook"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Facebook}
                              placeholder="Facebook"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Facebook &&
                            formik.errors.Facebook ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Facebook}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label htmlFor="">Twitter</label>
                            <input
                              type="text"
                              name="Twitter"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Twitter}
                              placeholder="Twitter"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Twitter && formik.errors.Twitter ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Twitter}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label htmlFor="">Linkedin</label>
                            <input
                              type="text"
                              name="Linkedin"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Linkedin}
                              placeholder="Linkedin"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Linkedin &&
                            formik.errors.Linkedin ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Linkedin}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label htmlFor="">Other</label>
                            <input
                              type="text"
                              name="Other"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Other}
                              placeholder="Other"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Other && formik.errors.Other ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Other}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex justify-center my-3">
                          <button
                            type="submit"
                            className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-10"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistProfile;
