import React, { useEffect, useMemo, useState } from 'react'
import { useData } from '../../Context/DataContext ';
import { useLocation, useParams } from 'react-router';
import titleimage from "../../Images/title-icon.png";
import ClipLoader from "react-spinners/FadeLoader";
import { RxCross2 } from "react-icons/rx";
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import Loader from '../../common/Loader';

const AllCities = () => {

    const { Slug } = useParams();
    const location = useLocation();
    const id = location.state?.id; // Get Id from state
    const breadcrumbItems = [
      {
        text: `${Slug}`,
      },
    ];
  
    const { CityData, loading } = useData();
    
    // State for search query and the debounced query
    const [isFocused, setIsFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  
    // Debounce the search query
    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedQuery(searchQuery);
      }, 500); // Delay of 500ms
  
      return () => clearTimeout(timer); // Cleanup on each keystroke
    }, [searchQuery]);
  
    // Filter the city data based on the debounced query
    const filteredCities = useMemo(() => {
      return CityData?.filter((city) =>
        city.Title.toLowerCase().includes(debouncedQuery.toLowerCase()),
      );
    }, [debouncedQuery, CityData]);
  
    // Handle search change
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };

    if (loading) {
      return <Loader />; // Show loader while loading data
    }
   

  return (
    <div>
         {/*================================ All City Show  =================================*/}

      <div className="container mx-auto mb-24">
        <div className="">
          <div className="bg-white dark:bg-darkmode lg:py-8 py-8">
            <div className="flex items-center justify-center flex-col">
              <p className="page-title text-center dark:text-white">
                Micro Influencers in India
              </p>
              <img src={titleimage} className="text-center title-icon" alt="" />
            </div>

          
            <div className="my-4 flex justify-end items-center surface:px-16 md:px-36 px-3`">
            <div className="relative w-fit h-fit">
              <input
                type="text"
                placeholder="Type to Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                className={`transition-all duration-500 ease-in-out ${
                  isFocused ? 'w-72 border border-black bg-transparent ps-4' : 'w-12 bg-themebgcolor3'
                } h-12 rounded-full pr-12 text-black placeholder-black placeholder-opacity-50 text-lg focus:outline-none`}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />

              <button
                className="absolute right-0 w-12 h-12 rounded-full text-white bg-transparent focus:outline-none transition-all duration-500 ease-in-out"
                onClick={() => setIsFocused(!isFocused)}
              >
                <FaSearch size={20} className="block m-auto" />
              </button>

              {searchQuery && (
                <button
                  onClick={() => setSearchQuery('')}
                  className="absolute right-14 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-white text-lg"
                >
                  <RxCross2 />
                </button>
              )}
            </div>
          </div>

            {loading ? (
              <div className="flex justify-center items-center my-40">
                <ClipLoader color={"#f59231"} loading={loading} size={40} />
              </div>
            ) : (
              <div className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-8 surface:px-16 md:px-36 px-3 lg:mt-16 my-5">
          {filteredCities?.filter((val) => val.IsMetro === 1) // Filter cities where IsMetro is 1
            .sort((a, b) => a.Title.localeCompare(b.Title)) // Sort cities by Title
            .map((val, index) => {
              return (
                <Link
                  key={index}
                  to={`/allcityshow/${val.Slug}`}
                  state={{ id: val.Id }}
                >
                  <div className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border">
                    <img
                      src={val.Icon}
                      className="w-full lg:h-36 h-32 rounded-t-xl"
                      alt=""
                    />
                    <p className="text-center py-3 text-black dark:text-white dark:bg-slate-900">
                      {val.Title}
                    </p>
                  </div>
                </Link>
              );
            })}
        </div>
            )}
          </div>
        </div>
      </div> 
    </div>
  )
}

export default AllCities