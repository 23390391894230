import { json } from "react-router";

const Config = {
  API_BASE_URL: "https://www.admin.viralkar.com/front/api",

  getId: () => {
    const sessiondata = sessionStorage.getItem("Id");
    const Id = sessiondata ? JSON.parse(sessiondata) : null;
    return Id;
  },
  getType: () => {
    const sessiondata = sessionStorage.getItem("Type");
    const Type = sessiondata ? JSON.parse(sessiondata) : null;
    return Type;
  },
  getToken: () => {
    const sessiondata = sessionStorage.getItem("Token");
    const Token = sessiondata ? JSON.parse(sessiondata) : null;
    return Token;
  },
  getUserName: () =>{
    const sessiondata = sessionStorage.setItem("UserName");
    const UserName = sessiondata ? JSON.parse(sessiondata): null;
    return UserName
  },
  getStatus: () => {
    const sessiondata = sessionStorage.getItem("Status");
    const Status = sessiondata ? JSON.parse(sessiondata):null;
    return Status
  },
  getvendorIdForTemp: () => {
    const sessiondata = sessionStorage.getItem("vendorIdForTemp");
    const vendorIdForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return vendorIdForTemp;
  },
  getvendorStatus: () => {
    const sessiondata = sessionStorage.getItem("getvendorStatus");
    const getvendorStatus = sessiondata ? JSON.parse(sessiondata) : null;
    return getvendorStatus;
  },
  getAdvStatus: () => {
    const sessiondata = sessionStorage.getItem("getAdvStatus");
    const getAdvStatus = sessiondata ? JSON.parse(sessiondata) : null;
    return getAdvStatus;
  },
  // getUserData: () => {
  //   const sessiondata = sessionStorage.getItem("UserData");
  //   const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
  //   return parsedSessionData;
  // },
  // getCitySlug: () => {
  //   const sessiondata = sessionStorage.getItem("selectedCitySlug");
  //   return sessiondata;
  // },
  // getCityTitle: () => {
  //   const sessiondata = sessionStorage.getItem("selectedCityTitle");
  //   return sessiondata;
  // },
  // getCityIdData: () => {
  //   const sessiondata = sessionStorage.getItem("selectedCityId");
  //   return sessiondata;
  // },
  // getCountryFlag: () => {
  //   const sessiondata = sessionStorage.getItem("CountryFlag");
  //   return sessiondata;
  // },
  // getCountrySlug: () => {
  //   const sessiondata = sessionStorage.getItem("CountrySlug");
  //   return sessiondata;
  // },
  // getCountryId: () => {
  //   const sessiondata = sessionStorage.getItem("CountryId");
  //   return sessiondata;
  // },

  // getKYCStatus: () => {
  //   const sessiondata = sessionStorage.getItem("KYCStatus");
  //   const Type = sessiondata ? JSON.parse(sessiondata) : null;
  //   return Type;
  // },

  // -------------------------new----------------------

  getInfluencerIdForTemp: () => {
    const sessiondata = sessionStorage.getItem("InfluencerIdForTemp");
    const InfluencerIdForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return InfluencerIdForTemp;
  },
  getInfluencerEmailForTemp: () => {
    const sessiondata = sessionStorage.getItem("InfluencerEmailForTemp");
    const InfluencerEmailForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return InfluencerEmailForTemp;
  },
  getInfluencerUserNameForTemp: () => {
    const sessiondata = sessionStorage.getItem("InfluencerUserNameForTemp");
    const InfluencerUserNameForTemp = sessiondata
      ? JSON.parse(sessiondata)
      : null;
    return InfluencerUserNameForTemp;
  },
  getInfluencerPhoneForTemp: () => {
    const sessiondata = sessionStorage.getItem("InfluencerPhoneForTemp");
    const InfluencerPhoneForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return InfluencerPhoneForTemp;
  },
  getInfluencerStep1ForTemp: () => {
    const sessiondata = sessionStorage.getItem("InfluencerStep1ForTemp");
    // const InfluencerStep1ForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return sessiondata;
  },
  getInfluencerStep2ForTemp: () => {
    const sessiondata = sessionStorage.getItem("InfluencerStep2ForTemp");
    // const InfluencerStep2ForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return sessiondata;
  },
  getInfluencerStep3ForTemp: () => {
    const sessiondata = sessionStorage.getItem("InfluencerStep3ForTemp");
    // const InfluencerStep3ForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return sessiondata;
  },

  // getId: () =>{
  //   const sessiondata = sessionStorage.getItem("Id");
  //   return sessiondata
  // },

  getInfluencerStatus: () =>{
    const sessiondata = sessionStorage.getItem("InfluencerStatus");
    return sessiondata
  },

  // -------------------------Model----------------------

  getModelIdForTemp: () => {
    const sessiondata = sessionStorage.getItem("ModelIdForTemp");
    const ModelIdForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return ModelIdForTemp;
  },
  getModelEmailForTemp: () => {
    const sessiondata = sessionStorage.getItem("ModelEmailForTemp");
    const ModelEmailForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return ModelEmailForTemp;
  },
  getModelUserNameForTemp: () => {
    const sessiondata = sessionStorage.getItem("ModelUserNameForTemp");
    const ModelUserNameForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return ModelUserNameForTemp;
  },
  getModelPhoneForTemp: () => {
    const sessiondata = sessionStorage.getItem("ModelPhoneForTemp");
    const ModelPhoneForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return ModelPhoneForTemp;
  },
  getModelStep1ForTemp: () => {
    const sessiondata = sessionStorage.getItem("ModelStep1ForTemp");
    // const ModelStep1ForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return sessiondata;
  },
  getModelStep2ForTemp: () => {
    const sessiondata = sessionStorage.getItem("ModelStep2ForTemp");
    // const ModelStep2ForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return sessiondata;
  },
  getModelStep3ForTemp: () => {
    const sessiondata = sessionStorage.getItem("ModelStep3ForTemp");
    // const ModelStep3ForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return sessiondata;
  },

  // -------------------------Artist----------------------

  getArtistIdForTemp: () => {
    const sessiondata = sessionStorage.getItem("ArtistIdForTemp");
    const ArtistIdForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return ArtistIdForTemp;
  },
  getArtistEmailForTemp: () => {
    const sessiondata = sessionStorage.getItem("ArtistEmailForTemp");
    const ArtistEmailForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return ArtistEmailForTemp;
  },
  getArtistUserNameForTemp: () => {
    const sessiondata = sessionStorage.getItem("ArtistUserNameForTemp");
    const ArtistUserNameForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return ArtistUserNameForTemp;
  },
  getArtistPhoneForTemp: () => {
    const sessiondata = sessionStorage.getItem("ArtistPhoneForTemp");
    const ArtistPhoneForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return ArtistPhoneForTemp;
  },
  getArtistStep1ForTemp: () => {
    const sessiondata = sessionStorage.getItem("ArtistStep1ForTemp");
    // const ArtistStep1ForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return sessiondata;
  },
  getArtistStep2ForTemp: () => {
    const sessiondata = sessionStorage.getItem("ArtistStep2ForTemp");
    // const ArtistStep2ForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return sessiondata;
  },
  getArtistStep3ForTemp: () => {
    const sessiondata = sessionStorage.getItem("ArtistStep3ForTemp");
    // const ArtistStep3ForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return sessiondata;
  },


  // ---------------------------------------- Advertiser---------------------------------

  getAdvertiserIdForTemp: () => {
    const sessiondata = sessionStorage.getItem("AdvertiserIdForTemp");
    const AdvertiserIdForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return AdvertiserIdForTemp;
  },

  getAdvertiserPhoneForTemp: () => {
    const sessiondata = sessionStorage.getItem("AdvertiserPhoneForTemp");
    const AdvertiserPhoneForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return AdvertiserPhoneForTemp;
  },
  getAdvertiserEmailForTemp: () => {
    const sessiondata = sessionStorage.getItem("AdvertiserEmailForTemp");
    const AdvertiserEmailForTemp = sessiondata ? JSON.parse(sessiondata) : null;
    return AdvertiserEmailForTemp;
  },

};

export default Config;
