import React, { useEffect, useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import { FaAddressCard, FaCalendar, FaUser } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { useNavigate } from "react-router";
import Config from "../../../../API/Config";
import { Link } from "react-router-dom";
import usePageSEO from "../../../usepageSEO/Index";
import { useData } from "../../../../Context/DataContext ";
import {InfluencerGetById, InfluencerStep2Add} from "../../../../API/InfluencerSignUpAPI";
import { getAllCategory } from "../../../../API/CategoryAPi";

const RegisterInfluencerNew2 = () => {
  usePageSEO(
    "Free Influencer Register - ViralKar",
    "Want more brand deals? Use our free influencer register to boost your visibility, connect with top companies, and grow your online presence fast.",
    [
      "Free influence Register, influence Register, Register as influencer, Register as influencer for free",
    ]
  );
  const [CategoryData, setCategoryData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResult] = await Promise.all([getAllCategory()]);
        setCategoryData(categoryResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  
  const languageData = [
    { Title: "English", Id: 1 },
    { Title: "Hindi", Id: 2 },
    { Title: "Gujarati", Id: 3 },
    { Title: "Marathi", Id: 4 },
    { Title: "Tamil", Id: 5 },
    { Title: "Telugu", Id: 6 },
    { Title: "Other", Id: 7 },
  ];
  
  const [isFormLoading, setIsFormLoading] = useState(false);
  const InfluencerId = Config.getInfluencerIdForTemp();
  const navigation = useNavigate();
  
  const validationSchema = Yup.object().shape({
    Category: Yup.array()
      .min(1, "Please select at least one category.")
      .max(5, "You can select up to 5 categories.")
      .required("Please select at least one category."),
    Language: Yup.array()
      .min(1, "Please select at least one language")
      .required("Please Enter Your Language selection"),
      OtherLanguage: Yup.string()
      .nullable()
      .matches(/^[A-Za-z\s]+$/, "Please enter a valid language"),
    ContentDescriptionBio: Yup.string().required("Content Description"),
    AgeRange: Yup.string().required("Please Enter Your Age Range"),
  //  Address: Yup.string().required("Please Enter Your City"),
    Gender: Yup.string().required("Please Enter Your Gender"),
    DOB: Yup.string().required("Please Enter Your Date of birth"),
    EngagementRate: Yup.number()
      .typeError("Engagement Rate must be a number")
      .required("Please Enter Your Average Engagement Rate"),
    FollowerCount: Yup.number()
      .typeError("Follower Count must be a number")
      .required("Please Enter Your Follower Count"),
  });
  
  const { CityData } = useData();
  
  const [InfluencerData, setInfluencerData] = useState([]);
  const InfluencerStep1 = Config.getInfluencerStep1ForTemp();
  
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await InfluencerGetById(InfluencerId);
  
        const languagesFromAPI =
          typeof response?.Languages === "string"
            ? response.Languages.split(",").map((item) => item.trim())
            : Array.isArray(response?.Languages)
            ? response.Languages
            : [];
  
        const languageTitles = languageData.map((lang) => lang.Title);
        const matchedLanguages = [];
        let OtherLanguage = "";
  
        languagesFromAPI.forEach((language) => {
          if (languageTitles.includes(language)) {
            matchedLanguages.push(language);
          } else {
            // Handle "Other" category
            OtherLanguage = language; // Set unmatched language to OtherLanguage
          }
        });
  
        if (OtherLanguage) {
          matchedLanguages.push("Other");
        }
  
        // Ensure Category is an array
        const categoryFromAPI =
        typeof response?.Category === "string" && response.Category.trim() !== ""
          ? response.Category.split(",").map((item) => item.trim())
          : [];
  
        setInfluencerData(response);
  
        sessionStorage.setItem(
          "InfluencerStep1ForTemp",
          JSON.stringify(response.Step1)
        );
        sessionStorage.setItem(
          "InfluencerStep2ForTemp",
          JSON.stringify(response.Step2)
        );
        sessionStorage.setItem(
          "InfluencerStep3ForTemp",
          JSON.stringify(response.Step3)
        );
  
        const dobFormatted = response?.DOB
          ? new Date(response.DOB).toISOString().split("T")[0]
          : "";
  
        formik.setValues({
          InfluencerId: response?.Id || "",
          Category: categoryFromAPI || [], // Ensure arrays are initialized
          Language: matchedLanguages || [], // Ensure arrays are initialized
          OtherLanguage: OtherLanguage || "", // Ensure arrays are initialized
          DOB: dobFormatted || "", // Ensure arrays are initialized
          ContentDescriptionBio: response?.ContentDescriptionBio || "", // Ensure arrays are initialized
          AgeRange: response?.AgeRange || "", // Ensure arrays are initialized
          //Address: response?.Address || 9, // Ensure arrays are initialized
          Gender: response?.Gender || "", // Ensure arrays are initialized
          EngagementRate: response?.EngagementRate || "", // Ensure arrays are initialized
          FollowerCount: response?.FollowerCount || "", // Ensure arrays are initialized
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [InfluencerId]);
  
  const formik = useFormik({
    initialValues: {
      InfluencerId: InfluencerId,
      Category: [],
      ContentDescriptionBio: "",
      Language: [],
      OtherLanguage: "",
      DOB: "",
      AgeRange: "",
     // Address: "",
      Gender: "",
      EngagementRate: "",
      FollowerCount: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setIsFormLoading(true);
  
      try {
        const finalValues = {
          ...values,
          Language: values.Language.includes("Other")
            ? [
                ...values.Language.filter((lang) => lang !== "Other"),
                values.OtherLanguage,
              ]
            : values.Language,
        };
        
        // Check if "Other" is selected in Language array
      if (values.Language.includes("Other") && !values.OtherLanguage) {
        formik.setFieldError("OtherLanguage", "Please specify the other language.");
        setIsFormLoading(false);
        return; // Stop form submission if validation fails
      }
  
        const result = await InfluencerStep2Add(finalValues);
  
        if (result.status === true) {
          navigation("/influencer/sign-up/influencer3");
          sessionStorage.setItem(
            "InfluencerEmailForTemp",
            JSON.stringify(InfluencerData.Email)
          );
          sessionStorage.setItem(
            "InfluencerUserNameForTemp",
            JSON.stringify(InfluencerData.UserName)
          );
          sessionStorage.setItem(
            "InfluencerPhoneForTemp",
            JSON.stringify(InfluencerData.Phone)
          );
          sessionStorage.setItem(
            "InfluencerStep1ForTemp",
            JSON.stringify(InfluencerData.Step1)
          );
          sessionStorage.setItem(
            "InfluencerStep1ForTemp",
            JSON.stringify(InfluencerData.Step2)
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });
  
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
  
    // Check if the checkbox is checked or unchecked
    if (checked) {
      formik.setFieldValue('Category', [...formik.values.Category, value]);
    } else {
      formik.setFieldValue(
        'Category',
        formik.values.Category.filter((val) => val !== value)
      );
    }
  };
  
  const handleLanguageCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const { Language = [] } = formik.values;
  
    if (value === "Other") {
      if (checked) {
        // Add "Other" to the Language array, but we will handle the value separately
        formik.setFieldValue("Language", [...Language, "Other"]);
        formik.setFieldValue("OtherLanguage", ""); // Clear OtherLanguage when "Other" is checked
      } else {
        // Remove "Other" from the Language array
        formik.setFieldValue(
          "Language",
          Language.filter((lang) => lang !== "Other")
        );
        formik.setFieldValue("OtherLanguage", ""); // Clear OtherLanguage when "Other" is unchecked
      }
    } else {
      if (checked) {
        // Add selected language to the Language array
        formik.setFieldValue("Language", [...Language, value]);
      } else {
        // Remove selected language from the Language array
        formik.setFieldValue(
          "Language",
          Language.filter((lang) => lang !== value)
        );
      }
    }
  };

  return (
    <div>
      <div className="bg-dark">
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-14 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center pb-2 dark:text-white capitalize">
              Unlock your influence register or log in to shine brighter
            </p>
            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <div className="relative flex items-center justify-between w-full my-10">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor dark:bg-white transition-all duration-500"></div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaUser className="w-5 h-5" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th bg-themecolor transition-all duration-300 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5 text-white" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5 text-black" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=" my-10 dark:border border-white"
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-3 bg-white dark:bg-slate-800 p-5 md:p-8 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border">
                <p className="text-center text-2xl pb-3 mb-3">
                  Content Information
                </p>
                <div className="rounded-lg shadow-sm duration-500 border px-4 py-4 mb-6">
                  <div className="mb-3">
                    <p>
                      Please select category <span className="text-red-500">*</span>
                    </p>
                    <small className="text-gray-400">
                      Select categories (Products or Services you can promote) (up to 5)
                    </small>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-4">
                    {CategoryData[0]?.ChildCat?.map((val, index) => (
                      <div className="flex items-center my-2" key={index}>
                        <input
                          type="checkbox"
                          className="mr-2"
                          name="Category"
                          value={val.Id}
                          checked={formik.values.Category.includes(val.Id.toString())}
                          id={val.Title}
                          onChange={handleCheckboxChange}
                        />
                        <label className="text-sm text-gray-500 font-normal" htmlFor={val.Title}>
                          {val.Title}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="pt-0">
                    {formik.touched.Category && formik.errors.Category && (
                      <div className="text-red-500 text-sm">{formik.errors.Category}</div>
                    )}
                  </div>
                </div>
                <div className="my-1">
                  <label className="text-base">
                    Content Description/Bio{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    type="text"
                    name="ContentDescriptionBio"
                    onChange={formik.handleChange}
                    value={formik.values.ContentDescriptionBio}
                    onBlur={formik.handleBlur}
                    placeholder="Please Enter Description/Bio"
                    className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                  ></textarea>
                  {formik.touched.ContentDescriptionBio &&
                  formik.errors.ContentDescriptionBio ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.ContentDescriptionBio}
                    </div>
                  ) : null}
                </div>
                <div className="rounded-lg shadow-sm duration-500 border px-4 py-4 mb-6 mt-5">
                  <div className="">
                    <p className="mb-2">
                      Please select Languages Spoken{" "}
                      <span className="text-red-500">*</span>
                    </p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-7">
                    {languageData?.map((val, index) => (
                      <div className="flex items-center my-1" key={index}>
                        <input
                          type="checkbox"
                          className="mr-2"
                          name="Language"
                          value={val.Title}
                          checked={formik.values.Language.includes(val.Title)}
                          id={val.Title}
                          onChange={handleLanguageCheckboxChange}
                        />
                        <label
                          className="text-sm text-gray-500 font-normal"
                          htmlFor={val.Title}
                        >
                          {val.Title}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="pt-0">
                    {formik.values.Language.includes("Other") && (
                      <div className="my-2">
                        <label className="text-base">
                          Specify Other Language
                        </label>
                        <input
                          type="text"
                          name="OtherLanguage"
                          value={formik.values.OtherLanguage}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Please specify"
                          className="px-4 py-2 w-full focus:outline-none my-1.5 border dark:bg-[#020617]"
                        />
                        {formik.touched.OtherLanguage &&
                        formik.errors.OtherLanguage ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.OtherLanguage}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  {formik.touched.Language && formik.errors.Language && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.Language}
                    </div>
                  )}
                </div>
                <p className="text-center text-2xl pb-3 my-3 border-b">
                  Audience Insights
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Date of Birth <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      name="DOB"
                      value={formik.values.DOB}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      max={new Date().toISOString().split("T")[0]} // Disable future dates
                      className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.DOB && formik.errors.DOB ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.DOB}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Age Range <span className="text-red-500">*</span>
                    </label>
                    <select
                      name="AgeRange"
                      value={formik.values.AgeRange}
                      onChange={formik.handleChange}
                      className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Please select Age Range</option>
                      <option value="0 to 12 years">0 to 12 years</option>
                      <option value="13 to 17 years">13 to 17 years</option>
                      <option value="18 to 30 years">18 to 30 years</option>
                      <option value="30 to 40 years">30 to 40 years</option>
                      <option value="40 and more">40 and more</option>
                    </select>
                    {formik.touched.AgeRange && formik.errors.AgeRange ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.AgeRange}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Gender <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center mt-1.5 px-2 py-2.5">
                      <div className="mr-3">
                        <input
                          type="radio"
                          id="Male"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="M"
                          className="mr-2"
                          checked={formik.values.Gender === "M"}
                        />
                        <label htmlFor="Male">Male</label>
                      </div>
                      <div className="">
                        <input
                          type="radio"
                          id="Female"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="F"
                          className="mr-3"
                          checked={formik.values.Gender === "F"}
                        />
                        <label htmlFor="Female">Female</label>
                      </div>
                    </div>
                    {formik.touched.Gender && formik.errors.Gender ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Gender}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="my-1">
                    <label className="text-base">
                      Select City <span className="text-red-500">*</span>{" "}
                    </label>
                    <select
                      name="Address"
                      value={formik.values.Address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    >
                      <option className="text-base">Select City</option>
                      {CityData?.map((val, index) => {
                        return (
                          <option key={index} value={val.Id}>
                            {val.Title}
                          </option>
                        );
                      })}
                    </select>
                    {formik.touched.Address && formik.errors.Address ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Address}
                      </div>
                    ) : null}
                  </div> */}
                  <div className="my-1">
                    <label className="text-base">
                      Your Average Engagement Rate{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.EngagementRate}
                      type="number"
                      name="EngagementRate"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Your Average Engagement Rate"
                      className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.EngagementRate &&
                    formik.errors.EngagementRate ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.EngagementRate}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Follower Count <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      name="FollowerCount"
                      value={formik.values.FollowerCount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Follower Count"
                      className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.FollowerCount &&
                    formik.errors.FollowerCount ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.FollowerCount}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-around my-3">
                  <Link
                    to={"/influencer/sign-up/influencer"}
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10"
                  >
                    Previous
                  </Link>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 ms-auto"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterInfluencerNew2;