import React, { useEffect, useState } from "react";
import ScrollToTop from "../ScrollToTop";
import { useFormik } from "formik";
import FormLoader from "../../../common/FormLoader";
import Config from "../../../API/Config";
import {
  InfluencerGetById,
  UpdateModelInfById,
} from "../../../API/InfluencerSignUpAPI";
import { useData } from "../../../Context/DataContext ";
import { format, parse } from "date-fns";
import { getAllCollbration } from "../../../API/CollabrationAPi";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import userImage from "../../../Images/usericon.png";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import axios from "axios";

const InfluencerProfile = () => {
  const [ThumbImagePreview, setThumbImagePreview] = useState();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const Id = Config.getId();

  const { CityData, CategoryData } = useData();
  const [CollbrationData, setCollbrationData] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [newLanguage, setNewLanguage] = useState("");
  const [showNewLanguageCheckbox, setShowNewLanguageCheckbox] = useState(false);
  const [isEmailEditable, setIsEmailEditable] = useState(true);
  const navigate = useNavigate();
  const Status = Config.getStatus();
  const [countryid, setCountryid] = useState(null);
  const [stateid, setStateId] = useState(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://admin.viralkar.com/front/api/all-country",
      );
      setCountries(response.data.responsedata);
      console.log(response)
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  console.log(countryid);
  

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-state/${countryId}`,
      );
      setStates(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-cities/${stateId}`,
      );
      setCities(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  useEffect(() => {
    const fetchCollaborationData = async () => {
      try {
        const result = await getAllCollbration();
        setCollbrationData(result);
      } catch (error) {
        console.error("Error fetching collaboration data:", error);
      }
    };
    fetchCollaborationData();
    fetchCountries();
  }, []);

  const formik = useFormik({
    initialValues: {
      InfluencerId: Id,
      Name: "",
      Email: "",
      Phone: "",
      DOB: "",
      Gender: "",
      CityName: "",
      Instragram: "",
      Facebook: "",
      Youtube: "",
      Twitter: "",
      LinkedIn: "",
      TikTok: "",
      Other: "",
      EngagementRate: "",
      Website: "",
      FollowerCount: "",
      TermsandConditions: false,
      PrivacyPolicy: false,
      ProfileImage: "",
      HidImg: "",
      Category: [],
      Language: [],
      CollaborationsType: [],
      OtherCollaborationType: "",
      CollaborationNames: [],
      ContentDescriptionBio: "",
      AgeRange: "",
      CollaborationIndustries: "",
      Country: "",
      State: "",
      City: "",
    },
    validate: (values) => {
      const errors = {};
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.Email)) {
        errors.Email = "Email must be in valid format.";
      } else if (values.Email) {
        setIsEmailEditable(false);
      }
      if (values.Phone) {
        if (values.Phone.startsWith("+91")) {
          const phoneWithoutCode = values.Phone.replace("+91", "").trim();
          const phoneRegex = /^\d{10}$/;
          if (!phoneRegex.test(phoneWithoutCode)) {
            errors.Phone = "Phone number must be 10 digits after +91.";
          }
        } else {
          errors.Phone = "Phone number must start with +91.";
        }
      } else {
        errors.Phone = "Phone number is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            formData.append(key, value.join(","));
          } else {
            formData.append(key, value);
          }
        });

        await UpdateModelInfById(Id, formData);
        await fetchData();

        Swal.fire({
          title: "Success!",
          text: "Profile updated successfully!",
          icon: "success",
        });
      } catch (error) {
        console.error("Error updating influencer data:", error);
        Swal.fire({
          title: "Error!",
          text: "There was an issue updating your profile. Please try again.",
          icon: "error",
        });
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  const fetchData = async () => {
    try {
      const result = await InfluencerGetById(Id);
      console.log(result);

      if (result.ProfileImage) {
        setThumbImagePreview(result.Path + result.ProfileImage);
      }

      const formattedDate = result.DOB
        ? format(
          parse(result.DOB, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date()),
          "yyyy-MM-dd",
        )
        : "";

      formik.setValues({
        InfluencerId: result.Id,
        Name: result.Name || "",
        Email: result.Email || "",
        Phone: result.Phone || "",
        DOB: formattedDate || "",
        Gender: result.Gender || "",
        CityName: result.CityName || "",
        Instragram: result.Instragram || "",
        Facebook: result.Facebook || "",
        Youtube: result.Youtube || "",
        Twitter: result.Twitter || "",
        LinkedIn: result.LinkedIn || "",
        TikTok: result.TikTok || "",
        Other: result.Other || "",
        EngagementRate: result.EngagementRate || "",
        Website: result.Website || "",
        FollowerCount: result.FollowerCount || "",
        TermsandConditions: result.TermsandConditions || false,
        PrivacyPolicy: result.PrivacyPolicy || false,
        ProfileImage: result.ProfileImage || "",
        HidImg: result.Hid_Image || "",
        Category: result.Category ? result.Category.split(",").map(Number) : [],
        Language: result.Languages ? result.Languages.split(",") : [],
        CollaborationsType: result.CollabrationId
          ? [result.CollabrationId]
          : [],
        CollaborationNames: result.CollaborationNames || [],
        ContentDescriptionBio: result.ContentDescriptionBio || "",
        AgeRange: result.AgeRange || "",
        CollaborationIndustries: result.CollaborationIndustries || "",
        Country: result.Country || "",
        State: result.State || "",
        City: result.City || "",
      });

      setAvailableLanguages(
        result.Languages ? result.Languages.split(",") : [],
      );

      if (result.Country) {
        setCountryid(result.Country);
        formik.setFieldValue("Country", result.Country);
        fetchStates(result.Country);
        console.log("Country set:", result.Country);
      }
      if (result.State) {
        setStateId(result.State);
        formik.setFieldValue("State", result.State);
        fetchCities(result.State);
        console.log("State set:", result.State);
      }
      if (result.City) {
        formik.setFieldValue("City", result.City);
        console.log("City set:", result.City);
      }
      

    } catch (error) {
      console.error("Error fetching influencer data:", error);
    }
  };

  useEffect(() => {
    if (Id) {
      fetchData();
    }
  }, [Id]);

  const handleCollaborationCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const currentCollaborations = formik.values.CollaborationsType || [];

    if (checked) {
      formik.setFieldValue("CollaborationsType", [
        ...currentCollaborations,
        value,
      ]);
    } else {
      formik.setFieldValue(
        "CollaborationsType",
        currentCollaborations.filter((val) => val !== value),
      );
    }

    if (value === "Other" && !checked) {
      formik.setFieldValue("OtherCollaborationType", "");
    }
  };

  const handleCheckboxChange = (event) => {
    const value = Number(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked) {
      formik.setFieldValue("Category", [...formik.values.Category, value]);
    } else {
      formik.setFieldValue(
        "Category",
        formik.values.Category.filter((id) => id !== value),
      );
    }
  };

  const handleLanguageCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const currentLanguages = formik.values.Language || [];

    if (value === "Other") {
      setShowNewLanguageCheckbox(checked);
      if (!checked) {
        formik.setFieldValue(
          "Language",
          currentLanguages.filter((lang) => lang !== "Other"),
        );
      }
    } else {
      if (checked) {
        formik.setFieldValue("Language", [...currentLanguages, value]);
      } else {
        formik.setFieldValue(
          "Language",
          currentLanguages.filter((lang) => lang !== value),
        );
      }
    }
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {
      formik.setFieldValue("Phone", value);
    }
  };

  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        navigate("/");
      }
    });
  };

  const location = useLocation();
  const getPathByType = (type) => {
    switch (type) {
      case "I":
        return "/profile/influencer";
      case "M":
        return "/profile/model";
      case "A":
        return "/profile/artist";
      default:
        return "/profileview";
    }
  };
  const type = Config.getType();
  const menuItems = [{ path: getPathByType(type), label: "Profile" }];

  const isActive = (path) => {
    return location.pathname === path;
  };

   
  

  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="">
        <ScrollToTop />
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-4 py-5 md:py-14 px-3 lg:gap-10 ">
            <div className="mb-10 lg:mb-0">
              <div className=" mb-5 md:mb-0 lg:sticky lg:top-24">
                <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                  <ul className="flex mb-5 list-none flex-col" role="tablist">
                    <img
                      src={
                        ThumbImagePreview === "undefined"
                          ? userImage
                          : ThumbImagePreview
                      }
                      alt="User Profile"
                      className="rounded-full h-32 w-32 mb-5 shadow-2xl p-2 mx-auto border object-cover object-top"
                    />
                    {menuItems.map((item, index) => (
                      <li
                        key={index}
                        className={`last:mr-0 text-center border dark:border-zinc-600 my-1.5 ${isActive(item.path) ? "bg-themecolor1 text-white" : ""
                          }`}
                      >
                        <Link
                          to={item.path}
                          className={`text-xs font-bold uppercase px-0 py-4 border-none block leading-normal ${isActive(item.path)
                              ? "bg-themecolor1 text-white"
                              : ""
                            }`}
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                    <li className="last:mr-0 my-1.5 text-center border dark:border-zinc-600 cursor-pointer">
                      <div
                        onClick={logout}
                        className="text-xs font-bold uppercase px-0 py-4 border-none block leading-normal"
                      >
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-span-3">
              <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
                <div className="bg-dark-light">
                  <form onSubmit={formik.handleSubmit}>
                    <input
                      type="hidden"
                      name="HidImg"
                      value={formik.values.HidImg}
                    />
                    <div className="">
                      <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                        {Status === 0 ? (
                          <div
                            class="bg-orange-100 border-t-2 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                            role="alert"
                          >
                            <div class="flex justify-center">
                              <p class="font-semibold text-sm">
                                Your Profile is Under Review
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div
                            class="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                            role="alert"
                          >
                            <div class="flex justify-center">
                              <p class="font-bold">Your Profile is Approved</p>
                            </div>
                          </div>
                        )}
                        <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                          Details
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          <div className="my-1">
                            <label className="text-base" for="">
                              Name
                            </label>
                            <input
                              type="text"
                              name="Name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Name}
                              placeholder="Name"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Name && formik.errors.Name ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Name}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Email</label>
                            <input
                              type="email"
                              name="Email"
                              value={formik.values.Email}
                              onChange={formik.handleChange}
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              required
                              disabled={!isEmailEditable}
                            />
                            {formik.touched.Email && formik.errors.Email ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Email}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Phone</label>
                            <input
                              type="tel"
                              name="Phone"
                              value={formik.values.Phone}
                              onChange={handlePhoneChange}
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              required
                              placeholder="Enter 10-digit number"
                            />
                            {formik.touched.Phone && formik.errors.Phone ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Phone}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">DOB</label>
                            <input
                              type="date"
                              name="DOB"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.DOB}
                              placeholder="DOB"
                              className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.DOB && formik.errors.DOB ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.DOB}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Profile Picture </label>
                            <input
                              type="file"
                              name="ProfileImage"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "ProfileImage",
                                  event.currentTarget.files[0],
                                );
                                setThumbImagePreview(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0],
                                  ),
                                );
                              }}
                              onBlur={formik.handleBlur}
                              className="text-sm text-grey-500 file:mr-5 mt-3 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold file:text-white
                                file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none border-0 rounded"
                            />
                            {formik.touched.ProfileImage &&
                              formik.errors.ProfileImage ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.ProfileImage}
                              </div>
                            ) : null}
                          </div>

                          <div className="flex items-center mb-2">
                            <label className="text-base mr-5">Gender</label>
                            <div className="flex items-center dark:bg-[#1e1e1e] px-2 py-2.5">
                              <div className="mr-3">
                                <input
                                  type="radio"
                                  id="Male"
                                  name="Gender"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value="M"
                                  checked={formik.values.Gender === "M"}
                                  className="mr-2"
                                />
                                <label htmlFor="Male">Male</label>
                              </div>
                              <div className="">
                                <input
                                  type="radio"
                                  id="Female"
                                  name="Gender"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value="F"
                                  checked={formik.values.Gender === "F"}
                                  className="mr-3"
                                />
                                <label htmlFor="Female">Female</label>
                              </div>
                            </div>
                            {formik.touched.Gender && formik.errors.Gender ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Gender}
                              </div>
                            ) : null}
                          </div>

                          {/* Country Dropdown */}
                          <select
                            className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            name="Country"
                            value={formik.values.Country || ""}
                            onChange={(e) => {
                              const countryId = e.target.value;
                              formik.setFieldValue("Country", countryId);  // Set the selected country ID in Formik
                              setCountryid(countryId);  // Update the state to track selected country
                              fetchStates(countryId);  // Fetch states for the selected country
                            }}
                          >
                            <option value="" label="Select Country" />
                            {countries.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                          {formik.errors.Country && <div className="text-red-500">{formik.errors.Country}</div>}

                          {/* State Dropdown */}
                          
                          <select
                            className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            name="State"
                            value={formik.values.State || ""}
                            onChange={(e) => {
                              const stateId = e.target.value;
                              formik.setFieldValue("State", stateId);  // Set the selected state ID in Formik
                              setStateId(stateId);  // Update the state to track selected state
                              fetchCities(stateId);  // Fetch cities for the selected state
                            }}
                            disabled={!countryid}  // Disable if country is not selected
                          >{}
                            <option value="" label="Select State" />
                            {states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                          {formik.errors.State && <div className="text-red-500">{formik.errors.State}</div>}

                          {/* City Dropdown */}
                          <select
                            className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            name="City"
                            value={formik.values.City || ""}
                            onChange={(e) => formik.setFieldValue("City", e.target.value)}  // Set the selected city in Formik
                            disabled={!stateid}  // Disable if state is not selected
                          >
                            <option value="" label="Select City" />
                            {cities.map((city) => (
                              <option key={city.id} value={city.id}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                          {formik.errors.City && <div className="text-red-500">{formik.errors.City}</div>}

                        </div>
                        <p className="text-md py-1.5 my-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                          Choose your category
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-4">
                          {CategoryData?.[0]?.ChildCat?.map((val, index) => (
                            <div className="flex items-center my-2" key={index}>
                              <input
                                type="checkbox"
                                className="mr-2"
                                name="Category"
                                value={val.Id}
                                checked={formik.values.Category.includes(
                                  val.Id,
                                )}
                                id={val.Id}
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="text-sm text-gray-500 font-normal"
                                htmlFor={val.Id}
                              >
                                {val.Title}
                              </label>
                            </div>
                          ))}
                        </div>

                        {formik.values.Language.length > 0 && (
                          <>
                            <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full mt-3">
                              Choose Language
                            </p>

                            <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                              {formik.values.Language.map((val, index) => (
                                <div className="flex items-center" key={index}>
                                  <input
                                    type="checkbox"
                                    className="mr-2"
                                    name="Language"
                                    value={val}
                                    checked={formik.values.Language.includes(
                                      val,
                                    )}
                                    id={val}
                                    onChange={handleLanguageCheckboxChange}
                                  />
                                  <label
                                    className="text-sm text-gray-700"
                                    htmlFor={val}
                                  >
                                    {val}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                        <div className="flex items-center my-4">
                          <input
                            type="checkbox"
                            id="newLanguageCheckbox"
                            className="mr-2"
                            onChange={() =>
                              setShowNewLanguageCheckbox(
                                !showNewLanguageCheckbox,
                              )
                            }
                          />
                          <label
                            htmlFor="newLanguageCheckbox"
                            className="text-sm text-gray-700"
                          >
                            Add New Language
                          </label>
                        </div>

                        {showNewLanguageCheckbox && (
                          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
                            <div className="col-span-2">
                              <input
                                type="text"
                                value={newLanguage}
                                onChange={(e) => setNewLanguage(e.target.value)}
                                placeholder="Specify new language"
                                className="px-4 py-2 border border-gray-300 rounded-md w-full"
                              />
                            </div>
                            <div className="my-auto">
                              <button
                                onClick={() => {
                                  if (
                                    newLanguage &&
                                    !formik.values.Language.includes(
                                      newLanguage,
                                    )
                                  ) {
                                    formik.setFieldValue("Language", [
                                      ...formik.values.Language,
                                      newLanguage,
                                    ]);
                                    setNewLanguage("");
                                  }
                                }}
                                className="text-sm bg-themecolor p-2 rounded text-white"
                              >
                                Include this language
                              </button>
                            </div>
                          </div>
                        )}

                        <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full mt-3">
                          Collaboration Preferences
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-4">
                          {CollbrationData[0]?.ChildCat?.map((val) => (
                            <div
                              className="flex items-center my-1"
                              key={val.Id}
                            >
                              <input
                                type="checkbox"
                                className="mr-2"
                                value={val.Title}
                                checked={formik.values.CollaborationNames.includes(
                                  val.Title,
                                )}
                                id={`collab-${val.Id}`}
                                onChange={handleCollaborationCheckboxChange}
                              />
                              <label
                                className="text-sm text-gray-500 font-normal"
                                htmlFor={`collab-${val.Id}`}
                              >
                                {val.Title}
                              </label>
                            </div>
                          ))}
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                          <div className="my-1">
                            <label for="">Preferred Brands/Industries</label>
                            <input
                              type="text"
                              name="CollaborationIndustries"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.CollaborationIndustries}
                              placeholder=" Preferred Brands/Industries"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                            />
                            {formik.touched.CollaborationIndustries &&
                              formik.errors.CollaborationIndustries ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.CollaborationIndustries}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">FollowerCount</label>
                            <input
                              type="text"
                              name="FollowerCount"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.FollowerCount}
                              placeholder="FollowerCount "
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                            />
                            {formik.touched.FollowerCount &&
                              formik.errors.FollowerCount ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.FollowerCount}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Engagement Rate</label>
                            <input
                              type="text"
                              name="EngagementRate"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.EngagementRate}
                              placeholder="Engagement Rate"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                            />
                            {formik.touched.EngagementRate &&
                              formik.errors.EngagementRate ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.EngagementRate}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label className="text-base">Age Range</label>
                            <select
                              name="AgeRange"
                              value={formik.values.AgeRange}
                              onChange={formik.handleChange}
                              className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                              onBlur={formik.handleBlur}
                            >
                              <option value="">Please select Age Range</option>
                              <option value="0 to 12 years">
                                0 to 12 years
                              </option>
                              <option value="13 to 17 years">
                                13 to 17 years
                              </option>
                              <option value="18 to 30 years">
                                18 to 30 years
                              </option>
                              <option value="30 to 40 years">
                                30 to 40 years
                              </option>
                              <option value="40 and more">40 and more</option>
                            </select>
                            {formik.touched.AgeRange &&
                              formik.errors.AgeRange ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.AgeRange}
                              </div>
                            ) : null}
                          </div>
                          <div className="mt-2 mb-2">
                            <label className="text-base" for="">
                              Description/Bio
                            </label>
                            <textarea
                              type="text"
                              name=" Description/Bio"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.ContentDescriptionBio}
                              placeholder="Bio"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                          </div>
                          <div className="my-1">
                            <label for="">Website/Blog URL</label>
                            <input
                              type="text"
                              name="Website"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Website}
                              placeholder="Website/Blog URL "
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                            />
                            {formik.touched.Website && formik.errors.Website ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Website}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                          Social Media Details
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                          <div className="my-1">
                            <label for="">Instagram</label>
                            <input
                              type="text"
                              name="Instragram"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Instragram}
                              placeholder="Instagram"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                            />
                            {formik.touched.Instragram &&
                              formik.errors.Instragram ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Instragram}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Facebook</label>
                            <input
                              type="text"
                              name="Facebook"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Facebook}
                              placeholder="Facebook"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                            />
                            {formik.touched.Facebook &&
                              formik.errors.Facebook ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Facebook}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">YouTube</label>
                            <input
                              type="text"
                              name="YouTube"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.YouTube}
                              placeholder="YouTube"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                            />
                            {formik.touched.YouTube && formik.errors.YouTube ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.YouTube}
                              </div>
                            ) : null}
                          </div>

                          <div className="my-1">
                            <label for="">Twitter</label>
                            <input
                              type="text"
                              name="Twitter"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Twitter}
                              placeholder="Twitter"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                            />
                            {formik.touched.Twitter && formik.errors.Twitter ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Twitter}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">LinkedIn</label>
                            <input
                              type="text"
                              name="LinkedIn"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.LinkedIn}
                              placeholder="LinkedIn"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.LinkedIn &&
                              formik.errors.LinkedIn ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.LinkedIn}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">TikTok</label>
                            <input
                              type="text"
                              name="TikTok"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.TikTok}
                              placeholder="TikTok"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.TikTok && formik.errors.TikTok ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.TikTok}
                              </div>
                            ) : null}
                          </div>

                          <div className="my-1">
                            <label for="">Other</label>
                            <input
                              type="text"
                              name="Other"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Other}
                              placeholder="Other"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                            />
                            {formik.touched.Other && formik.errors.Other ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Other}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex justify-center my-3">
                          <button
                            type="submit"
                            className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-10"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerProfile;
