/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import titleimage from "../../../../Images/title-icon.png";
import {
  FaAddressCard,
  FaCalendar,
  FaEye,
  FaEyeSlash,
  FaUser,
} from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { useNavigate } from "react-router";
import usePageSEO from "../../../usepageSEO/Index";
import Config from "../../../../API/Config";
import {
  ArtistGetById,
  ArtistStep1Add,
  checkUsernameExistsArtist,
} from "../../../../API/ArtistSignUpAPI";
import { useData } from "../../../../Context/DataContext ";
import { isValidPhoneNumber } from "libphonenumber-js";
import axios from "axios";

const RegisterArtist = () => {
  usePageSEO(
    "Free Artist Register - ViralKar",
    "Need more exposure for your art? Join our free artist register to connect with clients, showcase your work, and grow your creative career today.",
    [
      "Free Artist Register, Artist Register, Register as Artist, Register as Artist for free",
    ],
  );
  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
  const socialMediaOptions = [
    "Instagram",
    "YouTube",
    "Facebook",
    "Twitter",
    "LinkedIn",
    "TikTok",
    "Other",
  ];

  const validateFileSize = (file, maxSizeMB) => {
    if (file && file.size > maxSizeMB * 1024 * 1024) {
      return `File size must be less than ${maxSizeMB} MB`;
    }
    return null;
  };

  const validationSchema = Yup.object()
    .shape({
      Name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only alphabetic characters are allowed")
        .required("Please Enter Your Name"),
      ProfilePicture: Yup.mixed()
        .required("Profile Picture is required")
        .test("fileSize", "File size must be less than 5 MB", (value) => {
          return validateFileSize(value, 5) === null;
        }),
      Email: Yup.string().required("Please Enter Your Email"),
      Phone: Yup.string()
        .required("Phone number is required")
        .matches(
          /^\+91\d{10}$/,
          "Phone number must start with +91 and be followed by 10 digits",
        )
        .test("isValidPhoneNumber", "Phone number is not valid", (value) => {
          return isValidPhoneNumber(value);
        }),
      PassWord: Yup.string().required("Please Enter Your Password"),
      Username: Yup.string()
        .required("Please Enter a Username")
        .matches(
          /^[a-z0-9]*$/,
          "Username must be lowercase letters and numbers only.",
        )
        .matches(/^\S*$/, "Username cannot contain spaces."),
      Gender: Yup.string().required("Please Enter Your Gender"),
      DOB: Yup.string().required("Please Enter Your Date of birth"),
      Instagram: Yup.string().url("Invalid URL format"),
      YouTube: Yup.string().url("Invalid URL format"),
      TikTok: Yup.string().url("Invalid URL format"),
      Facebook: Yup.string().url("Invalid URL format"),
      Twitter: Yup.string().url("Invalid URL format"),
      LinkedIn: Yup.string().url("Invalid URL format"),
      Other: Yup.string().url("Invalid URL format"),
    })
    .test(
      "at-least-one-social-media",
      "Please add at least one social media URL",
      (values) => {
        return socialMediaOptions.some(
          (option) => values[option] && values[option].trim() !== "",
        );
      },
    );

  const ArtistId = Config.getArtistIdForTemp();
  const ArtistStep1 = Config.getArtistStep1ForTemp();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [ArtistData, setArtistData] = useState([]);
  const [Path, setPath] = useState([]);
  const [profilePicture, setProfilePicture] = useState("");
  const { CityData } = useData();
  const [phone, setPhone] = useState("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [countryid, setCountryid] = useState(null);
  const [stateid, setStateId] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://admin.viralkar.com/front/api/all-country",
      );
      setCountries(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-state/${countryId}`,
      );
      setStates(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-cities/${stateId}`,
      );
      setCities(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const response = await ArtistGetById(ArtistId);
      setArtistData(response);
      setPath(response.Path);
      setProfilePicture(response.ProfileImage || "");

      sessionStorage.setItem(
        "ArtistStep1ForTemp",
        JSON.stringify(response.Step1),
      );
      sessionStorage.setItem(
        "ArtistStep2ForTemp",
        JSON.stringify(response.Step2),
      );
      sessionStorage.setItem(
        "ArtistStep3ForTemp",
        JSON.stringify(response.Step3),
      );
      const dobFormatted = response?.DOB
        ? new Date(response.DOB).toISOString().split("T")[0]
        : "";
      formik.setValues({
        ArtistId: response.Id || "",
        Name: response.Name || "",
        ProfilePicture: response.ProfileImage || "",
        Hid_Image: response.Hid_Image || "",
        Email: response.Email || "",
        Phone: response.Phone || "",
        PassWord: response.PassWord || "",
        Username: response.ArtistName || "",
        Gender: response?.Gender || "",
        Instagram: response.Instagram || "",
        YouTube: response.YouTube || "",
        TikTok: response.TikTok || "",
        Facebook: response.Facebook || "",
        Twitter: response.Twitter || "",
        LinkedIn: response.LinkedIn || "",
        Other: response.Other || "",
        DOB: dobFormatted || "",
        Country: response.Country || "",
        State: response.State || "",
        City: response.City || "",
      });

      if (response.Country) {
        setCountryid(response.Country);
        formik.setFieldValue("Country", response.Country);
        fetchStates(response.Country);
        console.log("Country set:", response.Country);
      }
      if (response.State) {
        setStateId(response.State);
        formik.setFieldValue("State", response.State);
        fetchCities(response.State);
        console.log("State set:", response.State);
      }
      if (response.City) {
        formik.setFieldValue("City", response.City);
        console.log("City set:", response.City);
      }

      const filledSocialMedia = socialMediaOptions.filter(
        (option) => response[option] && response[option].trim() !== "",
      );
      setSelectedSocialMedia(filledSocialMedia);
    };
    getData();
    fetchCountries();
  }, [ArtistId]);

  useEffect(() => {
    if (CityData && CityData.length > 0) {
      const ahmedabad = CityData.find((city) => city.Title === "Ahmedabad");
      if (ahmedabad) {
        formik.setFieldValue("Address", ahmedabad.Id);
      }
    }
  }, [CityData]);

  const navigation = useNavigate();
  const formik = useFormik({
    initialValues: {
      ArtistId: ArtistId,
      Name: "",
      ProfilePicture: "",
      Hid_Image: "",
      Email: "",
      Phone: "",
      PassWord: "",
      Username: "",
      DOB: "",
      Gender: "",
      Instagram: "",
      YouTube: "",
      Facebook: "",
      Twitter: "",
      LinkedIn: "",
      TikTok: "",
      Other: "",
      Country: "",
      State: "",
      City: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);

      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const result = await ArtistStep1Add(formData);

        if (result.status === true) {
          navigation("/artist/sign-up/artist2");
          sessionStorage.setItem(
            "ArtistEmailForTemp",
            JSON.stringify(ArtistData.Email),
          );
          sessionStorage.setItem(
            "ArtistUserNameForTemp",
            JSON.stringify(ArtistData.UserName),
          );
          sessionStorage.setItem(
            "ArtistPhoneForTemp",
            JSON.stringify(ArtistData.Phone),
          );
          sessionStorage.setItem(
            "ArtistStep1ForTemp",
            JSON.stringify(ArtistData.Step1),
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    formik.setFieldValue("Phone", value);
  };

  const checkUsername = async (username) => {
    if (username) {
      setIsCheckingUsername(true);
      const response = await checkUsernameExistsArtist(username);

      if (response) {
        console.log("API Response:", response);
        if (response.status) {
          setIsUsernameAvailable(true);
        } else {
          setIsUsernameAvailable(false);
        }
      } else {
        setIsUsernameAvailable(false);
      }

      setIsCheckingUsername(false);
    } else {
      setIsUsernameAvailable(null);
    }
  };

  return (
    <div>
      <div className="bg-dark">
        {" "}
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-14 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center dark:text-white capitalize pb-3 ">
              Unleash your creativity register or log in to let your art shine
            </p>
            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <div className="relative flex items-center justify-between w-full my-10">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor dark:bg-white transition-all duration-500"></div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaUser className="w-5 h-5" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5 text-black" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5 text-black  " />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=" my-10 dark:border border-white"
          >
            <input
              type="hidden"
              name="Hid_Image"
              value={formik.values.Hid_Image}
            />
            <div className=" grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-3 bg-white dark:bg-slate-800 p-5 md:p-8 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border">
                <p className="text-center text-2xl pb-3 mb-3 border-b">
                  Personal Information
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name}
                      placeholder="Please Enter Full Name"
                      className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Name && formik.errors.Name ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Name}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Profile URL <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Username"
                      onChange={(e) => {
                        formik.handleChange(e);
                        checkUsername(e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.Username}
                      placeholder="Please Enter Username"
                      className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Username && formik.errors.Username ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Username}
                      </div>
                    ) : null}
                    {isCheckingUsername && (
                      <div className="text-gray-500 text-sm">
                        Checking username...
                      </div>
                    )}
                    {isUsernameAvailable === false && (
                      <div className="text-red-500 text-sm">
                        Username is already taken
                      </div>
                    )}
                    {isUsernameAvailable === true && (
                      <div className="text-green-500 text-sm">
                        Username is available
                      </div>
                    )}
                  </div>

                  <div className="my-1">
                    <label className="text-base">
                      Profile Picture <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="file"
                      name="ProfilePicture"
                      accept="image/*"
                      onBlur={formik.handleBlur}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "ProfilePicture",
                          event.currentTarget.files[0],
                        );
                      }}
                      className="text-sm text-grey-500
                      file:mr-5 file:py-1.5 file:px-5
                      file:rounded-full file:border-0
                      file:text-md file:font-semibold  file:text-white
                      file:bg-gradient-to-r file:from-themecolor file:to-black
                      hover:file:cursor-pointer hover:file:opacity-80
                      p-4 py-1.5 w-full focus:outline-none my-3 rounded"
                    />
                    {profilePicture && (
                      <img
                        src={
                          typeof profilePicture === "string"
                            ? Path + profilePicture
                            : URL.createObjectURL(profilePicture)
                        }
                        alt="Profile"
                        className="mt-2 h-24 w-24 object-cover"
                      />
                    )}
                    {formik.touched.ProfilePicture &&
                    formik.errors.ProfilePicture ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.ProfilePicture}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Email Address"
                      className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={formik.values.Phone}
                      onChange={handlePhoneChange}
                      defaultCountry="IN"
                      className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Phone && formik.errors.Phone ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Phone}
                      </div>
                    ) : null}
                  </div>

                  <div className="my-4">
                    <label className="text-base">
                      Gender <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center mt-2 space-x-6">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="Male"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="M"
                          className="mr-2"
                          checked={formik.values.Gender === "M"}
                        />
                        <label htmlFor="Male" className="">
                          Male
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="Female"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="F"
                          className="mr-2"
                          checked={formik.values.Gender === "F"}
                        />
                        <label htmlFor="Female" className="">
                          Female
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="Other"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="O"
                          className="mr-2"
                          checked={formik.values.Gender === "O"}
                        />
                        <label htmlFor="Other" className="">
                          Other
                        </label>
                      </div>
                    </div>

                    {formik.touched.Gender && formik.errors.Gender && (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.Gender}
                      </div>
                    )}
                  </div>

                  {ArtistStep1 === "1" ? (
                    ""
                  ) : (
                    <div className="my-1">
                      <label className="text-base">
                        Create Password <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <input
                          name="PassWord"
                          type={passwordVisible ? "text" : "password"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.PassWord}
                          placeholder="Please Enter Password "
                          className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                        >
                          {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                        </button>
                      </div>
                      {formik.touched.PassWord && formik.errors.PassWord ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.PassWord}
                        </div>
                      ) : null}
                    </div>
                  )}

                  <div className="my-1">
                    <label className="text-base">
                      Date of Birth <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      name="DOB"
                      value={formik.values.DOB}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Age Range"
                      max={new Date().toISOString().split("T")[0]}
                      className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.DOB && formik.errors.DOB ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.DOB}
                      </div>
                    ) : null}
                  </div>

                  <label></label>
                  <select
                    className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    name="Country"
                    value={formik.values.Country || ""}
                    onChange={(e) => {
                      const countryId = e.target.value;
                      formik.setFieldValue("Country", countryId);
                      setCountryid(countryId);
                      fetchStates(countryId);
                    }}
                  >
                    <option value="" label="Select Country" />
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.Country && <div>{formik.errors.Country}</div>}

                  {/* <label>State</label> */}
                  <select
                    className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    name="State"
                    value={formik.values.State || ""}
                    onChange={(e) => {
                      const stateId = e.target.value;
                      formik.setFieldValue("State", stateId);
                      setStateId(stateId);
                      fetchCities(stateId);
                    }}
                    disabled={!countryid}
                  >
                    <option value="" label="Select State" />
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.State && <div>{formik.errors.State}</div>}

                  {/* <label>City</label> */}
                  <select
                    className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    name="City"
                    value={formik.values.City || ""}
                    onChange={(e) =>
                      formik.setFieldValue("City", e.target.value)
                    }
                    disabled={!stateid}
                  >
                    <option value="" label="Select City" />
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.City && <div>{formik.errors.City}</div>}
                </div>
                <p className="text-center text-2xl pb-3 mb-3 border-b"></p>
                <div className="rounded-md mt-5 md:mb-10">
                  <div className="md:mb-4">
                    <p>Your Social Media Assets</p>
                    <small className="text-gray-400">
                      Select Social Media Assets and Add Links
                    </small>
                  </div>
                  <div className="grid md:grid-cols-4 grid-cols-1 gap-5 mt-2">
                    <div className="my-1">
                      <label className="text-base">Instagram</label>
                      <input
                        type="text"
                        name="Instragram"
                        value={formik.values.Instragram}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Instagram URL"
                        className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Instragram && formik.errors.Instragram ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Instragram}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">YouTube</label>
                      <input
                        type="text"
                        name="YouTube"
                        value={formik.values.YouTube}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter YouTube URL"
                        className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.YouTube && formik.errors.YouTube ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.YouTube}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Facebook</label>
                      <input
                        type="text"
                        name="Facebook"
                        value={formik.values.Facebook}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Facebook URL"
                        className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Facebook && formik.errors.Facebook ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Facebook}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Twitter</label>
                      <input
                        type="text"
                        name="Twitter"
                        value={formik.values.Twitter}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Twitter URL"
                        className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Twitter && formik.errors.Twitter ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Twitter}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">LinkedIn</label>
                      <input
                        type="text"
                        name="LinkedIn"
                        value={formik.values.LinkedIn}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter LinkedIn URL"
                        className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.LinkedIn && formik.errors.LinkedIn ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.LinkedIn}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">TikTok</label>
                      <input
                        type="text"
                        name="TikTok"
                        value={formik.values.TikTok}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter TikTok URL"
                        className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.TikTok && formik.errors.TikTok ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.TikTok}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Other</label>
                      <input
                        type="text"
                        name="Other"
                        value={formik.values.Other}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Other URL"
                        className=" px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Other && formik.errors.Other ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Other}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mt-5">
                  <button
                    type="submit"
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterArtist;
