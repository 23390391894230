import React, { useEffect, useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import { FaAddressCard, FaCalendar, FaUser } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../../../../Images/logo.png";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { useNavigate } from "react-router";
import Config from "../../../../API/Config";
import { Link } from "react-router-dom";
import usePageSEO from "../../../usepageSEO/Index";
import { useData } from "../../../../Context/DataContext ";
import {
  InfluencerGetById,
  InfluencerStep2Add,
  ModelGetById,
  ModelStep2Add,
  ModelStep3Add,
} from "../../../../API/ModelSignUpAPI";
import { getAllCategory } from "../../../../API/CategoryAPi";

const RegisterModel2 = () => {
  usePageSEO(
    "Free Model Register - VilarKar", // Use page title for SEO if available
    "Struggling to get noticed? Join our free model register to showcase your talent, connect with top brands, and land your next big opportunity.", // Use page description for SEO if available
    [
      "Free Model Register, Model Register, Register as Model , Register as Model for free",
    ] // No keywords provided in this example
  );
  const [CategoryData, setCategoryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResult] = await Promise.all([getAllCategory()]);
        setCategoryData(categoryResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [isFormLoading, setIsFormLoading] = useState(false);
  const ModelId = Config.getModelIdForTemp();
  const navigation = useNavigate();

  const validationSchema = Yup.object().shape({
    Category: Yup.array()
      .min(1, "Please select at least one category.")
      .max(5, "You can select up to 5 categories.")
      .required("Please select at least one category."),
   //  Address: Yup.string().required("Please Enter Your City"),
    PreviousAgencies: Yup.string().required("Please Enter Your Previous Agencies"),
    YearsofExperience: Yup.string().required("Please Enter Your Years of Experience"),
    Height: Yup.string()
      .required("Please enter your height in feet")
      .matches(/^\d+'(?:\d+"|")?$/, "Height must be in the format feet and inches"), // Adjusted regex for inches
    Weight: Yup.number()
      .typeError("Weight must be a number")
      .required("Please Enter Your Weight"),
    Bust: Yup.number()
      .typeError("Bust must be a number")
      .required("Please Enter Your Bust"),
    Waist: Yup.number()
      .typeError("Waist must be a number")
      .required("Please Enter Your Waist"),
    Hips: Yup.number()
      .typeError("Hips must be a number")
      .required("Please Enter Your Hips"),
    DOB: Yup.string().required("Please Enter Your Date of birth"),
  });
  
  

  const { CityData } = useData();

  const [InfluencerData, setInfluencerData] = useState([]);
  const InfluencerStep1 = Config.getModelStep1ForTemp();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await ModelGetById(ModelId);

        setInfluencerData(response);

        sessionStorage.setItem(
          "InfluencerStep1ForTemp",
          JSON.stringify(response.Step1)
        );
        sessionStorage.setItem(
          "InfluencerStep2ForTemp",
          JSON.stringify(response.Step2)
        );
        sessionStorage.setItem(
          "InfluencerStep3ForTemp",
          JSON.stringify(response.Step3)
        );

        const dobFormatted = response?.DOB
          ? new Date(response.DOB).toISOString().split("T")[0]
          : "";

        formik.setValues({
          ModelId: response?.Id || "",
          Category: response?.Category || [], // Ensure arrays are initialized
          PreviousAgencies: response?.PreviousAgencies || "", // Ensure arrays are initialized
          YearsofExperience: response?.YearsofExperience || "", // Ensure arrays are initialized
          Height: response?.Height || "", // Ensure arrays are initialized
          Weight: response?.Weight || "", // Ensure arrays are initialized
          Bust: response?.Bust || "", // Ensure arrays are initialized
          Waist: response?.Waist || "", // Ensure arrays are initialized
          Hips: response?.Hips || "", // Ensure arrays are initialized
//          Address: response?.Address || 9, // Ensure arrays are initialized
          DOB: dobFormatted || "", // Ensure arrays are initialized
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [ModelId]);

  const formik = useFormik({
    initialValues: {
      ModelId: ModelId,
      Category: [],
      PreviousAgencies: "",
      YearsofExperience: "",
      Height: "",
      Weight: "",
      Bust: "",
      Waist: "",
      Hips: "",
      DOB: "",
      // Address: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsFormLoading(true);

      try {
        const finalValues = {
          ...values,
        };

        const result = await ModelStep2Add(finalValues);

        if (result.status === true) {
          navigation("/model/sign-up/model3");
          sessionStorage.setItem(
            "ModelEmailForTemp",
            JSON.stringify(InfluencerData.Email)
          );
          sessionStorage.setItem(
            "ModelUserNameForTemp",
            JSON.stringify(InfluencerData.UserName)
          );
          sessionStorage.setItem(
            "ModelPhoneForTemp",
            JSON.stringify(InfluencerData.Phone)
          );
          sessionStorage.setItem(
            "ModelStep1ForTemp",
            JSON.stringify(InfluencerData.Step1)
          );
          sessionStorage.setItem(
            "ModelStep1ForTemp",
            JSON.stringify(InfluencerData.Step2)
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });


  const [otherCategory, setOtherCategory] = useState("");

  const handleCheckboxChange = async (event) => {
    const { value, checked } = event.target;
    const { Category } = formik.values;

    // Convert value to string
    const stringValue = value;

    if (checked) {
      // Add value to array if not already present and if the limit is not reached
      if (!Category.includes(stringValue) && Category.length <= 5) {
        formik.setFieldValue("Category", [...Category, stringValue]);
      }
    } else {
      // Remove value from array
      formik.setFieldValue(
        "Category",
        Category.filter((category) => category !== stringValue)
      );
    }

    // Manually trigger validation
    await formik.validateField("Category");
  };

  return (
    <div>
      <div className="bg-dark">
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-14 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center pb-2 dark:text-white capitalize">
              Step into the limelight register or log in to showcase your style
            </p>

            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <div className="relative flex items-center justify-between w-full my-10">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor dark:bg-white transition-all duration-500"></div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaUser className="w-5 h-5" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th bg-themecolor transition-all duration-300 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5 text-white" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5 text-black" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=" my-10 dark:border border-white"
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-3 bg-white dark:bg-slate-800 p-5 md:p-8 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border">
                <p className="text-center text-2xl pb-3 mb-3">
                  Modeling Experience
                </p>
                <div className="rounded-lg shadow-sm duration-500 border px-4 py-4 mb-6">
                  <div className="mb-3">
                    <p>
                      Please select category{" "}
                      <span className="text-red-500">*</span>
                    </p>
                    <small className="text-gray-400">
                      Select categories (Products or Services you can
                      promote)(up to 5)
                    </small>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-4">
                  {CategoryData[1]?.ChildCat?.map((val, index) => (
                            <div className="flex items-center my-2" key={index}>
                              <input
                                type="checkbox"
                                className="mr-2"
                                name="Category"
                                value={val.Id}
                                checked={formik.values.Category.includes(val.Id.toString())}
                                id={val.Title}
                                onChange={handleCheckboxChange}
                              />
                              <label className="text-sm text-gray-500 font-normal" htmlFor={val.Title}>
                                {val.Title}
                              </label>
                            </div>
                          ))}

                          {/* Input for Other Category */}
                          
                          <div className="flex items-center my-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              name="Category"
                              value="Other"
                              checked={formik.values.Category.includes("Other")}
                              id="Other1"
                              onChange={handleCheckboxChange} // You can manage the "Other" checkbox separately if needed
                            />
                            <label className="text-sm text-gray-500 font-normal" htmlFor="Other1">
                              Other
                            </label>
                          </div> 
                        </div> 

                        <div className="mt-4">
                        {formik.values.Category.includes("Other") && (
                            <div className="grid items-center ">
                              <input
                                type="text"
                                className="border border-gray-300 rounded-md p-2"
                                placeholder="Please specify"
                                value={otherCategory}
                                onChange={(e) => {
                                  setOtherCategory(e.target.value);
                                  formik.setFieldValue("OtherCategory", e.target.value);
                                }}
                              />
                            </div>
                          )}
                          </div>
                    {/* <div className="grid grid-cols-1 md:grid-cols-4">
                      {CategoryData[1]?.ChildCat?.map((val, index) => (
                        <div className="flex items-center my-1" key={index}>
                          <input
                            type="checkbox"
                            className="mr-2"
                            name="Category"
                            value={val.Id} // Ensure this matches the type of data in Formik
                            checked={formik.values.Category.includes(
                              val.Id.toString()
                            )} // Convert ID to string
                            id={val.Title}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="text-sm text-gray-500 font-normal"
                            htmlFor={val.Title}
                          >
                            {val.Title}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="pt-0">
                      {formik.touched.Category && formik.errors.Category && (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Category}
                        </div>
                      )}
                    </div> */}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Year of Experience <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="YearsofExperience"
                      value={formik.values.YearsofExperience}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Year of Experience"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.YearsofExperience &&
                    formik.errors.YearsofExperience ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.YearsofExperience}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Previous Agencies <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="PreviousAgencies"
                      value={formik.values.PreviousAgencies}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Previous Agencies"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.PreviousAgencies &&
                    formik.errors.PreviousAgencies ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.PreviousAgencies}
                      </div>
                    ) : null}
                  </div>
                </div>

                <p className="text-center text-2xl pb-3 my-3 border-b mt-5">
                  Physical Attributes
                </p>
                <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                    {/* Height */}
                    <div className="my-1">
                      <label className="text-base">
                        Height <span className="text-xs text-gray-500">(Feet)</span> <span className="text-red-500">*</span>
                      </label>
                      <select
                        name="Height"
                        value={formik.values.Height}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      >
                        <option value="">Select Height in Feet</option>
                        {Array.from({ length: 5 }, (_, feet) => 3 + feet).map(feet =>
                          Array.from({ length: 12 }, (_, inches) => (
                            <option key={`${feet}-${inches}`} value={`${feet}'${inches}"`}>
                              {`${feet}'${inches}"`}
                            </option>
                          ))
                        )}
                      </select>
                      {formik.touched.Height && formik.errors.Height && (
                        <div className="text-red-500 text-sm">{formik.errors.Height}</div>
                      )}
                    </div>

                    {/* Weight */}
                    <div className="my-1">
                      <label className="text-base">
                        Weight <span className="text-xs text-gray-500">(KG)</span> <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="Weight"
                        value={formik.values.Weight}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Weight"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Weight && formik.errors.Weight && (
                        <div className="text-red-500 text-sm">{formik.errors.Weight}</div>
                      )}
                    </div>

                    {/* Bust */}
                    <div className="my-1">
                      <label className="text-base">
                        Bust/Chest <span className="text-xs text-gray-500">(Inch)</span> <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="Bust"
                        value={formik.values.Bust}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Bust"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Bust && formik.errors.Bust && (
                        <div className="text-red-500 text-sm">{formik.errors.Bust}</div>
                      )}
                    </div>

                    {/* Waist */}
                    <div className="my-1">
                      <label className="text-base">
                        Waist <span className="text-xs text-gray-500">(Inch)</span> <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="Waist"
                        value={formik.values.Waist}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Waist"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Waist && formik.errors.Waist && (
                        <div className="text-red-500 text-sm">{formik.errors.Waist}</div>
                      )}
                    </div>

                    {/* Hips */}
                    <div className="my-1">
                      <label className="text-base">
                        Hips <span className="text-xs text-gray-500">(Inch)</span> <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="Hips"
                        value={formik.values.Hips}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Hips"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Hips && formik.errors.Hips && (
                        <div className="text-red-500 text-sm">{formik.errors.Hips}</div>
                      )}
                    </div>
                  </div>


                <p className="text-center text-2xl pb-3 my-3 border-b mt-5">
                  Audience Insights
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Date of Birth <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      name="DOB"
                      value={formik.values.DOB}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Age Range"
                      max={new Date().toISOString().split("T")[0]} // Disable future dates
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]  "
                    />
                    {formik.touched.DOB && formik.errors.DOB ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.DOB}
                      </div>
                    ) : null}
                  </div>

                  {/* <div className="my-1">
                    <label className="text-base">
                      Select City <span className="text-red-500">*</span>
                    </label>
                    <select
                      name="Address"
                      value={formik.values.Address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    >
                      <option className="text-base">Select City</option>
                      {CityData?.map((val, index) => {
                        return (
                          <option key={index} value={val.Id}>
                            {val.Title}
                          </option>
                        );
                      })}
                    </select>
                    {formik.touched.Address && formik.errors.Address ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Address}
                      </div>
                    ) : null}
                  </div> */}
                </div>

                <div className="flex justify-around mt-5">
                  <Link
                    to={"/model/sign-up/model"}
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10"
                  >
                    Previous
                  </Link>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 ms-auto"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterModel2;
