import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

// --------------------------------get All Influencer-----------------------
// https://www.model.easyshadi.com/front/api/vendor/
export const getAllInfluencer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/vendor`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// --------------------------------get Influencer by Id-----------------------
// https://www.model.easyshadi.com/front/api/vendor/profileImage/8
export const getInfluencerById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/vendorProfile/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// ------------------------------HireInf ----------------------
// https://www.model.easyshadi.com/front/api/HireInf
export const sendHireInf = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/HireInf`, data);
    if (response.data.status === true) {
      toast.success(response.data.message);
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// -------------------------Vendor Inquiry-------------------
// https://www.model.easyshadi.com/front/api/vendor/inquiry
export const AddVendorInquiry = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/inquiry`, data);
    if (response.data.status === true) {
      toast.success(response.data.message);
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// -------------------------Vendor Inquiry-------------------
// https://www.model.easyshadi.com/front/api/vendor/inquiry/1
export const GetVendorInquiry = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/vendor/inquiry/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Influencer by City-----------------------
// https://www.model.easyshadi.com/front/api/vendor/city/8
export const getInfluencerByCity = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/vendor/city/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// --------------------------------get Influencer by Category-----------------------
// https://www.model.easyshadi.com/front/api/vendor/interest/6
export const getInfluencerByCategory = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/vendor/interest/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// --------------------------------CreateCompositeCard-----------------------
// https://www.model.easyshadi.com/front/api/compocard/6/I
export const CreateCompositeCard = async (Id, Type) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/compocard/${Id}/${Type}`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    throw error;
  }
};

// ----------------------getRealWeddingByFilterData----------------
// http://model.easyshadi.com/front/api/filter?CityId=5|6&InterestId=5|4|6|7
export const getRealWeddingByFilterData = async (CityId, InterestId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/filter?CityId=${CityId}&InterestId=${InterestId}`
    );

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------GetCampaignforIunfluModel-----------------------
// https://www.model.easyshadi.com/front/api/campaign/model-influ/4
export const GetCampaignforIunfluModel = async (Id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/campaign/model-influ/${Id}`
    );
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// ----------------------------------CampaignApply----------------------------
// https://www.model.easyshadi.com/front/api/campaign/apply
export const campaignApply = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/campaign/apply`, data);
    if (response.data.status === true) {
      toast.success("Campaign apply successfully");
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
