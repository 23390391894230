import React, { useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import logo from "../../../../Images/logo.png";
import loginbg from "../../../../Images/password.png";
import sectionbg from "../../../../Images/in-section-shape15.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { Link, useNavigate } from "react-router-dom";
import usePageSEO from "../../../usepageSEO/Index";
import Config from "../../../../API/Config";  
import { ModelPassword } from "../../../../API/ModelSignUpAPI";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const validationSchema = Yup.object().shape({
  // Password: Yup.string().required("Please Enter Your Password"),
});

const LoginPasswordModel = () => {
  usePageSEO(
    "Free Model Register - ViralKar",
    "Struggling to get noticed? Join our free model register to showcase your talent, connect with top brands, and land your next big opportunity.",
    [
      "Free Model Register",
      "Model Register",
      "Register as Model",
      "Register as Model for free",
    ]
  );

  const ModelId = Config.getModelIdForTemp();
  const ModelEmail = Config.getModelEmailForTemp();
  const ModelPhone = Config.getModelPhoneForTemp();
  const ModelUserName = Config.getModelUserNameForTemp();

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      ModelId: ModelId || "",
      ModelEmail: ModelEmail || "",
      ModelPhone: ModelPhone || "",
      ModelUserName: ModelUserName || "",
      Password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsFormLoading(true);
      setApiError("");
    
      console.log("Submitting values:", values); // Debugging line
    
      try {
        const response = await ModelPassword(values);
        console.log("API Response:", response);
    
        if (response && response.status === true) {
          navigate("/profile/model");
        } else {
          //setApiError("");
        }
      } catch (error) {
        console.error("Error:", error);
        setApiError("An unexpected error occurred. Please try again.");
      } finally {
        setIsFormLoading(false);
      }
    },
    
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <ScrollToTop />
      <div className="md:py-14 py-5 px-5 xl:px-0">
        <div className="flex items-center justify-center flex-col">
          <p className="page-title text-center pb-2 capitalize dark:text-white">
            Step into the limelight register or log in to showcase your style
          </p>
          <img src={titleimage} className="text-center title-icon" alt="" />
        </div>

        <form onSubmit={formik.handleSubmit} className="py-10">
          <div className="flex items-center justify-center">
            <div className="surface:w-4/5 xl:w-3/5 rounded-lg dark:bg-white p-5 surface:p-0 md:p-0 grid md:grid-cols-2 grid-cols-1 dark:border border-white w-full">
              <div className="col-span-1 hidden md:block mt-10 md:mt-0 relative">
                <img src={sectionbg} alt="" />
                <img
                  src={loginbg}
                  alt=""
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
              <div className="col-span-1 my-auto mx-0 md:mx-20">
                <img
                  src={logo}
                  className="bg-white p-2 w-64 rounded mx-auto mb-10"
                  alt=""
                />
                <div className="my-3">
                  <label htmlFor="Password">Password</label>
                  <div className="relative">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Your Password"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 "
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                    >
                      {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>

                  <Link to={"/forgot-password"}>
                  <h1 className="text-left text-sm py-2 flex justify-end text-blue-500">Forgot Password</h1>
                  </Link>
                  

                  {formik.touched.Password && formik.errors.Password && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.Password}
                    </div>
                  )}
                  {apiError && (
                    <div className="text-red-500 text-sm mt-2">
                      {apiError}
                    </div>
                  )}
                </div>

                <div className="flex justify-center mx-auto my-3 gap-2 md:gap-8">
                  <Link to={"/model-register"}>
                    <button
                      type="button"
                      className="bg-green-600 hover:bg-themecolor rounded-full text-sm font-semibold text-white p-3 px-5 md:px-10"
                    >
                      Back
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-sm font-semibold text-white p-3 px-5 md:px-10"
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPasswordModel;
