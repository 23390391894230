/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashboardMenu from "../../../DashboardMenu";
import ScrollToTop from "../../ScrollToTop";
import ContactusImg from "../../../../Images/contact-back.jpg";
import Config from "../../../../API/Config";
import {
  CampaignDeletebyId,
  CampaignGetbyId,
} from "../../../../API/CampaignApi";
import { Link } from "react-router-dom";
import { FaGlobe } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import Swal from "sweetalert2";
import FormLoader from "../../../../common/FormLoader";
import ClipLoader from "react-spinners/FadeLoader";
import NoDataFound from "../../../../common/NodataFound";
import usePageSEO from "../../../usepageSEO/Index";
import Loader from "../../../../common/Loader";

const Campaigns = () => {
  const backgroundImage = ContactusImg;
  const breadcrumbStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center",
    zIndex: 1,
    padding: "140px 0 80px 0",
  };

  usePageSEO(
    "Viral kar | Campaign", // Use page title for SEO if available
    "Viral kar | Campaign", // Use page description for SEO if available
    ["Viral kar | Campaign"] // No keywords provided in this example
  );

  const [CampaingnData, setCampaingnData] = useState([]);
  const [DataLength, setDataLength] = useState();
  const [loading, setLoading] = useState(true); // Loading state
  const Id = Config.getId();
  const AdvStatus = Config.getAdvStatus();
  const FatchData = async () => {
    try {
      const result = await CampaignGetbyId(Id);
      setCampaingnData(result);

      const lengthData = result.length;
      setDataLength(lengthData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    FatchData();
  }, [Id]);
  
  const [isFormLoading, setIsFormLoading] = useState(false);
  // -------------------delete venue------------------
  const handleDelete = async (Id) => {
    setIsFormLoading(true);
    try {
      await CampaignDeletebyId(Id);
      FatchData();
    } catch (error) {
      console.error("Error deleting student:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  
  if (loading) {
    return <Loader />; // Show loader while loading data
  }
  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <div className="breadcrumb-bg" style={breadcrumbStyle}>
          <div className="breadcrumb-before"></div>
          <ul className="text-white text-3xl text-center">My Campaigns</ul>
        </div>
      </nav>
      <div className="pb-24">
        <ScrollToTop />
        <div className="w-full container mx-auto">
          <div className="w-full  lg:grid grid-cols-5 py-20 px-3 gap-3 ">
            <DashboardMenu />

            {/* ===============================Nav Pilss data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
              <div className="bg-dark-light">
                <div className="grid grid-cols-1 gap-5">
                  {AdvStatus === 0 ? (
                    <div className="bg-dark-light p-6">
                      <p className="text-center text-xl py-1.5 mb-3 bg-themecolor">
                        My Campaigns
                      </p>
                      <div>
                        <div
                          class="bg-orange-100 border-t-4 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-bold">
                              {" "}
                              Please Complete your profile before add campaign.
                            </p>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="bg-themecolor py-2 px-4"
                        >
                          <Link to={"/profile/advertiser"}>Click</Link>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="bg-dark-light p-6">
                      <div className="sm:flex items-center">
                        <button className="border w-full sm:w-auto p-2 rounded-full mb-3">
                          Connect Balance:{" "}
                          <span className="text-themecolor2">3</span>
                        </button>
                        <Link className="ml-auto" to={"/campaigns/add"}>
                          {" "}
                          <button className=" w-full sm:w-auto border border-themecolor2 p-2 rounded-full mb-3 px-4 bg-themecolor2 hover:bg-white hover:text-black transition-all">
                            <p>Create new campaign</p>
                          </button>
                        </Link>
                        <botton className=""></botton>
                      </div>
                      <p className="text-center text-xl py-1.5 mb-3 bg-themecolor">
                        My Campaigns
                      </p>
                      {loading ? (
                        <div className="flex justify-center items-center my-40">
                          <ClipLoader
                            color={"#d00067"}
                            loading={loading}
                            size={40}
                          />
                        </div>
                      ) : (
                        <React.Fragment>
                          {CampaingnData.length === 0 ? (
                            <NoDataFound />
                          ) : (
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                              {CampaingnData?.map((val, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="mb-5  surface:px-2"
                                  >
                                    <div className="bg-white p-5 shadow-lg rounded">
                                      <div className="grid grid-cols-1 md:grid-cols-4 border-b-2 border-gray-400 border-dashed">
                                        <div className="col-span-4 ">
                                          <div>
                                            {val.Status === 0 ? (
                                              <div
                                                class="bg-orange-100 border-t-4 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                                                role="alert"
                                              >
                                                <div class="flex">
                                                  <p class="font-bold">
                                                    Your Campaign is Under
                                                    Review
                                                  </p>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                class="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                                                role="alert"
                                              >
                                                <div class="flex">
                                                  <p class="font-bold">
                                                    Your Campaign is Approved
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                            <div className="flex items-center">
                                              <Link
                                                to={"/influencerCategoryInner"}
                                              >
                                                <p className="text-2xl text-themecolor my-2">
                                                  {val.CampaignName}
                                                </p>
                                              </Link>
                                              <p className="ml-auto">
                                                <Link
                                                  className="bg-themecolor2 p-2"
                                                  target="_blank"
                                                  to={val.CampaignDoc}
                                                >
                                                  View
                                                </Link>
                                              </p>
                                            </div>
                                            <div className="text-textcolor my-2">
                                              <p className="flex items-center my-1">
                                                <FaLocationDot />
                                                <span className="px-1.5">
                                                  {val.CityNames}
                                                </span>
                                              </p>
                                              <p className="flex items-center my-1 line-break-anywhere">
                                                <span className="mr-1.5">
                                                  <FaGlobe />
                                                </span>
                                                {val.Website}
                                              </p>
                                              <p>
                                                Category: {val.CategoryNames}
                                              </p>
                                              <p>
                                                Vendor Type:{" "}
                                                {val.VendorType === "M"
                                                  ? "Model"
                                                  : "Influencer"}
                                              </p>
                                              <p>
                                                Promotion Type:{" "}
                                                {val.Type === "S"
                                                  ? "Service"
                                                  : "Product"}
                                              </p>
                                            </div>

                                            <p className="flex items-center my-1 text-textcolor line-clamp-1">
                                              {val.Description}
                                            </p>
                                            <p className="text-2xl text-themecolor my-1">
                                              {val.Price}
                                            </p>

                                            <p className="text-black my-1">
                                              {val.Categories}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        {val.Status === 0 && (
                                          <Link
                                            to={`/campaigns/edit/${val.Id}`}
                                          >
                                            <button
                                              className="bg-green-700 mt-5 md:mr-3 mr-1 p-2 text-white rounded md:text-base text-xs"
                                              type="button"
                                            >
                                              Edit
                                            </button>
                                          </Link>
                                        )}

                                        <button
                                          className="bg-red-600 mt-5 md:mr-3 mr-1 p-2 text-white rounded md:text-base text-xs"
                                          type="button"
                                          onClick={() => {
                                            Swal.fire({
                                              title: "Are you sure?",
                                              text: `Are you sure you want to delete ${val.Id}?`,
                                              icon: "warning",
                                              showCancelButton: true,
                                              confirmButtonColor: "#3085d6",
                                              cancelButtonColor: "#d33",
                                              confirmButtonText:
                                                "Yes, delete it!",
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                handleDelete(val.Id);
                                              }
                                            });
                                          }}
                                        >
                                          Delete
                                        </button>

                                        {val.Status === 1 && (
                                          <Link
                                            to={`/influencer/applied/${val.Id}`}
                                          >
                                            <button
                                              className="bg-themecolor mt-5 md:mr-3 mr-1 p-2 text-white rounded md:text-base text-xs"
                                              type="button"
                                            >
                                              Influencers Applied
                                            </button>
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
