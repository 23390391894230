import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import userImage from "../Images/usericon.png";
import Swal from "sweetalert2";
import Config from "../API/Config";

const DashboardMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
      }
    });
  };

  const type = Config.getType();

  const getPathByType = (type) => {
    switch (type) {
      case "I":
        return "/profile/influencer"; 
      case "M":
        return "/profile/model";
      case "A":
        return "/profile/artist"; 
      case "Adv":
        return "/profile/advertiser"
      default:
        return "/profileview"; 
    }
  };

  
  const menuItems = [
    { path: getPathByType(type), label: "Profile" },
  ];

  const isActive = (path) => {
    return location.pathname === path;
  };

  const ProfileImage = sessionStorage.getItem("ProfileImage");

  return (
    <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
      <ul className="flex mb-5 list-none flex-col" role="tablist">
        <img
          src={ProfileImage === "undefined" ? userImage : ProfileImage}
          alt="User Profile"
          className="rounded-full h-32 w-32 mb-5 shadow-2xl p-2 mx-auto border"
        />

        {menuItems.map((item, index) => (
          <li
            key={index}
            className={`last:mr-0 text-center border dark:border-zinc-600 my-1.5 ${
              isActive(item.path) ? "bg-themecolor1 text-white" : ""
            }`}
          >
            <Link
              to={item.path}
              className={`text-xs font-bold uppercase px-0 py-4 border-none block leading-normal ${
                isActive(item.path) ? "bg-themecolor1 text-white" : ""
              }`}
            >
              {item.label}
            </Link>
          </li>
        ))}
        
        <li className="last:mr-0 my-1.5 text-center border dark:border-zinc-600 cursor-pointer">
          <div
            onClick={logout}
            className="text-xs font-bold uppercase px-0 py-4 border-none block leading-normal"
          >
            Logout
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DashboardMenu;
