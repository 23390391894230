import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Component/Pages/Home";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import Contact from "../Component/Pages/Contact";
import MaleInfluencer from "../Component/Pages/Influencer/MaleInfluencer";
import ForgotPassword from "../Component/Pages/Login-Register/ForgotPassword";
import Generalsignup from "../Component/Pages/Login-Register/Generalsignup";
import FemaleInfluencer from "../Component/Pages/Influencer/FemaleInfluencer";
import RegisterEnterprice from "../Component/Pages/Register/RegisterEnterprice";
import RequireAuth from "../Private/Requiredau";
import AdvProfile from "../Component/Pages/Dashboard/AdvProfile";
import Service from "../Component/Pages/Services/Service";
import ServiceInner from "../Component/Pages/Services/ServiceInner";
import ScrollToTop from "../Component/Pages/ScrollToTop";
import HowItWorks from "../Component/Pages/Influencer/Howitwork";
import KidsModel from "../Component/Pages/ModelCat/KidsModel";
import MaleModel from "../Component/Pages/ModelCat/MaleModel";
import FemaleModel from "../Component/Pages/ModelCat/FemaleModel";
import PlanModel from "../Component/Pages/Plan/PlanModel";
import Pages from "../Component/Pages/Pages";
import Category from "../Component/Pages/Category";
import Hiremodel from "../Component/Pages/Hire/Hiremodel";
import HireInfluencer from "../Component/Pages/Hire/HireInfluencer";
import Campaigns from "../Component/Pages/Dashboard/Campaign/Campaigns";
import AddCampaign from "../Component/Pages/Dashboard/Campaign/AddCampaign";
import EditCampaign from "../Component/Pages/Dashboard/Campaign/EditCampaign";
import Enquiry from "../Component/Pages/Dashboard/Enquiry";
import CityInfluencer from "../Component/Pages/CityInfluencer";
import ModelCampaign from "../Component/Pages/Dashboard/ModelCampaign";
import ApplyCampaign from "../Component/Pages/Dashboard/ApplyCampaign";
import InfluencerApplied from "../Component/Pages/Dashboard/Campaign/InfluencerApplied";
import AboutUs from "../Component/Pages/AboutUs";
import Blog from "../Component/Pages/Blog";
import BlogDetails from "../Component/Pages/BlogDetails";
import FAQ from "../Component/Pages/FAQ";
import PrivacyPolicy from "../Component/Pages/PrivacyPolicy";
import TermsCondition from "../Component/Pages/TermsCondition";
import LoginPasswords from "../Component/Pages/LoginMain/Influencer/LoginPassword";
import LoginInfluencer from "../Component/Pages/LoginMain/Influencer/LoginInfluencer";
import LoginModel from "../Component/Pages/LoginMain/Model/LoginModel";
import LoginPasswordModel from "../Component/Pages/LoginMain/Model/LoginPasswordModel";
import RegisterModel from "../Component/Pages/LoginMain/Model/RegisterModel";
import RegisterModel2 from "../Component/Pages/LoginMain/Model/RegisterModel2";
import RegisterModel3 from "../Component/Pages/LoginMain/Model/RegisterModel3";
import RegisterArtist from "../Component/Pages/LoginMain/Artist/RegisterArtist";
import RegisterArtist2 from "../Component/Pages/LoginMain/Artist/RegisterArtist2";
import LoginArtist from "../Component/Pages/LoginMain/Artist/LoginArtist";
import LoginPasswordArtist from "../Component/Pages/LoginMain/Artist/LoginPasswordArtist";
import RegisterArtist3 from "../Component/Pages/LoginMain/Artist/RegisterArtist3";
import RegisterSuccessful from "../Component/Pages/LoginMain/RegisterSuccessful";
import RegisterInfluencerNew2 from "../Component/Pages/LoginMain/Influencer/RegisterInfluencer2";
import RegisterInfluencerNew from "../Component/Pages/LoginMain/Influencer/RegisterInfluencer";
import RegisterInfluencerNew3 from "../Component/Pages/LoginMain/Influencer/RegisterInfluencer3";
import CatSubCategory from "../Component/Pages/CatSubCategory";
import InfluencerDetail from "../Component/Pages/InfluencerDetail";
import ModelDetail from "../Component/Pages/ModelDetail";
import ArtistDetail from "../Component/Pages/ArtistDetail";
import FindInfluencer from "../Component/Pages/FindInfluencer";
import Artist from "../Component/Pages/Artist";
import ModelProfile from "../Component/Pages/DB/ModelProfile";
import InfluencerProfile from "../Component/Pages/DB/InfluencerProfile";
import ArtistProfile from "../Component/Pages/DB/ArtistProfile";
import PopularInfluencer from "../Component/Pages/PopularInfluencer";
import FindModel from "../Component/Pages/FindModel";
import NotFound from "../Component/NotFound";
import ModelSubCategory from "../Component/Pages/ModelSubCategory";
import ArtistSubCategory from "../Component/Pages/ArtistSubCategory";
import AdvertiserSignup from "../Component/Pages/Register/AdvertiserSignup";
import AllCityShow from "../Component/Pages/AllCityShow";
import CityInf from "../Component/Pages/CityInf/CityInf";
import CityModel from "../Component/Pages/CityInf/CityModel";
import CItyArtist from "../Component/Pages/CityInf/CItyArtist";
import AllCities from "../Component/Pages/AllCities";
import AdvertiserLogin from "../Component/Pages/Register/AdvertiserLogin";

const MainRouter = () => {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} /> {/* Sitemap Done */}
 
          <Route path="/allcityshow/:Slug" exact element={<AllCityShow />} /> {/* Sitemap Done */}
          {/*YES*/}
          {/* ====================About Us ======================*/}
          <Route path="/about-us" element={<AboutUs />} /> {/* Sitemap Done */}
          {/*YES*/}
          {/* ==================== FAQ ======================*/}
          <Route path="/faq" element={<FAQ />} /> {/* Sitemap Done */}
          {/*YES*/}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Sitemap Done */}
          {/*YES*/}
          {/* ==================== Blog ======================*/}
          <Route path="/blog" element={<Blog />} /> {/*YES*/} {/* Sitemap Done */}
          <Route path="/blog/:slug" element={<BlogDetails />} /> {/* Sitemap Done */}
          {/*YES*/}
          <Route path="/terms-codtion" element={<TermsCondition />} /> {/* Sitemap Done */}
          {/*YES*/}
          <Route path="/contact" element={<Contact />} /> {/* Sitemap Done */}
          {/*YES*/}
          <Route path="/forgot-password" element={<ForgotPassword />} /> {/* Sitemap Done */}
          {/*YES*/}
          <Route path="/sign-up" element={<Generalsignup />} /> {/* Sitemap Done */}
          {/*YES*/}
          <Route path="/pages/:Slug" element={<Pages />} /> {/* Sitemap Done */}
          {/*YES*/}

          <Route path="/view-all-cities" element={<AllCities />} /> {/* Sitemap Done */}


          {/* Advertiser Signup */}

          <Route path="/sign-up/advertise-signup" element={<AdvertiserSignup />} /> {/* Sitemap Done */}
          <Route path="/sign-up/advertiser" element={<RegisterEnterprice />} /> {/* Sitemap Done */}
          <Route path="/sign-up/advertiser/password" element={<AdvertiserLogin />} /> {/* Sitemap Done */}

          {/* -------------------------- Influencer ---------------------------*/}
          <Route path="/male-influencer" element={<MaleInfluencer />} /> {/* Sitemap Done */}
          <Route path="/female-influencer" element={<FemaleInfluencer />} /> {/* Sitemap Done */}
          <Route path="/how-its-work" element={<HowItWorks />} /> {/* Sitemap Done */}

          {/* ------------------------------- Influncer ------------------*/}
          <Route
            path="/influencer/:UserName/:CitySlug"
            element={<InfluencerDetail />} 
          /> {/* Sitemap Done */}
          <Route path="/find-influencer" element={<FindInfluencer />} /> {/* Sitemap Done */}
          {/* ------------------------------- Model ------------------*/}
          <Route path="/model/:UserName/:CitySlug" element={<ModelDetail />} /> {/* Sitemap Done */}
          {/* ------------------------------- Artist ------------------*/}
          <Route path="/find-artist" element={<Artist />} /> {/* Sitemap Done */}
          <Route
            path="/artist/:ArtistName/:CitySlug"
            element={<ArtistDetail />} 
          />{/* Sitemap Done */}

          {/* ----------------------------- Category -------------------------------*/}
          <Route path="/category/:Slug" element={<Category />} /> {/* Sitemap Done */}
          <Route
            path="/influencer/category/:Slug"
            element={<CatSubCategory />}  
          /> {/* Sitemap Done */}
          <Route path="/model/category/:Slug" element={<ModelSubCategory />} /> {/* Sitemap Done */}
          <Route
            path="/artist/category/:Slug"
            element={<ArtistSubCategory />} 
          /> {/* Sitemap Done */}
          <Route path="/city/:Slug" element={<CityInfluencer />} /> {/* Sitemap Done */}


          {/* ----------------------------- cITY wISW mODEL -------------------------------*/}
          <Route path="/city/influencer/:Slug" element={<CityInf />} /> {/* Sitemap Done */}
          <Route path="/city/model/:Slug" element={<CityModel />} /> {/* Sitemap Done */}
          <Route path="/city/artist/:Slug" element={<CItyArtist />} /> {/* Sitemap Done */}



          {/* ----------------------------- Modal Category -------------------------------*/}
          <Route path="/female-models" element={<FemaleModel />} /> {/* Sitemap Done */}
          <Route path="/male-models" element={<MaleModel />} /> {/* Sitemap Done */}
          <Route path="/kids-models" element={<KidsModel />} /> {/* Sitemap Done */}
          {/*------------------------Service--------------------------*/}
          <Route path="/service" element={<Service />} /> {/* Sitemap Done */}
          <Route path="/service/:Slug" element={<ServiceInner />} /> 
          {/*------------------------Hire--------------------------*/}
          <Route path="/hire-model" element={<Hiremodel />} /> {/* Sitemap Done */}
          <Route path="/hire-influencer" element={<HireInfluencer />} /> {/* Sitemap Done */}
          {/* --------------------------------- Plan -------------------*/}
          <Route path="/plan" element={<PlanModel />} /> {/* Sitemap Done */}
          {/*------------------------dashboard--------------------------*/}
          <Route
            path="/profile/advertiser"
            element={
              <RequireAuth>
                <AdvProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/profile/influencer"
            element={
              <RequireAuth>
                <InfluencerProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/profile/model"
            element={
              <RequireAuth>
                <ModelProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/profile/artist"
            element={
              <RequireAuth>
                <ArtistProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/campaign"
            element={
              <RequireAuth>
                <ModelCampaign />
              </RequireAuth>
            }
          />
          <Route
            path="/campaign/apply/:Id"
            element={
              <RequireAuth>
                <ApplyCampaign />
              </RequireAuth>
            }
          />
          <Route
            path="/campaigns"
            element={
              <RequireAuth>
                <Campaigns />
              </RequireAuth>
            }
          />
          <Route
            path="/campaigns/add"
            element={
              <RequireAuth>
                <AddCampaign />
              </RequireAuth>
            }
          />
          <Route
            path="/campaigns/edit/:Id"
            element={
              <RequireAuth>
                <EditCampaign />
              </RequireAuth>
            }
          />
          <Route
            path="/enquiry"
            element={
              <RequireAuth>
                <Enquiry />
              </RequireAuth>
            }
          />
          <Route
            path="/influencer/applied/:Id"
            element={
              <RequireAuth>
                <InfluencerApplied />
              </RequireAuth>
            }
          />
          {/*-------------------------------Model-influencer-artist----------------------*/}
          {/*------------------------login/register--------------------------*/}
          <Route
            path="/influencer/sign-up/influencer"
            element={<RegisterInfluencerNew />}
          /> {/* Sitemap Done */}
          <Route
            path="/influencer/sign-up/influencer2"
            element={<RegisterInfluencerNew2 />}
          />{/* Sitemap Done */}
          <Route
            path="/influencer/sign-up/influencer3"
            element={<RegisterInfluencerNew3 />}
          />{/* Sitemap Done */}
          <Route path="/influencer-register" element={<LoginInfluencer />} />{/* Sitemap Done */}
          <Route path="/influencer-password" element={<LoginPasswords />} />{/* Sitemap Done */}
          {/*------------------------login/register Model--------------------------*/}
          <Route path="/model/sign-up/model" element={<RegisterModel />} />{/* Sitemap Done */}
          <Route path="/model/sign-up/model2" element={<RegisterModel2 />} />{/* Sitemap Done */}
          <Route path="/model/sign-up/model3" element={<RegisterModel3 />} />{/* Sitemap Done */}
          <Route path="/model-register" element={<LoginModel />} />{/* Sitemap Done */}
          <Route path="/model-password" element={<LoginPasswordModel />} />{/* Sitemap Done */}
          {/*------------------------login/register Artist--------------------------*/}
          <Route path="/artist/sign-up/artist" element={<RegisterArtist />} />{/* Sitemap Done */}
          <Route path="/artist/sign-up/artist2" element={<RegisterArtist2 />} />{/* Sitemap Done */}
          <Route path="/artist/sign-up/artist3" element={<RegisterArtist3 />} />{/* Sitemap Done */}
          <Route path="/artist-register" element={<LoginArtist />} />{/* Sitemap Done */}
          <Route path="/artist-password" element={<LoginPasswordArtist />} />{/* Sitemap Done */}

          <Route path="/register/success" element={<RegisterSuccessful />} />  {/* Sitemap Done */}
          <Route path="/profileview" element={<ModelProfile />} />
          <Route path="/popular-influencers" element={<PopularInfluencer />} />{/* Sitemap Done */}
          <Route path="/find-model" element={<FindModel />} /> {/* Sitemap Done */}
          <Route path="/404" element={<NotFound />} />{/* Sitemap Done */}
          <Route path="*" element={<NotFound />} />{/* Sitemap Done */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default MainRouter;
