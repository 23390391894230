import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Config from "../API/Config";
const { API_BASE_URL } = Config;

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(!Cookies.get("cookiesAccepted"));
  const [userIp, setUserIp] = useState(null);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setUserIp(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const saveConsent = async (ip) => {
    try {
      await fetch(`${API_BASE_URL}/save-consent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ip, consentAccepted: true }),
      });
    } catch (error) {
      console.error("Error saving consent:", error);
    }
  };

  const handleAcceptCookies = () => {
    Cookies.set("cookiesAccepted", "true", { expires: 365 });
    if (userIp) {
      Cookies.set("userIp", userIp, { expires: 365 });
      saveConsent(userIp);
    }
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-themebgcolor3 text-white py-2 px-3 md:flex justify-between items-center z-50">
      <p className="m-0 text-xs md:text-sm text-center md:text-left mb-2 md:mb-0">
        We use cookies to improve your experience on our site. By using our
        site, you accept our cookie policy.
      </p>
      <button
        onClick={handleAcceptCookies}
        className="bg-themecolor1 text-sm mx-auto block md:mx-0 text-white px-4 py-1 rounded-full"
      >
        Accept All Cookies
      </button>
    </div>
  );
};

export default CookieConsent;
