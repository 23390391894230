import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import BlogBanner from "../../Images/BlogBanner.jpg";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/FadeLoader";
import { getAllBlogsAPI, getRecentPostsAPI } from "../../API/BlogApi"; // Make sure to add getRecentPostsAPI
import Loader from "../../common/Loader";

const Blog = () => {
  const [blogdata, setBlogData] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]); // State for recent posts
  const [loading, setLoading] = useState(true);

  const breadcrumbItems = [
    {
      text: "Blog",
    },
  ];

  // Fetch blog data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [blogsResponse, recentPostsResponse] = await Promise.all([
          getAllBlogsAPI(),
          getRecentPostsAPI(), // Fetch recent posts from API
        ]);
        setBlogData(blogsResponse || []);
        setRecentPosts(recentPostsResponse || []); // Ensure response data is an array
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


 
  if (loading) {
    return <Loader />; // Show loader while loading data
  }

  return (
    <div className="dark:bg-darkmode dark:text-white">
      <Breadcrumb items={breadcrumbItems} backgroundImage={BlogBanner} />
      <div className="container mx-auto pt-5 md:pt-10 pb-5 md:pb-10 px-3">
        <div className="md:flex gap-10">
          {/* Left Side - Blog Content */}
          <div className="md:w-3/4">
            <div className="grid md:grid-cols-2 gap-8">
              {Array.isArray(blogdata) && blogdata.length > 0 ? (
                blogdata.map((val, index) => (
                  <div
                    key={index}
                    className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border dark:border-gray-500"
                  >
                    <div className="mb-10">
                      <Link to={`/blog/${val.Slug}`}>
                        <div className="overflow-hidden relative md:h-[18rem] rounded-t-md mb-2">
                          <img
                            src={val.Image}
                            alt={val.Title}
                            className="h-full w-full object-cover"
                          />
                        </div>
                        <h1 className="py-3 pb-1 px-4 line-clamp-2 text-lg text-center font-semibold " >
                          {val.Title}
                        </h1>
                      </Link>
                      <div className="border-t-4 border-themecolor w-1/4 flex mx-auto"></div>
                      <p className="text-center text-sm text-gray-400 pt-3 pb-2">
                        {new Date(val.EntDt).toDateString()}
                      </p>
                      <p className="text-center px-4 text-sm text-gray-600 dark:text-gray-300 line-clamp-5">
                        {val.ShortDescription}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div>No blogs available</div>
              )}
            </div>
          </div>
          <div className="md:w-1/4 mt-5 md:mt-0">
            <div className="mx-auto sticky lg:top-32">
              <div className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border dark:border-gray-500">
                <h1 className="text-center text-white text-lg font-semibold uppercase py-2 bg-themecolor1 rounded-t-lg">
                  Recent Posts
                </h1>
                <div className="py-4">
                  {recentPosts.length > 0 ? (
                    recentPosts.map((val, index) => (
                      <Link to={`/blog/${val.Slug}`} key={index}>
                        <div className="mb-3 flex px-3">
                          <div className="overflow-hidden relative h-20 w-40 rounded me-5">
                            <img
                              src={val.Image}
                              alt=""
                              className="h-full w-full object-cover object-top"
                            />
                          </div>
                          <div className="w-5/6">
                            <h1 className="text-sm font-medium line-clamp-2">
                              {val.Title}
                            </h1>
                            <p className="text-gray-400 text-[12px] pt-1">
                              {new Date(val.EntDt).toDateString()}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <div>No recent posts available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
