import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import InflencerImg from "../../Images/infleuncer.jpg";
import ModelImg from "../../Images/modelImg.jpg";
import ArtistImg from "../../Images/ArtistImg.jpg";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import titleimage from "../../Images/title-icon.png";
import { useData } from "../../Context/DataContext ";
import ClipLoader from "react-spinners/FadeLoader";
import PopularInfluencerImg from "../../Images/home-page-back-1.png";
import { RxCross2 } from "react-icons/rx";
import Loader from "../../common/Loader";

const AllCityShow = () => {
  const { Slug } = useParams();
  const location = useLocation();
  const id = location.state?.id; // Get Id from state
  const breadcrumbItems = [
    {
      text: `${Slug}`,
    },
  ];

  const { CityData, loading } = useData();

  // State for search query and the debounced query
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  // Debounce the search query
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500); // Delay of 500ms

    return () => clearTimeout(timer); // Cleanup on each keystroke
  }, [searchQuery]);

  // Filter the city data based on the debounced query
  const filteredCities = useMemo(() => {
    return CityData?.filter((city) =>
      city.Title.toLowerCase().includes(debouncedQuery.toLowerCase()),
    );
  }, [debouncedQuery, CityData]);

  // Handle search change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  if (loading) {
    return <Loader />; // Show loader while loading data
  }
 

  return (
    <div className="dark:bg-darkmode dark:text-white">
      <Breadcrumb items={breadcrumbItems} />

      <div
        className="bg-cover bg-center bg-no-repeat bg-gray-50 dark:bg-gray-200 my-10"
        style={{ backgroundImage: `url(${PopularInfluencerImg})` }}
      >
        <div className="container mx-auto px-28 py-20">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-20 ">
            {/* City Card 1 */}
            <Link to={`/city/influencer/${Slug}`} state={{ id: id }}>
              <div className="relative flex justify-center items-center group transform hover:scale-105 transition-all duration-300">
                <div className="relative w-64 h-64 flex justify-center items-center transform rotate-45 group-hover:rotate-0 transition-transform duration-300">
                  <img
                    src={InflencerImg}
                    alt="City"
                    className="absolute inset-0 w-full h-full object-cover shadow-2xl rounded-3xl transform transition-all duration-500 group-hover:scale-110"
                  />
                  {/* Dark overlay with opacity */}
                  <div className="absolute inset-0 bg-black opacity-30 rounded-3xl group-hover:opacity-30 transition-opacity duration-300"></div>
                </div>
                <div className="absolute bottom-24 left-1/2 transform -translate-x-1/2 text-center text-white transition-colors duration-300">
                  <h3 className="text-3xl font-medium mb-3 text-shadow-xl transform transition-transform duration-300 group-hover:translate-y-3">
                    Influencer
                  </h3>
                </div>
              </div>
            </Link>

            {/* City Card 2 */}
            <Link to={`/city/model/${Slug}`} state={{ id: id }}>
              <div className="relative flex justify-center items-center group transform hover:scale-105 transition-all duration-300">
                <div className="relative w-64 h-64 flex justify-center items-center transform rotate-45 group-hover:rotate-0 transition-transform duration-300">
                  <img
                    src={ModelImg}
                    alt="City"
                    className="absolute inset-0 w-full h-full object-cover shadow-2xl rounded-3xl transform transition-all duration-500 group-hover:scale-110"
                  />
                  {/* Dark overlay with opacity */}
                  <div className="absolute inset-0 bg-black opacity-30 rounded-3xl group-hover:opacity-30 transition-opacity duration-300"></div>
                </div>
                <div className="absolute bottom-24 left-1/2 transform -translate-x-1/2 text-center text-white transition-colors duration-300">
                  <h3 className="text-3xl font-medium mb-3 text-shadow-xl transform transition-transform duration-300 group-hover:translate-y-3">
                    Model
                  </h3>
                </div>
              </div>
            </Link>

            {/* City Card 3 */}
            <Link to={`/city/artist/${Slug}`} state={{ id: id }}>
              <div className="relative flex justify-center items-center group transform hover:scale-105 transition-all duration-300">
                <div className="relative w-64 h-64 flex justify-center items-center transform rotate-45 group-hover:rotate-0 transition-transform duration-300">
                  <img
                    src={ArtistImg}
                    alt="City"
                    className="absolute inset-0 w-full h-full object-cover shadow-2xl rounded-3xl transform transition-all duration-500 group-hover:scale-110"
                  />
                  {/* Dark overlay with opacity */}
                  <div className="absolute inset-0 bg-black opacity-30 rounded-3xl group-hover:opacity-30 transition-opacity duration-300"></div>
                </div>
                <div className="absolute bottom-24 left-1/2 transform -translate-x-1/2 text-center text-white transition-colors duration-300">
                  <h3 className="text-3xl font-medium mb-3 text-shadow-xl transform transition-transform duration-300 group-hover:translate-y-3">
                    Artist
                  </h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/*================================ All City Show  =================================*/}

      {/* <div className="container mx-auto mb-24">
        <div className="">
          <div className="bg-white dark:bg-darkmode lg:py-8 py-8">
            <div className="flex items-center justify-center flex-col">
              <p className="page-title text-center dark:text-white">
                Micro Influencers in India
              </p>
              <img src={titleimage} className="text-center title-icon" alt="" />
            </div>

          
            <div className="my-4 flex justify-end items-center relative">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search for a city..."
                className="px-6 py-2 rounded-lg border border-gray-300 dark:bg-darkmode dark:text-white dark:border-gray-600 w-80 md:w-96 text-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />

              {searchQuery && (
                <button
                  onClick={() => setSearchQuery("")}
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-white text-lg"
                >
                  <h1>
                    <RxCross2 />
                  </h1>
                </button>
              )}
            </div>

            {loading ? (
              <div className="flex justify-center items-center my-40">
                <ClipLoader color={"#f59231"} loading={loading} size={40} />
              </div>
            ) : (
              <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-3 md:gap-8 lg:mt-16 my-5">
                {filteredCities?.map((val, index) => {
                  return (
                    <Link
                      key={index}
                      to={`/allcityshow/${val.Slug}`}
                      state={{ id: val.Id }}
                    >
                      <div className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border">
                        <img
                          src={val.Icon}
                          className="w-full lg:h-36 h-32 rounded-t-xl"
                          alt=""
                        />
                        <p className="text-center py-3 text-black dark:text-white dark:bg-slate-900">
                          {val.Title}
                        </p>
                      </div>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AllCityShow;
