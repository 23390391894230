import React, { useEffect, useState } from "react";
import titleimage from "../../../Images/title-icon.png";
import { useFormik } from "formik";
import "react-country-state-city/dist/react-country-state-city.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import * as Yup from "yup";
import FormLoader from "../../../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import { AddAdvertiser } from "../../../API/AddAdvertiserApi";
import { useNavigate } from "react-router";
import usePageSEO from "../../usepageSEO/Index";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";
import Config from "../../../API/Config";

const RegisterEnterprice = () => {
  const validatePhone = (value, country) => {
    if (!value) return "Please enter your phone number";
    const phoneNumber = parsePhoneNumberFromString(value, country);

    if (!phoneNumber || !phoneNumber.isValid()) {
      return "Invalid phone number for selected country";
    }

    return null;
  };

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Please Enter Your Name"),
    Email: Yup.string()
      .email("Invalid email")
      .required("Please Enter Your Email"),
    Phone: Yup.string().required("Please Enter Your Phone"),
    Password: Yup.string().required("Please Enter Your Password"),
    BusinessName: Yup.string().required("Please Enter Your Business Name"),
    GSTNumber: Yup.string(),
    BusinessWebsite: Yup.string(),
    BusinessAddress: Yup.string().required(
      "Please Enter Your Business Address",
    ),
    BusinessCity: Yup.string().required("Please Enter Your Business City"),
    BusinessState: Yup.string().required("Please Enter Your Business State"),
    BusinessCountry: Yup.string().required(
      "Please Enter Your Business Country",
    ),
    ProfileImage: Yup.mixed()
      .required("Profile Picture is required")
      .test("fileSize", "File size must be less than 5 MB", (value) => {
        if (!value) return false;

        if (value && value instanceof File) {
          return value.size <= 5 * 1024 * 1024;
        }
        return false;
      }),
  });

  usePageSEO("Viral kar | Register", "Viral kar | Register", [
    "Viral kar | Register",
  ]);

  const AdvertiserId = Config.getAdvertiserIdForTemp();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const usenavigate = useNavigate();
  const [countryid, setCountryid] = useState(0);
  const [value, setValue] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateid, setStateId] = useState(null);

  const formik = useFormik({
    initialValues: {
      Id:AdvertiserId,
      Name: "",
      Email: "",
      Phone: "",
      Password: "",
      BusinessName: "",
      GSTNumber: "",
      BusinessWebsite: "",
      BusinessAddress: "",
      BusinessCity: "",
      BusinessState: "",
      BusinessCountry: "",
      ProfileImage: "",
    },
    validationSchema,
    validate: (values) => {
      const errors = {};
      const phoneError = validatePhone(values.Phone);
      if (phoneError) {
        errors.Phone = phoneError;
      }
      return errors;
    },
    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const result = await AddAdvertiser(formData,AdvertiserId);
        if (result.status === true) {
          usenavigate("/profile/advertiser");
        }
        if (result.Country) {
          setCountryid(result.Country);
          formik.setFieldValue("Country", result.Country);
          fetchStates(result.Country);
          console.log("Country set:", result.Country);
        }
        if (result.State) {
          setStateId(result.State);
          formik.setFieldValue("State", result.State);
          fetchCities(result.State);
          console.log("State set:", result.State);
        }
        if (result.City) {
          formik.setFieldValue("City", result.City);
          console.log("City set:", result.City);
        }
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://admin.viralkar.com/front/api/all-country",
      );
      setCountries(response.data.responsedata);
      console.log(response)
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-state/${countryId}`,
      );
      setStates(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-cities/${stateId}`,
      );
      setCities(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  useEffect(() => { 
    fetchCountries();
  }, []);

  return (
    <div>
      <div className="dark:bg-darkmode dark:text-white">
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-10 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center dark:text-white md:text-3xl">
              Advertiser Sign Up{" "}
            </p>
            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <form onSubmit={formik.handleSubmit} className="my-10">
            <div className="md:w-2/3 mx-auto">
              <div className="border rounded-md shadow-md md:p-8 p-5">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div className="mb-3">
                    <label htmlFor="" className="">Your Name <span className="text-red-600">*</span></label>
                    <input
                      type="text"
                      name="Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Your Name"
                      className="px-4 py-3 mt-2.5 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.Name && formik.errors.Name ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Name}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                  <label htmlFor="" className="">Your Business Email <span className="text-red-600">*</span></label>
                    <input
                      type="email"
                      name="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Your Business Email"
                      className="px-4 py-3 w-full focus:outline-none mt-2.5 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                  <label htmlFor="" className="">Your phone number <span className="text-red-600">*</span></label>
                    <PhoneInput
                      placeholder="Please Enter Your phone number"
                      value={value}
                      defaultCountry="IN"
                      onChange={(phone) => {
                        setValue(phone);
                        formik.setFieldValue("Phone", phone);
                      }}
                      onBlur={formik.handleBlur}
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border mt-2.5 border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.Phone && formik.errors.Phone ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Phone}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                  <label htmlFor="" className="">Your Password <span className="text-red-600">*</span></label>
                    <input
                      type="password"
                      name="Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Your Password"
                      className="px-4 py-3 w-full focus:outline-none mt-2.5 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.Password && formik.errors.Password ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Password}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                  <label htmlFor="" className="">Your Business Name<span className="text-red-600">*</span></label>
                    <input
                      type="text"
                      name="BusinessName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Your Business Name"
                      className="px-4 py-3 w-full focus:outline-none mt-2.5 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.BusinessName &&
                    formik.errors.BusinessName ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.BusinessName}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                  <label htmlFor="" className="">Your GST Number  </label>
                    <input
                      type="text"
                      name="GSTNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Your GST Number"
                      className="px-4 py-3 w-full focus:outline-none mt-2.5 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.GSTNumber && formik.errors.GSTNumber ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.GSTNumber}
                      </div>
                    ) : null}
                  </div>
                  
                  <div className="mb-3">
                  <label htmlFor="" className="">Select Your Country <span className="text-red-600">*</span></label>
                 
                  <select className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2.5  dark:bg-[#020617]"
                    name="BusinessCountry"
                    value={formik.values.BusinessCountry || ""}
                    onChange={(e) => {
                      const countryId = e.target.value;
                      formik.setFieldValue("BusinessCountry", countryId);
                      setCountryid(countryId);
                      fetchStates(countryId);
                    }}
                  >
                    <option value="" label="Please Select Your Country" />
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.Country && <div>{formik.errors.Country}</div>}
                  </div>

                  
                  <div className="mb-3">
                  <label htmlFor="" className="">Select Your State <span className="text-red-600">*</span></label>
                 
                 <select className="px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2.5 border border-zinc-200 rounded-md  dark:bg-[#020617]"
                    name="BusinessState"
                    value={formik.values.BusinessState || ""}
                    onChange={(e) => {
                      const stateId = e.target.value;
                      formik.setFieldValue("BusinessState", stateId);
                      setStateId(stateId);
                      fetchCities(stateId);
                    }}
                    disabled={!countryid}
                  >
                    <option value="" label="Please Select Your State" />
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.State && <div>{formik.errors.State}</div>}
                  </div>

                  <div className="mb-3">
                  <label htmlFor="" className="">Select Your City <span className="text-red-600">*</span></label>
 
                  <select className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2.5 dark:bg-[#020617]"
                    name="BusinessCity"
                    value={formik.values.BusinessCity || ""}
                    onChange={(e) =>
                      formik.setFieldValue("BusinessCity", e.target.value)
                    }
                    disabled={!stateid}
                  >
                    <option value="" label="Please Select Your City" />
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.City && <div>{formik.errors.City}</div>}
                  </div>

                  <div className="mb-3">
                  <label htmlFor="" className="">Your Business Website  </label>
                    <input
                      type="text"
                      name="BusinessWebsite"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Your Business Website"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border mt-2.5 border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.BusinessWebsite &&
                    formik.errors.BusinessWebsite ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.BusinessWebsite}
                      </div>
                    ) : null}
                  </div>
                
                <div className="my-3">
                <label htmlFor="" className="">Enter Your Address <span className="text-red-600">*</span></label>
                  <textarea
                    rows="3"
                    name="BusinessAddress"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Please Enter Your Address"
                    className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2.5 dark:bg-[#020617] "
                  ></textarea>
                  {formik.touched.BusinessAddress &&
                  formik.errors.BusinessAddress ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.BusinessAddress}
                    </div>
                  ) : null}
                </div>

                <div className="my-3 mt-5 ">
                  <label className="1">
                  Select Profile Picture <span className="text-red-600">*</span>
                    <input
                      type="file"
                      className="text-sm text-grey-500
                      file:mr-5 file:py-3 file:px-10
                      file:rounded-full file:border-0
                      file:text-md file:font-semibold  file:text-white
                      file:bg-gradient-to-r file:from-themecolor file:to-black
                      hover:file:cursor-pointer hover:file:opacity-80
                      p-4 py-3.5 w-full focus:outline-none my-1.5 border-zinc-400 rounded"
                      name="ProfileImage"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "ProfileImage",
                          event.currentTarget.files[0],
                        );
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="Business Country"
                    />
                  </label>
                  {formik.touched.ProfileImage && formik.errors.ProfileImage ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.ProfileImage}
                    </div>
                  ) : null}
                </div>
                </div>
                
                <div className="flex justify-center my-3">
                  <button
                    type="submit"
                    disabled={isFormLoading}
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto"
                  >
                    {isFormLoading ? "Signing Up..." : "Sign Up"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterEnterprice;
