import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import BlogBanner from "../../Images/BlogBanner.jpg";
import { Link, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/FadeLoader";

import { BlogbySlug, getRecentPostsAPI } from "../../API/BlogApi";
import Loader from "../../common/Loader";

const BlogDetails = () => {
  const { slug } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]); // State for recent posts
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await BlogbySlug(slug);
        console.log("API Response:", response); // Log response
        setBlogData(response);
      } catch (error) {
        setError(error.message || "Blog not found or an error occurred."); // Log specific error
        console.error("Error fetching blog data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRecentPosts = async () => {
      try {
        const response = await getRecentPostsAPI(); // Fetch recent posts
        setRecentPosts(response); // Store recent posts in state
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      }
    };

    fetchBlogData();
    fetchRecentPosts(); // Call the function to fetch recent posts
  }, [slug]);

  if (loading) {
    return <Loader />; // Show loader while loading data
  }
  if (error) return <p>{error}</p>;

  return (
    <div className="dark:bg-darkmode dark:text-white">
      <Breadcrumb items={[{ text: "Blog Details" }]} backgroundImage={BlogBanner} />
      <div className="container mx-auto pt-5 md:pt-10 pb-5 md:pb-10 px-3">
        <div className="md:flex gap-10">
          <div className="md:w-3/4">
            {blogData && (
              <div className="mb-10">
                <h1 className="pb-3 md:text-2xl text-lg font-semibold">{blogData.Title}</h1>
                <div className="border-t-4 border-themecolor w-1/4 flex"></div>
                <p className="text-sm text-gray-400 pt-2 pb-2">
                  {new Date(blogData.EntDt).toLocaleDateString()}
                </p>
                <p className="text-black pt-2 pb-5">
                  {blogData.ShortDescription}
                </p>

                <div className="overflow-hidden relative lg:h-[25rem] h-60 rounded-md mb-6">
                  <img
                    src={blogData.Image}
                    alt={blogData.Title}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="text-sm lg:text-base" dangerouslySetInnerHTML={{ __html: blogData.Content }} />
              </div>
            )}
          </div>
          <div className="md:w-1/4 mt-5 md:mt-0">
            <div className="mx-auto sticky lg:top-32">
              <div className="mb-5">
                <div className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border dark:border-gray-500">
                  <h1 className="text-center text-white text-lg font-semibold uppercase py-2 bg-themecolor1 rounded-t-lg">
                    Recent Posts
                  </h1>
                  <div className="py-4">
                    {recentPosts.length > 0 ? (
                      recentPosts.map((val, index) => (
                        <Link to={`/blog/${val.Slug}`} key={index}>
                          <div className="mb-3 flex px-3">
                            <div className="overflow-hidden relative h-20 w-40 rounded me-5">
                              <img
                                src={val.Image}
                                alt=""
                                className="h-full w-full object-cover object-top"
                              />
                            </div>
                            <div className="w-5/6">
                              <h1 className="text-sm font-medium line-clamp-2">
                                {val.Title}
                              </h1>
                              <p className="text-gray-400 text-[11px] pt-2">
                                {new Date(val.EntDt).toDateString()}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <div>No recent posts available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
