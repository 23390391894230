import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

export const InfluencerStatusCheck = async (data, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/influencer-status-1`,
      data
    );

    const responseData = response.data;

    if (responseData.responseData && response.data.status === true) {
      const { Id, Email, UserName, Phone, Step1, Step2, Step3 } =
      responseData.responseData;
      sessionStorage.setItem("InfluencerIdForTemp", JSON.stringify(Id));
      sessionStorage.setItem("InfluencerEmailForTemp", JSON.stringify(Email));
      sessionStorage.setItem("InfluencerUserNameForTemp",JSON.stringify(UserName));
      sessionStorage.setItem("InfluencerPhoneForTemp", JSON.stringify(Phone));
      sessionStorage.setItem("InfluencerStep1ForTemp", JSON.stringify(Step1));
      sessionStorage.setItem("InfluencerStep2ForTemp", JSON.stringify(Step2));
      sessionStorage.setItem("InfluencerStep3ForTemp", JSON.stringify(Step3));

      if (Step1 === 0 && Step2 === 0 && Step3 === 0) {
        navigate("/influencer/sign-up/influencer");
        return responseData; // Exit the function 
      } else if (Step1 === 1 && Step2 === 0 && Step3 === 0) {
        navigate("/influencer/sign-up/");
        return responseData; // Exit the funinfluencer2ction
      } else if (Step1 === 1 && Step2 === 1 && Step3 === 0) {
        navigate("/influencer/sign-up/influencer3");
        return responseData; // Exit the function
      } else {
        toast.success(
          "Email/Username Verification Successful! Welcome back, let’s continue your journey."
        );
        navigate("/influencer-password");
        return responseData; // Exit the function
      }
    }

    if (responseData.InsertId && response.data.status === true) {
      const InsertId = responseData.InsertId;

      if (InsertId) {
        sessionStorage.setItem("InfluencerIdForTemp", JSON.stringify(InsertId));
        navigate("/influencer/sign-up/influencer");
        toast.success(
          "You’re registered! Finish setting up your profile to unlock your influencer potential."
        );
      }
    }
  } catch (error) {
    navigate("/influencer/sign-up/influencer");
    throw error; // Re-throw the error after handling
  }
};

export const GoogleLoginInfluencerAPI = async (googleData, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/influencer-google-login`,
      googleData
    );

    const responseData = response.data;

    if (response.data.status === true) {
      
      sessionStorage.setItem(
        "Id",
        JSON.stringify(responseData.responseData.InfluencerId)
      );
      sessionStorage.setItem(
        "Type",
        JSON.stringify(responseData.responseData.Type)
      );
      sessionStorage.setItem(
        "Status",
        JSON.stringify(responseData.responseData.Status)
      );
      sessionStorage.setItem(
        "Token",
        JSON.stringify(responseData.token)
      );

      sessionStorage.removeItem("InfluencerIdForTemp");
      sessionStorage.removeItem("InfluencerEmailForTemp");
      sessionStorage.removeItem("InfluencerUserNameForTemp");
      sessionStorage.removeItem("InfluencerPhoneForTemp");
      sessionStorage.removeItem("InfluencerStep1ForTemp");
      sessionStorage.removeItem("InfluencerStep2ForTemp");
      sessionStorage.removeItem("InfluencerStep3ForTemp");

      toast.success(
        "Welcome back, influencer! You’re logged in and ready to shine!"
      );
      
      if (navigate) {
        navigate('/profile/influencer');
      }
    } else {
      
      toast.error("Invalid Password");
    }

    return responseData;
  } catch (error) {
    toast.error(
      "There was an issue logging in. Please try again."
    );
    throw error; 
  }
};

export const FacebookLoginInfluencerAPI = async (facebookData, navigate) =>{
  try {
    const response = await axios.post(
      `${API_BASE_URL}/influencer-facebook-login`,
      facebookData
    );

    const responseData = response.data;

    if (response.data.status === true) {
      
      sessionStorage.setItem(
        "Id",
        JSON.stringify(responseData.responseData.InfluencerId)
      );
      sessionStorage.setItem(
        "Type",
        JSON.stringify(responseData.responseData.Type)
      );
      sessionStorage.setItem(
        "Status",
        JSON.stringify(responseData.responseData.Status)
      );
      sessionStorage.setItem(
        "Token",
        JSON.stringify(responseData.token)
      );

      sessionStorage.removeItem("InfluencerIdForTemp");
      sessionStorage.removeItem("InfluencerEmailForTemp");
      sessionStorage.removeItem("InfluencerUserNameForTemp");
      sessionStorage.removeItem("InfluencerPhoneForTemp");
      sessionStorage.removeItem("InfluencerStep1ForTemp");
      sessionStorage.removeItem("InfluencerStep2ForTemp");
      sessionStorage.removeItem("InfluencerStep3ForTemp");

      toast.success(
        "Welcome back, influencer! You’re logged in and ready to shine!"
      );
      
      if (navigate) {
        navigate('/profile/influencer');
      }
    } else {
      toast.error("Invalid Password");
    }

    return responseData;
  } catch (error) {
    toast.error(
      "There was an issue logging in. Please try again."
    );
    throw error; 
  }
};

export const InfluencerPassword = async (data, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/influencer-verify-dev`,
      data
    );

    const responseData = response.data;

    if (response.data.status === true) {
      sessionStorage.setItem(
        "Id",
        JSON.stringify(responseData.responseData.InfluencerId)
      );
      sessionStorage.setItem(
        "Type",
        JSON.stringify(responseData.responseData.Type)
      );
      sessionStorage.setItem(
        "Status",
        JSON.stringify(responseData.responseData.Status)
      );
      sessionStorage.setItem(
        "Token",
        JSON.stringify(responseData.Token)
      );

      sessionStorage.removeItem("InfluencerIdForTemp");
      sessionStorage.removeItem("InfluencerEmailForTemp");
      sessionStorage.removeItem("InfluencerUserNameForTemp");
      sessionStorage.removeItem("InfluencerPhoneForTemp");
      sessionStorage.removeItem("InfluencerStep1ForTemp");
      sessionStorage.removeItem("InfluencerStep2ForTemp");
      sessionStorage.removeItem("InfluencerStep3ForTemp");

      toast.success("Welcome back, Inlfuencer! You’re logged in and ready to hit the runway!");
    } else {
      toast.error("Invalid Password and try again.");
    }

    return responseData;
  } catch (error) {
    toast.error("An error occurred while logging in. Please try again later.");
    throw error; // Rethrow the error for further handling if needed
  }
};

export const InfluencerGetById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencer/${Id}`);

    if (response.data.status) {
      return response.data.responseData[0]; // Return the first item directly
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("Error fetching influencer data:", error);
    throw error; // Rethrow the error to handle it in the calling function
  }
};
 

export const InfluencerStep1Add = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/influencer-step-1`,
      data
    );

    if (responce.data.status === true) {
      toast.success(
        "Step 1 Complete! You’re off to a great start-your influencer journey has just begun!"
      );
      return responce.data;
    } else {
      toast.error(
        "Oh no! We couldn’t finalize your profile. Let’s retry and get you live to connect with your followers!"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const InfluencerStep2Add = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/influencer-step-2`,
      data
    );

    if (responce.data.status === true) {
      toast.success(
        "Step 2 Done! Your profile is coming together - just one step away from going live!"
      );
      return responce.data;
    } else {
      toast.error(
        "We hit a snag! Please try submitting your profile details again and let’s keep your journey going."
      );
    }
  } catch (error) {
    throw error;
  }
};

export const InfluencerStep3Add = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/dev-influencer-step-3`,
      data
    );

    if (response.data.status === true) {
      sessionStorage.setItem(
        "Id",
        JSON.stringify(response.data.Id) 
      );
      sessionStorage.setItem(
        "Type",
        JSON.stringify(response.data.Type) 
      );
      sessionStorage.setItem(
        "UserName",
        JSON.stringify(response.data.UserName) 
      );
      sessionStorage.setItem(
        "Status",
        JSON.stringify(response.data.Status) 
      );
      sessionStorage.setItem(
        "Token",
        JSON.stringify(response.data.Token) 
      );

      //   -----------------------------remove----------------------------
      sessionStorage.removeItem("InfluencerIdForTemp");
      sessionStorage.removeItem("InfluencerEmailForTemp");
      sessionStorage.removeItem("InfluencerUserNameForTemp");
      sessionStorage.removeItem("InfluencerPhoneForTemp");
      sessionStorage.removeItem("InfluencerStep1ForTemp");
      sessionStorage.removeItem("InfluencerStep2ForTemp");
      sessionStorage.removeItem("InfluencerStep3ForTemp");
      toast.success(
        "Congrats! You’re officially an influencer-ready to make your mark!"
      );
      return response.data; // Return the entire response data for further use if needed
    } else {
      toast.error(
        "Oh no! We couldn’t finalize your profile. Let’s retry and get you live to connect with your followers!"
      );
    }
  } catch (error) {
    console.error("Error in InfluencerStep3Add:", error); // Log the error for debugging
    toast.error("An unexpected error occurred. Please try again later.");
    throw error; // Re-throw the error for further handling if needed
  }
};

export const UpdateModelInfById = async (Id, formdata) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/influencer/profile-update`,
      formdata
    );
    if (response.data.status === true) {
      toast.success(response.data.message);
      return response.data.responseData;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const PopularInfluencerAPI = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/popular-influencer`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw  error;
  }
}

export const FilterInfluencerAPI = async (CityId, CategoryId, Gender) => {
  try {
    const url = `${API_BASE_URL}/filter-influencers?CityId=${CityId}&CategoryId=${CategoryId}&Gender=${Gender}`;
    const response = await axios.get(url);

    if (response.data.status === true && response.data.responseData?.length > 0) {
      return response.data.responseData; // Return data
    } else {
      return []; // Return empty array if no data
    }
  } catch (error) {
    console.error("Error fetching influencers:", error);
    return []; // Return empty array on error
  }
};



export const getInfluencerById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencer/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getAllInfluencer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencer`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getPopulatInfluencer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/popular-influencer`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getInfluencerBySubCategoryId = async (CategoryId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/influencer/category/${CategoryId}`
    );
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const checkUsernameExists = async (username) => {
  try {
    const response = await fetch(`${API_BASE_URL}/influencer/username/check-username?username=${username}`);
    return await response.json(); // Return the JSON response directly
  } catch (error) {
    console.error("Error checking username:", error);
    return null; // Handle error
  }
};

 

