import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

export const AddAdvertiser = async (formData, Id) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/advertiser/${Id}`, formData);
    if (response.data.status === true) {
      sessionStorage.setItem("Token", JSON.stringify(response.data.Token));
      sessionStorage.setItem("Id", JSON.stringify(response.data.Id));
      sessionStorage.setItem("Type", JSON.stringify(response.data.Type));
      sessionStorage.setItem("Status", JSON.stringify(response.data.Status));
      toast.success(
        "Registration Successful! Get ready to elevate your brand with top-tier influencers and creators.",
      );
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// ------------------------------- Get User By Id -----------------------------------
export const getAdvertiserById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/advertiser/details/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// -------------------------------Get User By Id -----------------------------------
export const UpdateAdvertiserById = async (Id, formdata) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/${Id}`, formdata);
    if (response.data.status === true) {
      toast.success(response.data.message);
      sessionStorage.setItem("Token", JSON.stringify(response.data.token));
      sessionStorage.setItem("Id", JSON.stringify(response.data.UserId));
      sessionStorage.setItem("Type", JSON.stringify(response.data.Type));
      sessionStorage.setItem("Status", JSON.stringify(response.data.Type));
      return response.data.responseData;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// ---------------AdvertiserLogin--------------
// https://www.model.easyshadi.com/front/api/advitisor/login
export const AdvertiserLogin = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/advitisor/login`, data);
    if (response.data.status === true) {
      toast.success("User Login Successfully");
      sessionStorage.setItem("Token", JSON.stringify(response.data.token));
      sessionStorage.setItem("Id", JSON.stringify(response.data.UserId));
      sessionStorage.setItem("Type", JSON.stringify(response.data.Type));
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};



//=========================== Login and Register =====================
export const Advertisecheck = async (data, navigate) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/advertiser-Status`, data);
    const responseData = response.data;

    if (responseData.responseData && response.data.status === true) {
      const { Id, Email, Phone, Step, isEmailExist } = responseData.responseData; // Assuming `isEmailExist` is returned if email is already registered
      sessionStorage.setItem("AdvertiserIdForTemp", JSON.stringify(Id));
      sessionStorage.setItem("AdvertiserEmailForTemp", JSON.stringify(Email));
      sessionStorage.setItem("AdvertiserPhoneForTemp", JSON.stringify(Phone));
      
      if (isEmailExist) {
        // Redirect to password screen if email already exists
        toast.info("Email already exists. Redirecting to password screen.");
        navigate("/sign-up/advertiser/password");
        return responseData;
      }

      if (Step === 0) {
        navigate("/sign-up/advertiser");
        return responseData;
      } else {
        toast.success(
          "Email Verification Successful! Welcome back, let’s continue your journey."
        );
        navigate("/sign-up/advertiser/password");
        return responseData;
      }
    }

    if (responseData.InsertId && response.data.status === true) {
      const InsertId = responseData.InsertId;
      if (InsertId) {
        sessionStorage.setItem("AdvertiserIdForTemp", JSON.stringify(InsertId));
        navigate("/sign-up/advertiser");
        toast.success(
          "You’re registered! Finish setting up your profile to unlock your Advertise potential."
        );
      }
    }
  } catch (error) {
    navigate("/sign-up/advertiser");
    throw error;
  }
};

//================================ Update Advertisser Profile ==============

export const UpdateAdvertiserProfile = async (Id, formdata) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/advertiser/profile/${Id}`, formdata);
    if (response.data.status === true) {
      toast.success(response.data.message); 
      return response.data.responseData;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

//================================== Password ======================================
export const AdvertiserPassword = async (data, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/advertiser-verify`,
      data
    );

    const responseData = response.data;

    if (response.data.status === true) {
      sessionStorage.setItem(
        "Id",
        JSON.stringify(responseData.responseData.AdvertiserId )
      );
      sessionStorage.setItem(
        "Type",
        JSON.stringify(responseData.responseData.Type)
      );
      sessionStorage.setItem(
        "Status",
        JSON.stringify(responseData.responseData.Status)
      );
      sessionStorage.setItem(
        "Token",
        JSON.stringify(responseData.Token)
      );

      sessionStorage.removeItem("AdvertiserIdForTemp");
      sessionStorage.removeItem("AdvertiserEmailForTemp");
      sessionStorage.removeItem("AdvertiserPhoneForTemp");
      sessionStorage.removeItem("AdvertiserStepForTemp");
 

      toast.success("Welcome back, Inlfuencer! You’re logged in and ready to hit the runway!");
    } else {
      toast.error("Invalid Password and try again.");
    }

    return responseData;
  } catch (error) {
    toast.error("An error occurred while logging in. Please try again later.");
    throw error; // Rethrow the error for further handling if needed
  }
};
