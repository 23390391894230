import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import usePageSEO from "../usepageSEO/Index";
import { Link } from "react-router-dom";
import NoDataFound from "../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { getModelBySubCategoryId } from "../../API/ModelSignUpAPI";
import Loader from "../../common/Loader";

const ModelSubCategory = () => {
  const { Slug } = useParams();
  const breadcrumbItems = [
    {
      text: `${Slug.replace(/-/g, " ")} Model`,
    },
  ];

  const location = useLocation();
  const state = location.state || {}; // Default to empty object if no state

  const CategoryId = state.CategoryId || "No ID provided";
  console.log("Passed CategoryId:", CategoryId);

  const [InfluencerData, setInfluencerData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getData = async () => {
    try {
      const results = await getModelBySubCategoryId(CategoryId);
      setInfluencerData(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [CategoryId]);

  usePageSEO(
    "Viral kar | Category", // Use page title for SEO if available
    "Viral kar | Category", // Use page description for SEO if available
    ["Viral kar | Category"], // No keywords provided in this example
  );

  if (loading) {
    return <Loader />; // Show loader while loading data
  }
  return (
    <div className="dark:bg-darkmode dark:text-white">
      {/*-------------- male - Influencers -----------------*/}
      <Breadcrumb items={breadcrumbItems} />
      <div className="subscribe-bg lg:mb-0 relative bg-cover bg-center bg-no-repeat md:py-10 py-5 px-5 md:px-9 xl:px-0">
        {loading ? (
          <div className="flex justify-center items-center my-40">
            <ClipLoader color={"#f59231"} loading={loading} size={40} />
          </div>
        ) : (
          <div className="container mx-auto relative">
            {InfluencerData?.length === 0 ? (
              <NoDataFound />
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 container mx-auto ">
                {InfluencerData?.map((val, index) => {
                  return (
                    <div key={index} className="mb-5 px-3">
                      <div className="bg-white p-5 rounded-lg dark:border-gray-500 shadow-[0px_0px_12px_0px_rgba(0,0,0,0.3)] dark:bg-slate-900 border dark:text-white">
                        <div className="grid grid-cols-1 md:grid-cols-5 border-b border-gray-200">
                          <div className="col-span-2">
                            <Link to={`/model/${val.UserName}/${val.CitySlug}`}>
                              <div className=" md:p-5 md:pl-0 p-0">
                                <div className="border rounded-md">
                                  <img
                                    src={val.Path + val.ProfileImage}
                                    alt=""
                                    className="md:h-60 w-full p-2 border-themecolor h-72 surface:h-60 object-cover object-top"
                                  />
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-span-3 md:py-5 py-3">
                            <div>
                              <div className="flex items-center">
                                <Link
                                  to={`/model/${val.CityName}/${val.StageName}`}
                                >
                                  <p className="text-md font-semibold text-black dark:text-white my-2">
                                    {val.Name}
                                  </p>
                                </Link>
                                <span className="text-themecolor ml-2 text-sm font-normal">
                                  {val.Gender === "F"
                                    ? "(Female)"
                                    : val.Gender === "M"
                                      ? "(Male)"
                                      : val.Gender === "K"
                                        ? "(Kids)"
                                        : ""}
                                </span>
                              </div>
                              <div className="md:flex items-center text-textcolor dark:text-white mb-2">
                                <p className="flex items-center">
                                  <FaLocationDot className="text-green-500 text-sm" />
                                  <span className="px-1.5 text-sm">
                                    {val.CityName}
                                  </span>
                                </p>
                              </div>

                              <p className="flex flex-wrap">
                                <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                  <span className="text-gray-600 dark:text-gray-300 text-sm">
                                    Height :
                                  </span>{" "}
                                  <span className="text-themecolor font-semibold">
                                    {val.Height}
                                  </span>
                                </p>
                                <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                  <span className="text-gray-600 dark:text-gray-300 text-sm">
                                    Weight :
                                  </span>{" "}
                                  <span className="text-themecolor font-semibold">
                                    {val.Weight}
                                  </span>
                                </p>

                                <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                  <span className="text-gray-600 dark:text-gray-300 text-sm">
                                    Bust :
                                  </span>{" "}
                                  <span className="text-themecolor font-semibold">
                                    {val.Bust}
                                  </span>
                                </p>
                                <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                  <span className="text-gray-600 dark:text-gray-300 text-sm">
                                    Waist :
                                  </span>{" "}
                                  <span className="text-themecolor font-semibold">
                                    {val.Waist}
                                  </span>
                                </p>
                                <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                  <span className="text-gray-600 dark:text-gray-300 text-sm">
                                    Hips :
                                  </span>{" "}
                                  <span className="text-themecolor font-semibold">
                                    {val.Hips}
                                  </span>
                                </p>
                              </p>

                              {(val.Instagram ||
                                val.Facebook  ||
                                val.Twitter   ||
                                val.YouTube   ||
                                val.LinkedIn) && (
                                <div>
                                  <p className="flex items-center text-sm dark:text-white my-2 md:mb-3 text-gray-500">
                                    {val.Name}'s Social
                                  </p>

                                  <div className="text-white flex items-center mb-5">
                                    {val.Instagram && (
                                      <Link
                                        to={
                                          "https://www.instagram.com/viralkar.official/"
                                        }
                                        target="_blank"
                                        className="bg-instagram text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                      >
                                        <FaInstagram className="text-lg" />
                                      </Link>
                                    )}
                                    {val.Facebook && (
                                      <Link
                                        to={
                                          "https://www.facebook.com/viralkarr/"
                                        }
                                        target="_blank"
                                        className="bg-[#0165E1] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                      >
                                        <FaFacebook className="text-lg" />
                                      </Link>
                                    )}
                                    {val.Twitter && (
                                      <Link
                                        to={"https://x.com/viral_kar_"}
                                        target="_blank"
                                        className="bg-black text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                      >
                                        <FaXTwitter className="text-lg" />
                                      </Link>
                                    )}
                                    {val.YouTube && (
                                      <Link
                                        to={
                                          "https://www.youtube.com/@ViralKarr"
                                        }
                                        target="_blank"
                                        className="bg-red-600 text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                      >
                                        <FaYoutube className="text-lg" />
                                      </Link>
                                    )}
                                    {val.LinkedIn && (
                                      <Link
                                        to={
                                          "https://www.linkedin.com/company/viralkar"
                                        }
                                        target="_blank"
                                        className="bg-[#0077b5] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                      >
                                        <FaLinkedin className="text-lg" />
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <Link to={`/model/${val.UserName}/${val.CitySlug}`}>
                          <button
                            className="bg-themebgcolor3 hover:bg-themecolor1 dark:bg-themecolor1 text-sm mt-5 mx-auto block py-2 px-5 text-white rounded-full"
                            type="button"
                          >
                            Send Message to {val.name}
                          </button>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelSubCategory;
