import React, { useState, useEffect } from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import TermsCoditionBanner from "../../Images/TermsConditionBanner.jpeg";
import { GetPageBySlug } from "../../API/PagesApi";
import usePageSEO from "../usepageSEO/Index";
import Loader from "../../common/Loader";

const TermsCondition = () => {
  const [termsData, setTermsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const breadcrumbItems = [
    {
      text: "Terms & Conditions",
    },
  ];

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await GetPageBySlug("terms-condition");

        // Log the full response for debugging
        console.log("API Response:", response);

        if (response && response.length > 0) {
          setTermsData(response[0]); // Access the first object in the array
        } else {
          setError("No content available for the terms and conditions.");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching terms and conditions:", error);
        setError("Failed to load terms and conditions. Please try again later.");
        setLoading(false);
      }
    };

    fetchTerms();
  }, []);

  // Use SEO settings based on fetched termsData
  usePageSEO(
    termsData ? termsData.SeoTitle : "Viral kar | Influencer",
    termsData ? termsData.SeoKeyword : "Viral kar | Influencer",
    [termsData ? termsData.SeoDescription : "Viral kar | Influencer"]
  );

  if (loading) {
    return <Loader />; // Show loader while loading data
  }

  if (error) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb items={breadcrumbItems} backgroundImage={TermsCoditionBanner} />

      <main className="bg-gray-100 dark:bg-darkmode min-h-screen py-12 px-4">
        <section className="container mx-auto bg-white dark:bg-slate-950 dark:border dark:text-white dark:border-gray-500 lg:p-8 p-4 rounded-lg shadow-lg">
          <div className="mb-5">
            <h1 className="text-xl font-semibold mb-4">{termsData.Title}</h1>
            <p className="text-base">
              Please read these terms and conditions carefully before using our service.
            </p>
          </div>
          {termsData.Content ? (
            <div 
              className="text-gray-600 dark:text-gray-200 leading-relaxed text-sm" 
              dangerouslySetInnerHTML={{ __html: termsData.Content }} 
            />
          ) : (
            <p>No specific terms and conditions content available.</p>
          )}
        </section>
      </main>
    </div>
  );
};

export default TermsCondition;
