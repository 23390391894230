/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import FemaleInnerbanner from "../../Images/FemaleInnerbanner.jpg";
import BreadCrumb from "../../Breadcumb/Breadcumb";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { AddVendorInquiry } from "../../API/ModelInfMain";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../common/FormLoader";
import ScrollToTop from "./ScrollToTop";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../usepageSEO/Index";
import { getInfluencerByCityNameandUserName } from "../../API/NewInfluencerAPI";
import { IoIosCall } from "react-icons/io";
import Loader from "../../common/Loader";

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers")
    .required("Name is required"),
  Email: Yup.string().email("Invalid email").required("Email is required"),
  Phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .required("Number is required"),
  Message: Yup.string().required("Message is required"),
});

const InfluencerDetail = () => {
  const { CitySlug, UserName } = useParams();
  const [InfluencerData, setInfluencerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const messageSectionRef = useRef(null);
  const [id, setVendorId] = useState(null);

  // Fetch Influencer Data
  const getData = async () => {
    try {
      const results = await getInfluencerByCityNameandUserName(CitySlug, UserName);
      setInfluencerData(results);
      setVendorId(results?.Id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [CitySlug, UserName]);

  // SEO Metadata Hook
  usePageSEO(
    InfluencerData ? `${InfluencerData.Name} | Viral kar` : "Viral kar | Influencer",
    InfluencerData ? `${InfluencerData.Name} | Viral kar` : "Viral kar | Influencer",
    [InfluencerData ? `${InfluencerData.Name} | Viral kar` : "Viral kar | Influencer"]
  );

  // Formik form configuration
  const formik = useFormik({
    initialValues: {
      VendorId: id || "",
      Name: '',
      Email: '',
      Phone: '',
      Message: '',
      Type:'I'
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await AddVendorInquiry({ ...values, VendorId: id });
        if (response.status === true) {
          actions.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  const breadcrumbItems = [
    {
      text: InfluencerData?.Name,
    },
  ];

  const backgroundImage = FemaleInnerbanner;

  const calculateAge = (dob) => {
    if (!dob) return "";
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  if (loading) {
    return <Loader />; // Show loader while loading data
  }
  return (
    <div>
      <div>
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <BreadCrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
        <div className="dark:bg-darkmode dark:text-white md:py-10 py-5">
          <div className="container mx-auto px-2 xl:px-0 lg:px-4">
            {loading ? (
              <div className="flex justify-center items-center my-40">
                <ClipLoader color={"#f59231"} loading={loading} size={40} />
              </div>
            ) : (
              <React.Fragment>
                <div className="md:flex lg:gap-10">
                  <div className="md:w-2/5 md:mb-0 mb-4">
                    <div className="models-box relative">
                      <div className="relative overflow-hidden">
                        <img
                          src={InfluencerData?.Path + InfluencerData?.ProfileImage}
                          alt=""
                          className="md:h-[400px] w-full md:object-contain object-cover lg:object-center border rounded-md border-gray-200 bg-white p-2"
                        />
                        <div className="absolute bottom-1.5 right-1.5 flex flex-col">
                          {(InfluencerData.Instragram || InfluencerData.Facebook || InfluencerData.Twitter || InfluencerData.YouTube || InfluencerData.LinkedIn) && (
                            <div>
                              <div className="text-white grid items-center mb-5 space-y-3">
                                {InfluencerData.Instragram && (
                                  <Link to={"https://www.instagram.com/viralkar.official/"} target="_blank" className="bg-instagram text-white rounded p-2 text-lg mx-1 drop-shadow-lg">
                                    <FaInstagram className="text-lg" />
                                  </Link>
                                )}
                                {InfluencerData.Facebook && (
                                  <Link to={"https://www.facebook.com/viralkarr/"} target="_blank" className="bg-[#0165E1] text-white rounded p-2 text-lg mx-1 drop-shadow-lg">
                                    <FaFacebook className="text-lg" />
                                  </Link>
                                )}
                                {InfluencerData.Twitter && (
                                  <Link to={"https://x.com/viral_kar_"} target="_blank" className="bg-black text-white rounded p-2 text-lg mx-1 drop-shadow-lg">
                                    <FaXTwitter className="text-lg" />
                                  </Link>
                                )}
                                {InfluencerData.YouTube && (
                                  <Link to={"https://www.youtube.com/@ViralKarr"} target="_blank" className="bg-red-600 text-white rounded p-2 text-lg mx-1 drop-shadow-lg">
                                    <FaYoutube className="text-lg" />
                                  </Link>
                                )}
                                {InfluencerData.LinkedIn && (
                                  <Link to={"https://www.linkedin.com/company/viralkar"} target="_blank" className="bg-[#0077b5] text-white rounded p-2 text-lg mx-1 drop-shadow-lg">
                                    <FaLinkedin className="text-lg" />
                                  </Link>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-3/5 ">
                    <div className="grid grid-cols-1 xl:gap-4 lg:gap-0">
                      <div>
                        <div className="bg-white rounded-lg dark:border-gray-100 shadow-lg dark:bg-zinc-800 border dark:text-white">
                          <table className="table-auto w-full">
                            <tbody>
                              {[
                                { label: "Name", value: `${InfluencerData?.Name} ${InfluencerData?.Gender === "F" ? "(Female)" : InfluencerData?.Gender === "M" ? "(Male)" : InfluencerData?.Gender === "K" ? "(Kids)" : ""}` },
                                { label: "Age", value: `${calculateAge(InfluencerData?.DOB)} years` },
                                { label: "Country", value: InfluencerData?.CountryName },
                                { label: "State", value: InfluencerData?.StateName },
                                { label: "City", value: InfluencerData?.CityName },
                                { label: "Category", value: InfluencerData?.ChildCategoryNames?.join(", ") },
                                { label: "Collaborations", value: InfluencerData?.CollaborationsType?.join(", ") },
                                { label: "Languages", value: InfluencerData?.Languages },
                                { label: "Engagement Rate", value: InfluencerData?.EngagementRate },
                                { label: "Follower Count", value: InfluencerData?.FollowerCount },
                                { label: "Collaboration Industries", value: InfluencerData?.CollaborationIndustries }
                              ].filter((field) => field.value).map((field, index, arr) => (
                                <tr key={index} className={`flex flex-col md:flex-row md:justify-between p-2 ${index < arr.length - 1 ? "border-b" : ""}`}>
                                  <td className="text-left text-md font-medium text-black dark:text-white my-auto md:w-1/3">
                                    {field.label}:
                                  </td>
                                  <td className="my-auto text-sm text-gray-600 dark:text-zinc-300 md:w-2/3">
                                    {field.value}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {InfluencerData?.ContentDescriptionBio && (
                      <div className="dark:bg-zinc-800 dark:text-white border border-gray-200 shadow-md rounded-md p-4 mt-5">
                        <div className="flex justify-center space-x-2">
                          <p className="text-md text-gray-500 dark:text-white">
                            {InfluencerData?.ContentDescriptionBio}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="grid grid-cols-2 lg:grid-cols-2 mt-4 lg:gap-10">
                      <div className="lg:ml-auto">
                        <button
                          type="button"
                          onClick={() => messageSectionRef.current.scrollIntoView({ behavior: "smooth" })}
                          className="bg-themebgcolor3 flex p-3 rounded-full lg:px-6 px-4 mx-auto block font-medium lg:text-sm text-xs mt-4 border text-white hover:bg-black"
                        >
                          <IoIosCall className="text-xl my-auto me-1"/>
                          Contact Influencer
                        </button>
                      </div>
                      <div className="lg:mr-auto">
                        <Link to={"https://www.whatsapp.com/channel/0029Van2Z041iUxRfvDuN02u"} target="_blank">
                          <button
                            type="button"
                            className="bg-[#25D366] p-3 rounded-full px-6 flex mx-auto block font-medium lg:text-sm text-xs mt-4 border text-white hover:bg-black"
                          >
                            <FaWhatsapp className="text-xl my-auto me-1"/>
                            WhatsApp
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            <div ref={messageSectionRef} className="md:w-3/5 mx-auto">
              <div className="dark:bg-zinc-800 border border-gray-200 shadow-md rounded-md p-6 mt-10">
                <h1 className="md:text-2xl text-center font-semibold mb-5">
                  Hire {InfluencerData?.Name}
                </h1>
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                    <div>
                      <label className="pb-2 block" htmlFor="Name">
                        Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Name}
                        className="p-3 w-full dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                        placeholder="Enter Your Name"
                      />
                      {formik.touched.Name && formik.errors.Name && (
                        <div className="text-red-500 text-sm">{formik.errors.Name}</div>
                      )}
                    </div>
                    <div>
                      <label className="pb-2 block" htmlFor="Email">
                        Email <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="email"
                        name="Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Email}
                        className="p-3 w-full dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                        placeholder="Enter Your Email"
                      />
                      {formik.touched.Email && formik.errors.Email && (
                        <div className="text-red-500 text-sm">{formik.errors.Email}</div>
                      )}
                    </div>
                    <div>
                      <label className="pb-2 block" htmlFor="Phone">
                        Phone <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="Phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Phone}
                        className="p-3 w-full dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                        placeholder="Enter Your Mobile"
                      />
                      {formik.touched.Phone && formik.errors.Phone && (
                        <div className="text-red-500 text-sm">{formik.errors.Phone}</div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5">
                    <label className="pb-2 block" htmlFor="Message">
                      Message <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      name="Message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Message}
                      rows="4"
                      className="w-full p-3 dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                      placeholder="Message"
                    ></textarea>
                    {formik.touched.Message && formik.errors.Message && (
                      <div className="text-red-500 text-sm">{formik.errors.Message}</div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="bg-themecolor p-3 rounded-full px-8 mx-auto block font-medium text-sm mt-4 border text-white hover:bg-black"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerDetail;
