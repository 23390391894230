import React from 'react'
import logo from '../Images/logo.png';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-overlay">
        <div className="logo-with-loader">
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </div>
    </div>
  )
}

export default Loader