import React, { useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import logo from "../../../../Images/logo.png";
import sectionbg1 from "../../../../Images/Model-Register-BG.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FaFacebookF } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { GoogleLoginModelAPI, ModelStatusCheck, FacebookLoginModelAPI } from "../../../../API/ModelSignUpAPI";

const LoginModel = () => {
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [loginMethod, setLoginMethod] = useState("email");
  const [networkError, setNetworkError] = useState(null); // State for network error
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
    },
    validateOnBlur: true,
    onSubmit: async (values) => {
      setIsFormLoading(true);
      setNetworkError(null); // Reset network error

      try {
        if (loginMethod === "email") {
          await Yup.object().shape({
            email: Yup.string()
              .required("Email address is required")
              .email("Invalid email address"),
          }).validate({ email: values.email });

          await ModelStatusCheck({ Email: values.email }, navigate);
        } else if (loginMethod === "phone") {
          await Yup.object().shape({
            phone: Yup.string()
              .required("Mobile Number is required")
              .test('is-valid-phone', 'Invalid mobile number', value => {
                const phoneRegex = /^\+?[1-9]\d{1,14}$/;
                return phoneRegex.test(value);
              }),
          }).validate({ phone: values.phone });

          await ModelStatusCheck({ Phone: values.phone }, navigate);
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        if (error.message.includes("Network Error")) {
          setNetworkError("Network error, please try again later.");
        } else {
          formik.setErrors({
            email: error.errors ? error.errors[0] : undefined,
            phone: error.errors ? error.errors[0] : undefined,
          });
        }
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  const handleGoogleSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    try {
      const user = jwtDecode(token);
      await GoogleLoginModelAPI(
        {
          AuthId: user.sub,
          Email: user.email,
          UserName: user.name,
        },
        navigate
      );
    } catch (error) {
      console.error("Google Login Failed:", error);
      setNetworkError("Network error during Google login, please try again.");
    }
  };

  const responseFacebook = async (response) => {
    console.log("Facebook Response:", response);
    if (response.accessToken) {
      try {
        await FacebookLoginAPI(response.accessToken, response.userID, response.name, response.email);
      } catch (error) {
        console.error("Facebook Login API Error:", error);
        setNetworkError("Network error during Facebook login, please try again.");
      }
    } else {
      console.error("Facebook login failed or was cancelled by the user");
    }
  };

  const FacebookLoginAPI = async (accessToken, userID, name, email) => {
    try {
      await FacebookLoginModelAPI(
        {
          AuthId: userID,
          AccessToken: accessToken,
          UserName: name,
          Email: email
        },
        navigate
      );
    } catch (error) {
      console.error("Facebook Login Failed:", error);
      setNetworkError("Network error during Facebook login, please try again.");
    }
  };

  const handlePhoneChange = (value) => {
    formik.setFieldValue("phone", value);
  };

  return (
    <GoogleOAuthProvider clientId="your-client-id">
      <div className="bg-dark">
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-10 px-5 xl:px-0">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center pb-2 capitalize dark:text-white">
              Step into the limelight register or log in to showcase your style
            </p>
            <img src={titleimage} className="text-center title-icon" alt="Title Icon" />
          </div>
          <form onSubmit={formik.handleSubmit} className="py-10">
            <div className="flex items-center justify-center">
              <div className="surface:w-4/5 xl:w-3/5 rounded-lg bg-dark-light dark:bg-white dark:text-black p-5 surface:p-0 md:p-0 grid md:grid-cols-2 grid-cols-1 dark:border border-white w-full">
                <div className="col-span-1 my-auto md:mx-5">
                  <img
                    src={logo}
                    className="bg-white p-2 w-64 rounded mx-auto mb-10"
                    alt="Logo"
                  />
                  <div className="mb-4 xl:px-10">
                    <label className="block mb-5">
                      Select Login Method <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center space-x-4">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="loginMethod"
                          value="email"
                          checked={loginMethod === "email"}
                          onChange={() => setLoginMethod("email")}
                          className="mr-2"
                        />
                        Email
                      </label>
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="loginMethod"
                          value="phone"
                          checked={loginMethod === "phone"}
                          onChange={() => setLoginMethod("phone")}
                          className="mr-2"
                        />
                        Phone
                      </label>
                    </div>
                  </div>
                  {loginMethod === "email" && (
                    <div className="mb-4 xl:px-10">
                      <label>
                        Email Address <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        placeholder="Enter your email"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-white"
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="text-red-500 text-sm">{formik.errors.email}</div>
                      )}
                    </div>
                  )}
                  {loginMethod === "phone" && (
                    <div className="mb-4 xl:px-10">
                      <label>
                        Mobile Number <span className="text-red-500">*</span>
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={formik.values.phone}
                        onChange={handlePhoneChange}
                        international
                        defaultCountry="IN"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 "
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <div className="text-red-500 text-sm">{formik.errors.phone}</div>
                      )}
                    </div>
                  )}
                  {networkError && (
                    <div className="text-red-500 text-sm">{networkError}</div> // Display network error
                  )}
                  <div className="flex justify-center my-3">
                    <button
                      type="submit"
                      className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto"
                    >
                      Register
                    </button>
                  </div>
                  <div className="relative py-3">
                    <div className="border border-zinc-200"></div>
                    <p className="text-center absolute px-2 top-0 left-1/2 transform -translate-x-1/2 bg-white ">Or</p>
                  </div>

                  <div className="xl:flex gap-5 justify-around mt-5">
                    <div className="col-span-1 md:w-[175px] mb-3 fb-mobile">
                      <FacebookLogin
                        appId="844919764444509"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        render={renderProps => (
                          <button
                            className="bg-[#3B5998] w-full flex text-sm justify-center items-center text-white text-md px-3 py-2.5 rounded-md"
                            onClick={renderProps.onClick}
                          >
                            <FaFacebookF className="mr-2" />
                            Facebook
                          </button>
                        )}
                      />
                    </div>
                    <div className="col-span-1 flex md:w-[175px] justify-center">
                      <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onFailure={(error) => {
                          console.error("Google Login Error:", error);
                          setNetworkError("Network error during Google login, please try again.");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-1 hidden md:flex bg-cover bg-center rounded-md">
                  <img src={sectionbg1} alt="Background" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginModel;
