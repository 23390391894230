import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../Breadcumb/Breadcumb';
import NoDataFound from "../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import { FaFacebook, FaInstagram, FaLinkedin, FaSignLanguage, FaYoutube } from 'react-icons/fa';
import { FaLocationDot, FaXTwitter } from 'react-icons/fa6';
import { getArtistBySubCategoryId } from '../../API/ArtistSignUpAPI';
import Loader from '../../common/Loader';

const ArtistSubCategory = () => {
  const [artist, setArtist] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { Slug } = useParams();
  const breadcrumbItems = [
    {
      text: `${Slug.replace(/-/g, ' ')} Artist` // Replacing dashes with spaces
    }
  ];

  const location = useLocation();
  const state = location.state || {}; // Default to empty object if no state
  const CategoryId = state.CategoryId || "No ID provided";
  console.log("Passed CategoryId:", CategoryId);

  useEffect(() => {
    const fetchArtistData = async () => {
      try {
        const results = await getArtistBySubCategoryId(CategoryId);
        setArtist(results);
      } catch (error) {
        console.error(error);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };
  
    fetchArtistData();
  }, [CategoryId]);
  

  if (loading) return   <div className="flex justify-center items-center my-40">
  <ClipLoader color={"#f59231"} loading={loading} size={40} />
</div>;
  if (error) return <div>   <NoDataFound /></div>;

  // Check if artist array is empty
  if (artist.length === 0) return <NoDataFound />;

  if (loading) {
    return <Loader />; // Show loader while loading data
  }

  return (
    <div className="dark:bg-darkmode dark:text-white">
      <Breadcrumb items={breadcrumbItems} />
      <div className="subscribe-bg lg:mb-0 relative bg-cover bg-center bg-no-repeat md:py-10 py-5 px-5 md:px-9 xl:px-0">
        <div className="container mx-auto relative">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
            {artist.map((val, index) => (
              <div key={index} className="px-2 py-4 border mb-4 shadow-sm flex flex-col max-w-[24rem] justify-start rounded-[2.5rem] bg-gradient-to-b from-[#efefef] to-transparent">
                <div className="h-fit overflow-hidden mx-auto rounded-t-full rounded-br-full">
                 <Link to={`/artist/${val.ArtistName}/${val.CitySlug}`}>
                    <img
                      src={val.Path + val.ProfileImage}
                      alt={val.Name}
                      className="min-w-[18rem] min-h-[18rem] max-w-[18rem] max-h-[18rem] rounded-t-full object-cover transition-all duration-300 cursor-pointer scale-100 hover:scale-110"
                    />
                  </Link>
                </div>
                <div className="font-semibold leading-[38.73px] text-xl dark:text-themecolor mt-4 mb-2 line-clamp-1">
                 <Link to={`/artist/${val.ArtistName}/${val.CitySlug}`}>{val.Name}</Link>
                </div>
                <div className="flex items-center mb-3">
                  <FaSignLanguage className="text-sm text-themecolor" />
                  <label className="ml-2 text-sm leading-4 font-normal text-themebgcolor3 dark:text-white line-clamp-2">
                    {val.Languages?.join(", ") || "Languages not specified"}
                  </label>
                </div>
                <div className="md:flex items-center text-textcolor dark:text-white">
                  <p className="flex items-center">
                    <FaLocationDot className="text-green-500 text-sm" />
                    <span className="px-1.5 text-sm dark:text-white">{val.CityName || "Unknown Location"}</span>
                  </p>
                </div>
                {(val.Instagram || val.Facebook || val.Twitter || val.YouTube || val.LinkedIn) && (
                  <div>
                    <p className="flex items-center text-sm dark:text-white my-2 md:mb-3 text-gray-500">
                      {val.Name}'s Social
                    </p>
                    <div className="text-white flex items-center mb-5">
                      {val.Instagram && (
                        <Link to={val.Instagram} target="_blank" className="bg-instagram text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                          <FaInstagram className="text-lg" />
                        </Link>
                      )}
                      {val.Facebook && (
                        <Link to={val.Facebook} target="_blank" className="bg-[#0165E1] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                          <FaFacebook className="text-lg" />
                        </Link>
                      )}
                      {val.Twitter && (
                        <Link to={val.Twitter} target="_blank" className="bg-black text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                          <FaXTwitter className="text-lg" />
                        </Link>
                      )}
                      {val.YouTube && (
                        <Link to={val.YouTube} target="_blank" className="bg-red-600 text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                          <FaYoutube className="text-lg" />
                        </Link>
                      )}
                      {val.LinkedIn && (
                        <Link to={val.LinkedIn} target="_blank" className="bg-[#0077b5] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                          <FaLinkedin className="text-lg" />
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistSubCategory;
