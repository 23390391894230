import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ScrollToTop from "../ScrollToTop";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../common/FormLoader";
import Config from "../../../API/Config";
import { ModelGetById, UpdateModelInfById } from "../../../API/ModelSignUpAPI";
import { useData } from "../../../Context/DataContext ";
import { format } from "date-fns";
import usePageSEO from "../../usepageSEO/Index";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import userImage from "../../../Images/usericon.png";
import { getAllCollbration } from "../../../API/CollabrationAPi";
import axios from "axios";


const ModelProfile = () => {
  usePageSEO(
    "Viral kar | Dashboard",
    "Viral kar | Dashboard",
    ["Viral kar | Dashboard"]
  );
  
  const [ThumbImagePreview, setThumbImagePreview] = useState();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const Id = Config.getId();
  const Status = Config.getStatus();
  const { CityData, CategoryData } = useData();
  const [countryid, setCountryid] = useState(0);
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateid, setStateId] = useState(null);

 
  
  // Formik Initialization
  const formik = useFormik({
    initialValues: {
      ModelId: Id,
      Name: "",
      Email: "",
      Phone: "",
      DOB: "", 
      Gender: "",
      Height: "",
      Weight: "", 
      Bust: "",
      Waist: "",
      Hips: "",
      Instagram: "",
      Facebook: "",
      YouTube: "",
      Twitter: "", 
      Other:"",
      ProfilePicture: "",
      HidImg: "",
      Category: [],
      Language: [],
      OtherLanguage: "",
      CollaborationsType: [], 
      YearsofExperience: "",
      Country: "",
      State: "",
      City: "",
      AvailableforTravel : "",
      AvailabilityforWork: "",
      CollaborationIndustries: "",
      Website: "",
      PreviousAgencies:"",
      LinkedIn:""
    },
  
    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        const finalValues = {
          ...values,
          Language: values.Language.includes("Other")
            ? [...values.Language.filter(lang => lang !== "Other"), values.OtherLanguage]
            : values.Language,
        };
    
        const formData = new FormData();
        Object.entries(finalValues).forEach(([key, value]) => {
          if (key !== "CompositeCardImages" && key !== "VendorImages") {
            formData.append(key, value);
          }
        });
    
        await UpdateModelInfById( formData);
        fetchData(); // Refetch data after submission
    
        // Success notification
        Swal.fire({
          title: "Success!",
          text: "Model information updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("Error updating model information:", error);
    
        // Error notification
        Swal.fire({
          title: "Error!",
          text: "There was an error updating the model information. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setIsFormLoading(false);
      }
    },
  });
  
  const [CollbrationData, setCollbrationData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [CollbrationResult] = await Promise.all([getAllCollbration()]);
        setCollbrationData(CollbrationResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    fetchCountries();
  }, []);
  
  const fetchData = async () => {
    try {
      const result = await ModelGetById(Id);
      
      if (result.ProfileImage) {
        setThumbImagePreview(result.Path + result.ProfileImage);
      }
  
      const formattedDate = result.DOB ? format(new Date(result.DOB), "yyyy-MM-dd") : "";
  
      formik.setValues({
        ...formik.values,
        ModelId : Id,
        Name: result.Name || "",
        Email: result.Email || "",
        Phone: result.Phone || "",
        DOB: formattedDate, 
        Gender : result.Gender  || "",
        Height: result.Height || "",
        Weight: result.Weight || "", 
        Bust: result.Bust || "",
        Waist: result.Waist || "",
        Hips: result.Hips || "",
        Instagram: result.Instagram || "",
        Facebook: result.Facebook || "",
        YouTube: result.YouTube || "",
        Twitter: result.Twitter || "", 
        Other:result.Other || "",
        ProfilePicture: result.ProfileImage || "",
        HidImg: result.Hid_Image || "",
        Category : result.Category  ? result.Category.map(Number) : [],
        Language: result.Languages ? result.Languages.split(",") : [],
        CollaborationsType: result.CollaborationsType || [],
        YearsofExperience: result.YearsofExperience || "",
        Country : result.Country  || "",
        State : result.State  || "",
        City: result.City || "",
        AvailableforTravel : result.AvailableforTravel  || "",
        AvailabilityforWork : result.AvailabilityforWork  || "",
        CollaborationIndustries: result.CollaborationIndustries || "",
        Website: result.Website || "",
        PreviousAgencies: result.PreviousAgencies || "",
        LinkedIn: result.LinkedIn ||""
      });

      if (result.Country) {
        setCountryid(result.Country);
        formik.setFieldValue("Country", result.Country);
        fetchStates(result.Country);
        console.log("Country set:", result.Country);
      }
      if (result.State) {
        setStateId(result.State);
        formik.setFieldValue("State", result.State);
        fetchCities(result.State);
        console.log("State set:", result.State);
      }
      if (result.CityName) {
        formik.setFieldValue("City", result.CityName);
        console.log("City set:", result.CityName);
      }
  
      sessionStorage.setItem("getvendorStatus", result.Status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  


  useEffect(() => {
    fetchData();
  }, [Id]);
  
  useEffect(() => {
    if (ThumbImagePreview) {
      sessionStorage.setItem("ProfilePicture", ThumbImagePreview);
    }
  }, [ThumbImagePreview]);
  
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const currentCategories = formik.values.Category || [];
  
    formik.setFieldValue('Category', checked
      ? [...currentCategories, value]
      : currentCategories.filter(val => val !== value)
    );
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://admin.viralkar.com/front/api/all-country",
      );
      setCountries(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-state/${countryId}`,
      );
      setStates(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-cities/${stateId}`,
      );
      setCities(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };
  
  // Handle Language Checkbox Changes
  const handleLanguageCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const currentLanguages = formik.values.Language || [];
  
    if (value === "Other") {
      formik.setFieldValue("Language", checked
        ? [...currentLanguages, "Other"]
        : currentLanguages.filter(lang => lang !== "Other")
      );
      formik.setFieldValue("OtherLanguage", checked ? "" : ""); // Reset other language
    } else {
      formik.setFieldValue("Language", checked
        ? [...currentLanguages, value]
        : currentLanguages.filter(lang => lang !== value)
      );
    }
  };
  
  // Profile Image Section
  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        navigate("/"); // Redirect to home after logout
      }
    });
  };
  
  const location = useLocation();
  const getPathByType = (type) => {
    switch (type) {
      case "I":
        return "/profile/influencer"; // Path for Influencer
      case "M":
        return "/profile/model"; // Path for Model
      case "A":
        return "/profile/artist"; // Path for Artist
      default:
        return "/profileview"; // Default path if Type does not match
    }
  };
  
  const type = Config.getType();
  const menuItems = [
    { path: getPathByType(type), label: "Profile" },
  ];
  
  const isActive = (path) => {
    return location.pathname === path;
  };
  
  
    
  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="">
        <ScrollToTop />
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-4 py-5 md:py-14 px-3 md:gap-10 ">
            
            <div className=""> 
          <div className=" mb-5 md:mb-0 lg:sticky lg:top-24">
          <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
              <ul className="flex mb-5 list-none flex-col" role="tablist">
                <img
                  src={ThumbImagePreview === "undefined" ? userImage : ThumbImagePreview}
                  alt="User Profile"
                  className="rounded-full h-32 w-32 mb-5 shadow-2xl p-2 mx-auto border object-cover object-top"
                />
                {menuItems.map((item, index) => (
                  <li
                    key={index}
                    className={`last:mr-0 text-center border dark:border-zinc-600 my-1.5 ${
                      isActive(item.path) ? "bg-themecolor1 text-white" : ""
                    }`}
                  >
                    <Link
                      to={item.path}
                      className={`text-xs font-bold uppercase px-0 py-4 border-none block leading-normal ${
                        isActive(item.path) ? "bg-themecolor1 text-white" : ""
                      }`}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
                <li className="last:mr-0 my-1.5 text-center border dark:border-zinc-600 cursor-pointer">
                  <div
                    onClick={logout}
                    className="text-xs font-bold uppercase px-0 py-4 border-none block leading-normal"
                  >
                    Logout
                  </div>
                </li>
              </ul>
            </div>
          </div>
          </div>
            {/* ===============================Nav Pilss data ====================================*/}
            <div className="col-span-3">
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
              <div className="bg-dark-light">
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="hidden"
                    name="HidImg"
                    value={formik.values.HidImg}
                  />
                  <div className="">
                    <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                      {Status === 0 ? (
                        <div
                          class="bg-orange-100 border-t-2 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-semibold text-sm">
                              Your Profile is Under Review
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          class="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-bold">Your Profile is Approved</p>
                          </div>
                        </div>
                      )}
                      <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                      Details
                      </p>
                     
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label className="text-base" for="">
                            Name
                          </label>
                          <input
                            type="text"
                            name="Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Name}
                            placeholder="Name"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Name && formik.errors.Name ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Name}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Email
                           
                          </label>
                          <input
                            type="email"
                            name="Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Email}
                            disabled
                            placeholder="Email Address"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Email && formik.errors.Email ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Email}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Phone
                          </label>
                          <input
                            type="text"
                            name="Phone"
                            disabled
                            readOnly
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Phone}
                            placeholder="Phone"
                            className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Phone && formik.errors.Phone ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Phone}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            DOB
                          </label>
                          <input
                            type="date"
                            name="DOB"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.DOB}
                            placeholder="DOB"
                            className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.DOB && formik.errors.DOB ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.DOB}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                            <label for="">Profile Picture </label>
                            <input
                              type="file"
                              name="ProfilePicture"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "ProfilePicture",
                                  event.currentTarget.files[0],
                                );
                                setThumbImagePreview(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0],
                                  ),
                                ); // Show a preview of the new image
                              }}
                              onBlur={formik.handleBlur}
                              className="text-sm text-grey-500 file:mr-5 mt-3 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold file:text-white
                                file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none border-0 rounded"
                            />
                            {formik.touched.ProfilePicture &&
                            formik.errors.ProfilePicture ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.ProfilePicture}
                              </div>
                            ) : null}
                          </div>
                       
                        <div className="my-1">
                      <label className="text-base">Gender</label>
                      <div className="flex items-center space-x-4 mt-3">
                        <label>
                          <input
                            type="radio"
                            name="Gender"
                            value="Male"
                            checked={formik.values.Gender === 'Male'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mr-2"
                          />
                          Male
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="Gender"
                            value="Female"
                            checked={formik.values.Gender === 'Female'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mr-2"
                          />
                          Female
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="Gender"
                            value="Kid"
                            checked={formik.values.Gender === 'Kid'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="mr-2"
                          />
                          Kid
                        </label>
                      </div>
                      {formik.touched.Gender && formik.errors.Gender ? (
                        <div className="text-red-500 text-sm">{formik.errors.Gender}</div>
                      ) : null}
                    </div>

                    <div className="">
                    <label>Country</label>
                    <select className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      name="Country"
                      value={formik.values.Country || ""}
                      onChange={(e) => {
                        const countryId = e.target.value;
                        formik.setFieldValue("Country", countryId);
                        setCountryid(countryId);
                        fetchStates(countryId);
                      }}
                    >
                      <option value="" label="Select Country" />
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.Country && (
                      <div>{formik.errors.Country}</div>
                    )}
                    </div>

                    <div className="">
                    <label>State</label>
                    <select className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      name="State"
                      value={formik.values.State || ""}
                      onChange={(e) => {
                        const stateId = e.target.value;
                        formik.setFieldValue("State", stateId);
                        setStateId(stateId);
                        fetchCities(stateId);
                      }}
                      disabled={!countryid}
                    >
                      <option value="" label="Select State" />
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.State && <div>{formik.errors.State}</div>}
                    </div>

                    <div className="">
                    <label>City</label>
                    <select className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      name="City"
                      value={formik.values.City || ""}
                      onChange={(e) =>
                        formik.setFieldValue("City", e.target.value)
                      }
                      disabled={!stateid}
                    >
                      <option value="" label="Select City" />
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.City && <div>{formik.errors.City}</div>}
                    </div> 
                      </div>

                      <p className="text-center text-xl py-1.5 bg-themebgcolor3 text-white dark:text-white rounded-lg mt-6">
                      Modeling Experience
                      </p>
                      <p className="text-md py-1.5 mt-5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                        Choose your category
                      </p>
                      {CategoryData && CategoryData.length > 0 && (
                        <div className="grid grid-cols-1 md:grid-cols-4">
                          {CategoryData[1]?.ChildCat?.map((val, index) => (
                            <div className="flex items-center my-2" key={index}>
                              <input
                                type="checkbox"
                                className="mr-2"
                                name="Category"
                                value={val.Id}
                                checked={formik.values.Category.includes(val.Id)}
                                id={val.Title}
                                onChange={handleCheckboxChange}
                              />
                              <label className="text-sm text-gray-500 font-normal" htmlFor={val.Title}>
                                {val.Title}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mt-6">
                        <div className="my-1">
                          <label className="text-base" for="">
                          Year of Experience  
                          </label>
                          <input
                            type="text"
                            name="YearsofExperience"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.YearsofExperience}
                            placeholder="Year of Experience"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          /> 
                        </div>
                        <div className="my-1">
                          <label className="text-base" for="">
                          Previous Agencies 
                          </label>
                          <input
                            type="text"
                            name="PreviousAgencies"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.PreviousAgencies}
                            placeholder="PreviousAgencies"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          /> 
                        </div>
                        </div>
                    
  
                      {/* "Other" Language Input Section */}
                      {formik.values.Language && formik.values.Language.includes("Other") && ( // Ensure Language is defined
                        <div className="my-2">
                          <label className="text-base">Specify Other Language</label>
                          <input
                            type="text"
                            name="OtherLanguage"
                            value={formik.values.OtherLanguage || ""} // Ensure value is not undefined
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please specify"
                            className="px-4 py-2 w-full focus:outline-none my-1.5 border dark:bg-[#020617]"
                          />
                          {formik.touched.OtherLanguage && formik.errors.OtherLanguage ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.OtherLanguage}
                            </div>
                          ) : null}
                        </div>
                      )}
                      {/* Language Error Message */}
                      {formik.touched.Language && formik.errors.Language && (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Language}
                        </div>
                      )}
                      <p className="text-md py-1.5 mt-5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                      Physical Attributes
                      </p>
                      
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Height */}
                    <div className="my-1">
                      <label className="text-base">
                        Height <span className="text-xs text-gray-500">(Feet)</span>
                      </label>
                      <select
                        name="Height"
                        value={formik.values.Height}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      >
                        <option value="">Select Height in Feet</option>
                        {Array.from({ length: 5 }, (_, feet) => 3 + feet).map(feet =>
                          Array.from({ length: 12 }, (_, inches) => (
                            <option key={`${feet}-${inches}`} value={`${feet}'${inches}"`}>
                              {`${feet}'${inches}"`}
                            </option>
                          ))
                        )}
                      </select>
                      {formik.touched.Height && formik.errors.Height && (
                        <div className="text-red-500 text-sm">{formik.errors.Height}</div>
                      )}
                    </div>

                    {/* Weight */}
                    <div className="my-1">
                      <label className="text-base">
                        Weight <span className="text-xs text-gray-500">(KG)</span>
                      </label>
                      <input
                        type="text"
                        name="Weight"
                        value={formik.values.Weight}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Weight"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Weight && formik.errors.Weight && (
                        <div className="text-red-500 text-sm">{formik.errors.Weight}</div>
                      )}
                    </div>

                    {/* Bust */}
                    <div className="my-1">
                      <label className="text-base">
                        Bust/Chest <span className="text-xs text-gray-500">(Inch)</span>
                      </label>
                      <input
                        type="text"
                        name="Bust"
                        value={formik.values.Bust}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Bust"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Bust && formik.errors.Bust && (
                        <div className="text-red-500 text-sm">{formik.errors.Bust}</div>
                      )}
                    </div>

                    {/* Waist */}
                    <div className="my-1">
                      <label className="text-base">
                        Waist <span className="text-xs text-gray-500">(Inch)</span>
                      </label>
                      <input
                        type="text"
                        name="Waist"
                        value={formik.values.Waist}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Waist"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Waist && formik.errors.Waist && (
                        <div className="text-red-500 text-sm">{formik.errors.Waist}</div>
                      )}
                    </div>

                    {/* Hips */}
                    <div className="my-1">
                      <label className="text-base">
                        Hips <span className="text-xs text-gray-500">(Inch)</span>
                      </label>
                      <input
                        type="text"
                        name="Hips"
                        value={formik.values.Hips}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Hips"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Hips && formik.errors.Hips && (
                        <div className="text-red-500 text-sm">{formik.errors.Hips}</div>
                      )}
                    </div>
                  </div>

                      <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg mt-4">
                      Collaboration Preferences
                      </p>
                      
                      <div className="grid grid-cols-1 md:grid-cols-4">
                    {CollbrationData[1]?.ChildCat?.map((val) => (
                      <div className="flex items-center my-1" key={val.Id}>
                        <input
                          type="checkbox"
                          className="mr-2"
                          name="CollaborationsType"
                          value={val.Id}
                          checked={formik.values.CollaborationsType.includes(
                            val.Id.toString()
                          )}
                          id={`collab-${val.Id}`}
                          onChange={handleLanguageCheckboxChange}
                        />
                        <label
                          className="text-sm text-gray-500 font-normal"
                          htmlFor={`collab-${val.Id}`}
                        >
                          {val.Title}
                        </label>
                      </div>
                    ))}
                    <div className="flex items-center my-1">
                      <input
                        type="checkbox"
                        className="mr-2"
                        name="CollaborationsType"
                        value="Other"
                        id="collab-other"
                        checked={formik.values.CollaborationsType.includes(
                          "Other"
                        )}
                        onChange={handleLanguageCheckboxChange}
                      />
                      <label
                        className="text-sm text-gray-500 font-normal"
                        htmlFor="collab-other"
                      >
                        Other
                      </label>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
                  <div className="my-1">
                    <label className="text-base">
                      Availability for Work{" "}
                     
                    </label>

                    <select
                      name="AvailabilityforWork"
                      value={formik.values.AvailabilityforWork}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    >
                      <option className="text-base">
                        Select Availability for Work
                      </option>
                      <option value="Full-TIme">Full-TIme</option>
                      <option value="Part-time">Part-time</option>
                      <option value="Freelance">Freelance</option>
                    </select>

                    {formik.touched.AvailabilityforWork &&
                    formik.errors.AvailabilityforWork ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.AvailabilityforWork}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label for="">
                      Preferred Brands/Industries
                      {/*  */ }
                    
                    </label>
                    <input
                      type="text"
                      name="CollaborationIndustries"
                      value={formik.values.CollaborationIndustries}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Preferred Brands/Industries"
                      className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />

                    <div className="flex items-center">
                      {formik.touched.CollaborationIndustries &&
                      formik.errors.CollaborationIndustries ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.CollaborationIndustries}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Available for Travel{" "}
                     
                    </label>
                    <div className="flex items-center mt-1.5 px-2 py-2">
                      <div className="mr-3">
                        <input
                          type="radio"
                          id="Yes"
                          name="AvailableforTravel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="Yes"
                          className="mr-2"
                          checked={formik.values.AvailableforTravel === "Yes"}
                        />
                        <label htmlFor="Yes">Yes</label>
                      </div>
                      <div className="">
                        <input
                          type="radio"
                          id="No"
                          name="AvailableforTravel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="No"
                          className="mr-3"
                          checked={formik.values.AvailableforTravel === "No"}
                        />
                        <label htmlFor="No">No</label>
                      </div>
                    </div>

                    {formik.touched.AvailableforTravel &&
                    formik.errors.AvailableforTravel ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.AvailableforTravel}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label for="">
                      Website/Blog URL   {/*<span className="text-red-500">*</span> */}
                    </label>
                    <input
                      type="text"
                      name="Website"
                      value={formik.values.Website}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Website/Blog URL"
                      className="px-4 py-4 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />

                    <div className="flex items-center">
                      {formik.touched.Website && formik.errors.Website ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Website}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>


                      <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg mt-4">
                        Social Media Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label for="">
                            Instagram 
                          </label>
                          <input
                            type="text"
                            name="Instagram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Instagram}
                            placeholder="Instagram"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Instagram &&
                          formik.errors.Instagram ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Instagram}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Facebook 
                          </label>
                          <input
                            type="text"
                            name="Facebook"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Facebook}
                            placeholder="Facebook"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Facebook && formik.errors.Facebook ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Facebook}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Youtube 
                          </label>
                          <input
                            type="text"
                            name="YouTube"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.YouTube}
                            placeholder="YouTube"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.YouTube && formik.errors.YouTube ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.YouTube}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Twitter 
                          </label>
                          <input
                            type="text"
                            name="Twitter"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Twitter}
                            placeholder="Twitter"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Twitter && formik.errors.Twitter ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Twitter}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">LinkedIn</label>
                          <input
                            type="text"
                            name="LinkedIn"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.LinkedIn}
                            placeholder="LinkedIn"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.LinkedIn && formik.errors.LinkedIn ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.LinkedIn}
                            </div>
                          ) : null}
                        </div>
                       
                        <div className="my-1">
                          <label for="">Other</label>
                          <input
                            type="text"
                            name="Other"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Other}
                            placeholder="Other"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.Other && formik.errors.Other ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Other}
                            </div>
                          ) : null}
                        </div>
                       
                      
                      </div>
                      <div className="flex justify-center my-3">
                        <button
                          type="submit"
                          className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-10"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelProfile