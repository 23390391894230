import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin, FaTwitter, FaSignLanguage } from "react-icons/fa";
import NoDataFound from "../../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../../usepageSEO/Index";
import Breadcrumb from "../../../Breadcumb/Breadcumb";
import { getArtistByCity } from "../../../API/NewInfluencerAPI";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import Loader from "../../../common/Loader";
   
const CItyArtist = () => {
  const { Slug, City } = useParams();
  const location = useLocation();
  const id = location.state?.id; // Get Id from state

  // console.log("Id from state:", id); // For debugging purposes, log the id

  const breadcrumbItems = [
    {
      text: `${Slug || City}`, // Default to Slug or City if available
    },
  ];

  const [ModelData, setModelData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getData = async () => {
    try {
      let results;
      if (id) {
        results = await getArtistByCity(id); // Assuming id is an actual id
      } 
      if (results) {
        //   console.log("Fetched data:", results);
        setModelData(results);
      } else {
        setModelData([]); // Ensure empty array when no data is returned
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    getData();
  }, [id, Slug, City]); // Re-fetch when any of these params change
  
  usePageSEO(
    `Explore top influencers from ${Slug || City} city. Check their follower count, engagement, and bio details.`,
    `Explore top influencers from ${Slug || City} city. Check their follower count, engagement, and bio details.`,
    [
      `Explore top influencers from ${Slug || City} city. Check their follower count, engagement, and bio details.`,
    ],
  );
  
  
    if (loading) {
      return <Loader />; // Show loader while loading data
    }
  return (
    <div>
         <div className="dark:bg-darkmode dark:text-white">
        <Breadcrumb items={breadcrumbItems} />
        <div className="mb-10">
          <div className="subscribe-bg relative bg-cover bg-right bg-no-repeat">
            {loading ? (
              <div className="flex justify-center items-center my-40">
                <ClipLoader color={"#f59231"} loading={loading} size={40} />
              </div>
            ) : (
              <div className="container mx-auto px-4 md:px-0 lg:py-12 py-10">
                {ModelData?.length === 0 ? (
                  <NoDataFound />
                ) : (
                  <div className="grid grid-cols-1 gap-10">
                    <div className="col-span-3">
                      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 container mx-auto">
                        {ModelData?.map((val, index) => (
                           <div
                           key={index}
                           className="px-2 py-4 border mb-4 shadow-sm md:py-4 md:px-4 flex flex-col max-w-[24rem] justify-start rounded-[2.5rem] bg-gradient-to-b from-[#efefef] to-transparent"
                         >
                           <div className="h-fit overflow-hidden min-w-[18rem] min-h-[18rem] max-w-[18rem] max-h-[18rem] sm:min-w-[20rem] sm:min-h-[20rem] sm:max-w-[20rem] sm:max-h-[20rem] md:min-w-[22rem] md:min-h-[22rem] md:max-w-[22rem] md:max-h-[22rem] lg:min-w-[18rem] lg:min-h-[18rem] lg:max-w-[18rem] lg:max-h-[18rem] xl:min-w-[22rem] xl:min-h-[22rem] xl:max-w-[22rem] xl:max-h-[22rem] rounded-t-full rounded-br-full mx-auto">
                             <Link to={`/artist/${val.ArtistName}/${val.CitySlug}`}>
                               <img
                                 src={val.Path + val.ProfileImage}
                                 alt=""
                                 className="min-w-[18rem] min-h-[18rem] max-w-[18rem] max-h-[18rem] sm:min-w-[20rem] sm:min-h-[20rem] sm:max-w-[20rem] sm:max-h-[20rem] md:min-w-[22rem] md:min-h-[22rem] md:max-w-[22rem] lg:min-w-[18rem] fold:max-h-[16rem] lg:min-h-[18rem] lg:max-w-[18rem] lg:max-h-[18rem] xl:min-w-[22rem] xl:min-h-[22rem] xl:max-w-[22rem] xl:max-h-[22rem] rounded-t-full rounded-br-full object-cover flex justify-center text-center items-center transition-all duration-300 cursor-pointer scale-100 hover:scale-110 object-top fold:max-w-[16rem] fold:min-w-[16rem] fold:min-h-[10rem] surface:max-w-[17rem] surface:min-w-[17rem] surface:max-h-[20rem] surface:min-h-[20rem]"
                               />
                             </Link>
                           </div>
                           <div className="font-semibold leading-[38.73px] text-xl dark:text-themecolor mt-4 mb-2 line-clamp-1">
                            <Link to={`/artist/${val.ArtistName}/${val.CitySlug}`}>
                               {" "}
                               {val.Name}
                             </Link>
                           </div>
         
                           <div className="flex items-center mb-3">
                             <FaSignLanguage className="text-sm text-themecolor" />
                             <label className="ml-2 text-sm leading-4 font-normal text-themebgcolor3 dark:text-white line-clamp-2">
                               English,Hindi,Marathi
                             </label>
                           </div>
                           <div className="md:flex items-center text-textcolor dark:text-white">
                             <p className="flex items-center">
                               <FaLocationDot className="text-green-500 text-sm" />
                               <span className="px-1.5 text-sm dark:text-white">
                                 {val.CityName}
                               </span>
                             </p>
                           </div>
         
                           {(val.Instagram ||
                             val.Facebook ||
                             val.Twitter ||
                             val.YouTube ||
                             val.LinkedIn) && (
                             <div>
                               <p className="flex items-center text-sm dark:text-white my-2 md:mb-3 text-gray-500">
                                 {val.Name}'s Social
                               </p>
         
                               <div className="text-white flex items-center mb-5">
                                 {val.Instagram && (
                                   <Link
                                     to={"https://www.instagram.com/viralkar.official/"}
                                     target="_blank"
                                     className="bg-instagram text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                   >
                                     <FaInstagram className="text-lg" />
                                   </Link>
                                 )}
                                 {val.Facebook && (
                                   <Link
                                     to={"https://www.facebook.com/viralkarr/"}
                                     target="_blank"
                                     className="bg-[#0165E1] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                   >
                                     <FaFacebook className="text-lg" />
                                   </Link>
                                 )}
                                 {val.Twitter && (
                                   <Link
                                     to={"https://x.com/viral_kar_"}
                                     target="_blank"
                                     className="bg-black text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                   >
                                     <FaXTwitter className="text-lg" />
                                   </Link>
                                 )}
                                 {val.YouTube && (
                                   <Link
                                     to={"https://www.youtube.com/@ViralKarr"}
                                     target="_blank"
                                     className="bg-red-600 text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                   >
                                     <FaYoutube className="text-lg" />
                                   </Link>
                                 )}
                                 {val.LinkedIn && (
                                   <Link
                                     to={"https://www.linkedin.com/company/viralkar"}
                                     target="_blank"
                                     className="bg-[#0077b5] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                   >
                                     <FaLinkedin className="text-lg" />
                                   </Link>
                                 )}
                               </div>
                             </div>
                           )}
         
                          <Link to={`/artist/${val.ArtistName}/${val.CitySlug}`}>
                             <button
                               className="bg-themebgcolor3 hover:bg-themecolor1 dark:bg-themecolor1 text-sm mt-5 mx-auto block py-2 px-5 text-white rounded-full"
                               type="button"
                             >
                               Send Message to {val.Name}
                             </button>
                           </Link>
                         </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CItyArtist
