import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

// ------------------------------Campaign Get by Id ----------------------
// https://www.model.easyshadi.com/front/api/user/campaign/1
export const CampaignGetbyId = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/campaign/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// ------------------------------Campaign Get by Id ----------------------
// https://www.model.easyshadi.com/front/api/user/campaigns/1
export const CampaignbyId = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/campaigns/${Id}`);
    if (response.data.status === true) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// ------------------------------Campaign Add ----------------------
// https://www.model.easyshadi.com/front/api/campaign/add
export const CampaignAdd = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/campaign/add`, formData);
    if (response.data.status === true) {
      toast.success("Campaign created successfully");
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// ------------------------------CampaignUpdate ----------------------
// https://www.model.easyshadi.com/front/api/campaign/update/:Id
export const CampaignUpdate = async (Id, formData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/campaign/update/${Id}`,
      formData,
    );
    if (response.data.status === true) {
      toast.success("Campaign Edit successfully");
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// ------------------------------Campaign Get by Id ----------------------
// https://www.model.easyshadi.com/front/api/campaign/delete/3
export const CampaignDeletebyId = async (Id) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/campaign/delete/${Id}`,
    );
    if (response.data.status === true) {
      toast.success("Campaign created successfully");
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------getAppliedInfluencerforCampaign-----------------------
// https://www.model.easyshadi.com/front/api/campaign/model-influ/applied/9
export const getAppliedInfluencerforCampaign = async (Id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/campaign/model-influ/applied/${Id}`,
    );
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
