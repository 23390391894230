

import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

export const ArtistStatusCheck = async (data, navigate) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/artist-status-1`, data);

    const responseData = response.data;

    if (responseData.responseData && response.data.status === true) {
      const { Id, Email, ArtistName, Phone, Step1, Step2, Step3 } =
        responseData.responseData;

      sessionStorage.setItem("ArtistIdForTemp", JSON.stringify(Id));
      sessionStorage.setItem("ArtistEmailForTemp", JSON.stringify(Email));
      sessionStorage.setItem(
        "ArtistUserNameForTemp",
        JSON.stringify(ArtistName),
      );
      sessionStorage.setItem("ArtistPhoneForTemp", JSON.stringify(Phone));
      sessionStorage.setItem("ArtistStep1ForTemp", JSON.stringify(Step1));
      sessionStorage.setItem("ArtistStep2ForTemp", JSON.stringify(Step2));
      sessionStorage.setItem("ArtistStep3ForTemp", JSON.stringify(Step3));

      if (Step1 === 0 && Step2 === 0 && Step3 === 0) {
        navigate("/artist/sign-up/artist");
        return responseData;
      } else if (Step1 === 1 && Step2 === 0 && Step3 === 0) {
        navigate("/artist/sign-up/artist2");
        return responseData;
      } else if (Step1 === 1 && Step2 === 1 && Step3 === 0) {
        navigate("/artist/sign-up/artist3");
        return responseData;
      } else {
        toast.success(
          "Success! Your email/phone is verified. Let’s continue crafting your artistic journey",
        );
        navigate("/artist-password");
        return responseData;
      }
    }

    if (responseData.InsertId && response.data.status === true) {
      const InsertId = responseData.InsertId;

      if (InsertId) {
        sessionStorage.setItem("ArtistIdForTemp", JSON.stringify(InsertId));
        navigate("/artist/sign-up/artist");
        toast.success(
          "Registration Successful! Complete your profile to showcase your talent as an artist",
        );
      }
    }
  } catch (error) {
    navigate("/artist/sign-up/artist");
    throw error;
  }
};

export const GoogleLoginAtristAPI = async (googleData, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/artist-google-login`,
      googleData,
    );

    const responseData = response.data;

    if (response.data.status === true) {
      sessionStorage.setItem(
        "Id",
        JSON.stringify(responseData.responseData.ArtistId),
      );
      sessionStorage.setItem(
        "Type",
        JSON.stringify(responseData.responseData.Type),
      );
      sessionStorage.setItem(
        "Status",
        JSON.stringify(responseData.responseData.Status),
      );
      sessionStorage.setItem("Token", JSON.stringify(responseData.token));

      sessionStorage.setItem("ArtistIdForTemp");
      sessionStorage.setItem("ArtistEmailForTemp");
      sessionStorage.setItem("ArtistUserNameForTemp");
      sessionStorage.setItem("ArtistPhoneForTemp");
      sessionStorage.setItem("ArtistStep1ForTemp");
      sessionStorage.setItem("ArtistStep2ForTemp");
      sessionStorage.setItem("ArtistStep3ForTemp");

      toast.success(
        "Welcome back, artist! You’re logged in and ready to shine!",
      );

      if (navigate) {
        navigate("/profile/artist");
      }
    } else {
      toast.error("Invalid Password");
    }

    return responseData;
  } catch (error) {
    toast.error("There was an issue logging in. Please try again.");
    throw error;
  }
};

export const FacebookLoginAtristAPI = async (facebookData, navigate) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/artist-facebook-login`,
      facebookData,
    );

    const responseData = response.data;

    if (response.data.status === true) {
      sessionStorage.setItem(
        "Id",
        JSON.stringify(responseData.responseData.ArtistId),
      );
      sessionStorage.setItem(
        "Type",
        JSON.stringify(responseData.responseData.Type),
      );
      sessionStorage.setItem(
        "Status",
        JSON.stringify(responseData.responseData.Status),
      );
      sessionStorage.setItem(
        "Token",
        JSON.stringify(responseData.token)
      );
      
      sessionStorage.removeItem("ArtistIdForTemp");
      sessionStorage.removeItem("ArtistEmailForTemp");
      sessionStorage.removeItem("ArtistUserNameForTemp");
      sessionStorage.removeItem("ArtistPhoneForTemp");
      sessionStorage.removeItem("ArtistStep1ForTemp");
      sessionStorage.removeItem("ArtistStep2ForTemp");
      sessionStorage.removeItem("ArtistStep3ForTemp");

      toast.success(
        "Welcome back, artist! You’re logged in and ready to shine!",
      );

      if (navigate) {
        navigate("/profile/artist");
      }
    } else {
      toast.error("Invalid Password");
    }

    return responseData;
  } catch (error) {
    toast.error("There was an issue logging in. Please try again.");
    throw error;
  }
};

export const ArtistPassword = async (data, navigate) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/artist-verify`, data);

    const responseData = response.data;

    if (response.data.status === true) {
      sessionStorage.setItem(
        "Id",
        JSON.stringify(responseData.responseData.ArtistId),
      );
      sessionStorage.setItem(
        "UserName",
        JSON.stringify(responseData.responseData.ArtistName),
      );
      sessionStorage.setItem(
        "Type",
        JSON.stringify(responseData.responseData.Type),
      );
      sessionStorage.setItem(
        "Status",
        JSON.stringify(responseData.responseData.Status),
      );
      sessionStorage.setItem("Token", JSON.stringify(responseData.Token));

      //   -----------------------------remove----------------------------
      sessionStorage.removeItem("ArtistIdForTemp");
      sessionStorage.removeItem("ArtistEmailForTemp");
      sessionStorage.removeItem("ArtistUserNameForTemp");
      sessionStorage.removeItem("ArtistPhoneForTemp");
      sessionStorage.removeItem("ArtistStep1ForTemp");
      sessionStorage.removeItem("ArtistStep2ForTemp");
      sessionStorage.removeItem("ArtistStep3ForTemp");

      toast.success(
        "Welcome back, Artist! You’re logged in and ready to hit the runway!",
      );
    } else {
      toast.error("Invalid Password and try again.");
    }

    return responseData;
  } catch (error) {
    toast.error("An error occurred while logging in. Please try again later.");
    throw error;
  }
};

export const ArtistGetById = async (Id) => {
  try {
    const responce = await axios.get(`${API_BASE_URL}/artist/${Id}`);

    if (responce.data.status === true) {
      return responce.data.responseData[0];
    } else {
      throw new Error(responce.data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const ArtistStep1Add = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/artist-step-1`, data);

    if (responce.data.status === true) {
      toast.success("Step 1 Complete! Your creative journey begins now!");
      return responce.data;
    } else {
      toast.error(
        "Oops! Something went wrong while starting your artist profile. Please check your details and try again.",
      );
    }
  } catch (error) {
    throw error;
  }
};

export const ArtistStep2Add = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/artist-step-2`, data);

    if (responce.data.status === true) {
      toast.success(
        "Step 2 Done! Your artistic profile is almost complete-just one more step!",
      );
      return responce.data;
    } else {
      toast.error(
        "We hit a bump! Try submitting your profile details again, and we’ll get things back on track.",
      );
    }
  } catch (error) {
    throw error;
  }
};

export const ArtistStep3Add = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/dev-artist-step-3`,
      data,
    );

    if (responce.data.status === true) {
      sessionStorage.setItem("Id", JSON.stringify(responce.data.Id));
      sessionStorage.setItem("Type", JSON.stringify(responce.data.Type));
      sessionStorage.setItem(
        "UserName",
        JSON.stringify(responce.data.ArtistName),
      );
      sessionStorage.setItem("Status", JSON.stringify(responce.data.Status));
      sessionStorage.setItem("Token", JSON.stringify(responce.data.Token));

      //   -----------------------------remove----------------------------
      sessionStorage.removeItem("InfluencerIdForTemp");
      sessionStorage.removeItem("InfluencerEmailForTemp");
      sessionStorage.removeItem("InfluencerUserNameForTemp");
      sessionStorage.removeItem("InfluencerPhoneForTemp");
      sessionStorage.removeItem("InfluencerStep1ForTemp");
      sessionStorage.removeItem("InfluencerStep2ForTemp");
      sessionStorage.removeItem("InfluencerStep3ForTemp");
      toast.success(
        "Congratulations! Your artist profile is live-time to showcase your talent!",
      );
      return responce.data;
    } else {
      toast.error(
        "Technical hiccup! No worries, retry now and get your artistic profile back on the canvas.",
      );
    }
  } catch (error) {
    throw error;
  }
};

export const UpdateArtistInfById = async (Id, formdata) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/vendor/profile-update/${Id}`,
      formdata,
    );
    if (response.data.status === true) {
      toast.success(response.data.message);
      return response.data.responseData;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const checkUsernameExistsArtist = async (username) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/artist/username/check-username?username=${username}`,
    );
    return await response.json();
  } catch (error) {
    console.error("Error checking username:", error);
    return null;
  }
};

export const getArtistBySubCategoryId = async (CategoryId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/artist/category/${CategoryId}`,
    );
    console.log(response.data);

    if (response.data.status) {
      return Array.isArray(response.data.responseData)
        ? response.data.responseData
        : [response.data.responseData];
    } else {
      throw new Error("Data retrieval failed");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

//=================== Artist Filter Api ==========

export const FilterArtistAPI = async (CityId, CategoryId, Gender) => {
  try {
    const url = `${API_BASE_URL}/filter-artist?CityId=${CityId}&CategoryId=${CategoryId}&Gender=${Gender}`;
    const response = await axios.get(url);

    if (response.data.status === true && response.data.responseData?.length > 0) {
      return response.data.responseData; // Return data
    } else {
      return []; // Return empty array if no data
    }
  } catch (error) {
    console.error("Error fetching influencers:", error);
    return []; // Return empty array on error
  }
}
