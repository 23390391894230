/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ContactusImg from "../../../../Images/contact-back.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import ClipLoader from "react-spinners/FadeLoader";
import { format, parseISO } from "date-fns";
import Config from "../../../../API/Config";
import { getAppliedInfluencerforCampaign } from "../../../../API/CampaignApi";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import usePageSEO from "../../../usepageSEO/Index";
import Loader from "../../../../common/Loader";

const InfluencerApplied = () => {
  const backgroundImage = ContactusImg;
  const breadcrumbStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center",
    zIndex: 1,
    padding: "140px 0 80px 0",
  };

  usePageSEO(
    "Viral kar | Influencer Applied", // Use page title for SEO if available
    "Viral kar | Influencer Applied", // Use page description for SEO if available
    ["Viral kar | Influencer Applied"] // No keywords provided in this example
  );

  const [CampaingnData, setCampaingnData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const { Id } = useParams();
  const VendorId = Config.getId();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const FatchData = async () => {
    try {
      const result = await getAppliedInfluencerforCampaign(Id);
      setCampaingnData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    FatchData();
  }, [Id]);
  
  const [isFormLoading, setIsFormLoading] = useState(false);
  // -------------------delete venue------------------
  const navigate = useNavigate();
  
  const backClick = () => {
    navigate("/campaigns");
  };
  // -----------------------------pagination------------------------------
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = CampaingnData?.slice(indexOfFirstItem, indexOfLastItem);
  
  const totalPages = Math.ceil(CampaingnData?.length / itemsPerPage);
  
  const handlePrevious = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);
  };
  
  const handleNext = () => {
    setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage);
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);
  
  if (loading) {
    return <Loader />; // Show loader while loading data
  }
  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <div className="breadcrumb-bg" style={breadcrumbStyle}>
          <div className="breadcrumb-before"></div>
          <ul className="text-white text-3xl text-center">Applied Campaign</ul>
        </div>
      </nav>
      <div className="pb-24">
        <ScrollToTop />
        <div className="w-full container mx-auto">
          <div className="w-full  lg:grid grid-cols-5 py-20 px-3 gap-3 ">
            {/* ===============================Nav Pilss data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-5">
              <div className="bg-dark-light">
                <div className="grid grid-cols-1 gap-5">
                  <div className="bg-dark-light p-6">
                    <div className=" text-xl py-1.5 mb-3 bg-themecolor flex items-center">
                      <span
                        onClick={backClick}
                        className="bg-white text-black p-2 ml-1.5 cursor-pointer"
                      >
                        <FaArrowLeft />
                      </span>

                      <p className="text-center mx-auto text-base md:text-xl">
                        Applied Campaign
                      </p>
                    </div>
                    {loading ? (
                      <div className="flex justify-center items-center my-40">
                        <ClipLoader
                          color={"#d00067"}
                          loading={loading}
                          size={40}
                        />
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                          {currentItems.map((val, index) => {
                            return (
                              <div key={index} className="mb-0  surface:px-2">
                                <div className="bg-white p-5 shadow-lg rounded">
                                  <div>
                                    <div className="flex items-center">
                                      <Link to={`/influencer/${val.VendorId}`}>
                                        <p className="text-2xl text-themecolor my-2">
                                          {val.VendorName}
                                        </p>
                                      </Link>
                                    </div>
                                    <div className="text-textcolor my-2">
                                      <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                                        <p className="border border-themecolor p-2">
                                          <span className="text-themecolor">
                                            Name :{" "}
                                          </span>
                                          {val.Name}
                                        </p>

                                        <p className="border border-themecolor p-2">
                                          <span className="text-themecolor">
                                            Email :{" "}
                                          </span>
                                          {val.Email}
                                        </p>

                                        <p className="border border-themecolor p-2">
                                          {" "}
                                          <span className="text-themecolor">
                                            Phone :{" "}
                                          </span>
                                          {val.Phone}
                                        </p>

                                        <p className="border border-themecolor p-2">
                                          {" "}
                                          <span className="text-themecolor">
                                            Applied Date :{" "}
                                          </span>
                                          {format(
                                            parseISO(val.EntDt),
                                            "dd-MM-yyyy"
                                          )}
                                        </p>
                                      </div>
                                      <p className="border border-themecolor p-2 my-2 text-justify">
                                        <span className="text-themecolor">
                                          Description :{" "}
                                        </span>
                                        {val.Message}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    )}
                    {CampaingnData?.length > 8 ? (
                      <div className="flex justify-between mt-4">
                        <button
                          onClick={handlePrevious}
                          disabled={currentPage === 1}
                          className={`py-2 px-4 rounded ${
                            currentPage === 1
                              ? "bg-gray-300 dark:bg-white text-black"
                              : "bg-themecolor1 text-white"
                          }`}
                        >
                          Previous
                        </button>
                        <span className="py-2 px-4">{`Page ${currentPage} of ${totalPages}`}</span>
                        <button
                          onClick={handleNext}
                          disabled={currentPage === totalPages}
                          className={`py-2 px-4 rounded ${
                            currentPage === totalPages
                              ? "bg-gray-300 dark:bg-white text-black"
                              : "bg-themecolor1 text-white"
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerApplied;
