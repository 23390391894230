import React, { useEffect, useState } from "react";
import ContactusImg from "../../Images/contact-back.jpg";
import titleimage from "../../Images/title-icon.png";
import ScrollToTop from "./ScrollToTop";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import { useParams } from "react-router";
import { GetPageBySlug } from "../../API/PagesApi";
import usePageSEO from "../usepageSEO/Index";
import Loader from "../../common/Loader";
const Pages = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const { Slug } = useParams();
  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const responce = await GetPageBySlug(Slug);
        setPageData(responce[0]);
      } catch (error) {
        console.error(error);
      }finally {
        setLoading(false);
      }
    };
    fetchPageData();
  }, [Slug]);

  const title = pageData?.Title;
  const Image = pageData?.Image;
  const breadcrumbItems = [
    {
      text: title,
    },
  ];

  usePageSEO(
    pageData ? pageData.SeoTitle : "Viral kar | Pages", // Use page title for SEO if available
    pageData ? pageData.SeoDescription : "Viral kar | Pages", // Use page description for SEO if available
    [pageData ? pageData.SeoKeyword : "Viral kar | Pages"] // No keywords provided in this example
  );
  const backgroundImage = ContactusImg;

  if (loading) {
    return <Loader />; // Show loader while loading data
  }

  return (
    <div>
      <div className=" ">
        {" "}
        <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
        <ScrollToTop />
        <div className="py-20 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center">{title}</p>

            <img src={titleimage} className="text-center title-icon" alt="" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 my-10">
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: pageData ? pageData.Content : "",
                }}
                className="text-justify"
              />
            </div>
            <div className="my-auto">
              <img src={pageData?.Image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pages;
