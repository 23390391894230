import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
 import NoDataFound from "../../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../../usepageSEO/Index";
import Breadcrumb from "../../../Breadcumb/Breadcumb";
import { getModelByCity } from "../../../API/NewInfluencerAPI";
import { FaLocationDot } from "react-icons/fa6";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
   FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Loader from "../../../common/Loader";


const CityModel = () => {
  const { Slug, City } = useParams();
  const location = useLocation();
  const id = location.state?.id; // Get Id from state

  // console.log("Id from state:", id); // For debugging purposes, log the id

  const breadcrumbItems = [
    {
      text: `${Slug || City}`, // Default to Slug or City if available
    },
  ];

  const [ModelData, setModelData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getData = async () => {
    try {
      let results;
      if (id) {
        results = await getModelByCity(id); // Assuming id is an actual id
      } 
      if (results) {
        //   console.log("Fetched data:", results);
        setModelData(results);
      } else {
        setModelData([]); // Ensure empty array when no data is returned
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    getData();
  }, [id, Slug, City]); // Re-fetch when any of these params change
  
  usePageSEO(
    `Explore top influencers from ${Slug || City} city. Check their follower count, engagement, and bio details.`,
    `Explore top influencers from ${Slug || City} city. Check their follower count, engagement, and bio details.`,
    [
      `Explore top influencers from ${Slug || City} city. Check their follower count, engagement, and bio details.`,
    ],
  );
  if (loading) {
    return <Loader />; // Show loader while loading data
  }

  return (
    <div>
      <div className="dark:bg-darkmode dark:text-white">
        <Breadcrumb items={breadcrumbItems} />
        <div className="mb-10">
          <div className="subscribe-bg relative bg-cover bg-right bg-no-repeat">
            {loading ? (
              <div className="flex justify-center items-center my-40">
                <ClipLoader color={"#f59231"} loading={loading} size={40} />
              </div>
            ) : (
              <div className="container mx-auto px-4 md:px-0 lg:py-12 py-10">
                {ModelData?.length === 0 ? (
                  <NoDataFound />
                ) : (
                  <div className="grid grid-cols-1 gap-10">
                    <div className="col-span-3">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 container mx-auto">
                        {ModelData?.map((val, index) => (
                          <div key={index} className="mb-5 px-3">
                          <div className="bg-white p-5 rounded-lg dark:border-gray-500 shadow-[0px_0px_12px_0px_rgba(0,0,0,0.3)] dark:bg-slate-900 border dark:text-white">
                            <div className="grid grid-cols-1 md:grid-cols-5 border-b border-gray-200">
                              <div className="col-span-2">
                                <Link to={`/model/${val.UserName}/${val.CitySlug}`}>
                                  <div className=" md:p-5 md:pl-0 p-0">
                                    <div className="border rounded-md">
                                      <img
                                        src={val.Path + val.ProfileImage}
                                        alt=""
                                        className="md:h-60 w-full p-2 border-themecolor h-72 surface:h-60 object-cover object-top"
                                      />
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              <div className="col-span-3 md:py-5 py-3">
                                <div>
                                  <div className="flex items-center">
                                    <Link
                                      to={`/model/${val.CityName}/${val.UserName}`}
                                    >
                                      <p className="text-md font-semibold text-black dark:text-white my-2">
                                        {val.Name}
                                      </p>
                                    </Link>
                                    <span className="text-themecolor ml-2 text-sm font-normal">
                                      {val.Gender === "F"
                                        ? "(Female)"
                                        : val.Gender === "M"
                                          ? "(Male)"
                                          : val.Gender === "K"
                                            ? "(Kids)"
                                            : ""}
                                    </span>
                                  </div>
                                  <div className="md:flex items-center text-textcolor dark:text-white mb-2">
                                    <p className="flex items-center">
                                      <FaLocationDot className="text-green-500 text-sm" />
                                      <span className="px-1.5 text-sm">
                                        {val.CityName}
                                      </span>
                                    </p>
                                  </div>
      
                                  <p className="flex flex-wrap">
                                    <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                      <span className="text-gray-600 dark:text-gray-300 text-sm">
                                        Height :
                                      </span>{" "}
                                      <span className="text-themecolor font-semibold">
                                        {val.Height}
                                      </span>
                                    </p>
                                    <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                      <span className="text-gray-600 dark:text-gray-300 text-sm">
                                        Weight :
                                      </span>{" "}
                                      <span className="text-themecolor font-semibold">
                                        {val.Weight}
                                      </span>
                                    </p>
      
                                    <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                      <span className="text-gray-600 dark:text-gray-300 text-sm">
                                        Bust :
                                      </span>{" "}
                                      <span className="text-themecolor font-semibold">
                                        {val.Bust}
                                      </span>
                                    </p>
                                    <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                      <span className="text-gray-600 dark:text-gray-300 text-sm">
                                        Waist :
                                      </span>{" "}
                                      <span className="text-themecolor font-semibold">
                                        {val.Waist}
                                      </span>
                                    </p>
                                    <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                      <span className="text-gray-600 dark:text-gray-300 text-sm">
                                        Hips :
                                      </span>{" "}
                                      <span className="text-themecolor font-semibold">
                                        {val.Hips}
                                      </span>
                                    </p>
                                  </p>
      
                                  {(val.Instagram ||
                                    val.Facebook ||
                                    val.Twitter ||
                                    val.YouTube ||
                                    val.LinkedIn) && (
                                    <div>
                                      <p className="flex items-center text-sm dark:text-white my-2 md:mb-3 text-gray-500">
                                        {val.Name}'s Social
                                      </p>
      
                                      <div className="text-white flex items-center mb-5">
                                        {val.Instagram && (
                                          <Link
                                            to={
                                              "https://www.instagram.com/viralkar.official/"
                                            }
                                            target="_blank"
                                            className="bg-instagram text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                          >
                                            <FaInstagram className="text-lg" />
                                          </Link>
                                        )}
                                        {val.Facebook && (
                                          <Link
                                            to={"https://www.facebook.com/viralkarr/"}
                                            target="_blank"
                                            className="bg-[#0165E1] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                          >
                                            <FaFacebook className="text-lg" />
                                          </Link>
                                        )}
                                        {val.Twitter && (
                                          <Link
                                            to={"https://x.com/viral_kar_"}
                                            target="_blank"
                                            className="bg-black text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                          >
                                            <FaXTwitter className="text-lg" />
                                          </Link>
                                        )}
                                        {val.YouTube && (
                                          <Link
                                            to={"https://www.youtube.com/@ViralKarr"}
                                            target="_blank"
                                            className="bg-red-600 text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                          >
                                            <FaYoutube className="text-lg" />
                                          </Link>
                                        )}
                                        {val.LinkedIn && (
                                          <Link
                                            to={
                                              "https://www.linkedin.com/company/viralkar"
                                            }
                                            target="_blank"
                                            className="bg-[#0077b5] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg"
                                          >
                                            <FaLinkedin className="text-lg" />
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <Link to={`/model/${val.CityName}/${val.UserName}`}>
                              <button
                                className="bg-themebgcolor3 hover:bg-themecolor1 dark:bg-themecolor1 text-sm mt-5 mx-auto block py-2 px-5 text-white rounded-full"
                                type="button"
                              >
                                Send Message to {val.Name}
                              </button>
                            </Link>
                          </div>
                        </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityModel;
