import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ScrollToTop from "../ScrollToTop";
import ContactusImg from "../../../Images/contact-back.jpg";
import {
  getAdvertiserById,
  UpdateAdvertiserById,
  UpdateAdvertiserProfile
} from "../../../API/AddAdvertiserApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../common/FormLoader";
import Config from "../../../API/Config";
import usePageSEO from "../../usepageSEO/Index";
import axios from "axios";

const AdvProfile = () => {
  const backgroundImage = ContactusImg;
  const breadcrumbStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center",
    zIndex: 1,
    padding: "140px 0 80px 0",
  };

  usePageSEO("Viral kar | Dashboard", "Viral kar | Dashboard", [
    "Viral kar | Dashboard",
  ]);

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is required"),
    BusinessName: Yup.string().required("Business Name is required"),
    GSTNumber: Yup.string().required("GST Number is required"),
    BusinessWebsite: Yup.string().required("Business Website is required"),
    BusinessAddress: Yup.string().required("Business Address is required"),
    BusinessCity: Yup.string().required("Business City is required"),
    BusinessState: Yup.string().required("Business State is required"),
    BusinessCountry: Yup.string().required("Business Country is required"),
  });

  const [ThumbImagePreview, setThumbImagePreview] = useState();
  const Id = Config.getId();
  const AdvStatus = Config.getAdvStatus();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateid, setStateId] = useState(null);
  const [countryid, setCountryid] = useState(0);

  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      BusinessName: "",
      GSTNumber: "",
      BusinessWebsite: "",
      BusinessAddress: "",
      BusinessCity: "",
      BusinessState: "",
      BusinessCountry: "",
      ProfileImage: "",
      HidImage: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        await UpdateAdvertiserProfile(Id, formData);
        fetchData(); // Re-fetch data after updating
      } catch (error) {
        console.error("Error updating advertiser:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  const fetchData = async () => {
    try {
      const result = await getAdvertiserById(Id);
      formik.setValues({
        Name: result.Name || "",
        Email: result.Email || "",
        Phone: result.Phone || "",
        BusinessName: result.BusinessName || "",
        GSTNumber: result.GSTNumber || "",
        BusinessWebsite: result.BusinessWebsite || "",
        BusinessAddress: result.BusinessAddress || "",
        BusinessCity: result.BusinessCity || "",
        BusinessState: result.BusinessState || "",
        BusinessCountry: result.BusinessCountry || "",
        HidImage: result.HidImage || "",
      });

      if (result.ProfileImage) {
        setThumbImagePreview(result.Path + result.ProfileImage);
      }

      if (result.BusinessCountry) {
        setCountryid(result.BusinessCountry);
        formik.setFieldValue("BusinessCountry", result.BusinessCountry);
        fetchStates(result.BusinessCountry);
      }
      if (result.BusinessState) {
        setStateId(result.BusinessState);
        formik.setFieldValue("BusinessState", result.BusinessState);
        fetchCities(result.BusinessState);
      }
      if (result.BusinessCity) {
        formik.setFieldValue("BusinessCity", result.BusinessCity);
      }

      sessionStorage.setItem("getAdvStatus", result.Status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://admin.viralkar.com/front/api/all-country",
      );
      setCountries(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-state/${countryId}`,
      );
      setStates(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `https://admin.viralkar.com/front/api/all-cities/${stateId}`,
      );
      setCities(response.data.responsedata);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
    fetchCountries();
  }, []);
  sessionStorage.setItem("ProfileImage", ThumbImagePreview);

  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="">
        <ScrollToTop />
        <div className="container mx-auto">
          <div className="md:flex py-5 md:py-14 px-3 lg:gap-8">
            <div className="md:w-1/4 md:mb-0 mb-4">
              <DashboardMenu />
            </div>
            <div className="md:w-3/4">
              <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
                <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                  <form onSubmit={formik.handleSubmit}>
                    <input
                      type="hidden"
                      name="HidImage"
                      value={formik.values.HidImage}
                    />
                    <div className="grid grid-cols-1 gap-5">
                      <div className="">
                        {AdvStatus === 0 ? (
                          <div
                            class="bg-orange-100 border-t-2 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                            role="alert"
                          >
                            <div class="flex justify-center">
                              <p class="font-semibold text-sm">
                                Your Profile is Under Review
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div
                            class="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                            role="alert"
                          >
                            <div class="flex justify-center">
                              <p class="font-bold">Your Profile is Approved</p>
                            </div>
                          </div>
                        )}
                        <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                          Basic Details
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          <div className="my-1">
                            <label for="">Name</label>
                            <input
                              type="text"
                              name="Name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Name}
                              placeholder="Name"
                              className="px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Name && formik.errors.Name ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Name}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Email</label>
                            <input
                              type="email"
                              name="Email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Email}
                              disabled
                              placeholder="Email Address"
                              className="px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Email && formik.errors.Email ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Email}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Phone</label>
                            <input
                              type="text"
                              name="Phone"
                              disabled
                              readOnly
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Phone}
                              placeholder="Phone"
                              className="px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Phone && formik.errors.Phone ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Phone}
                              </div>
                            ) : null}
                          </div>

                          <div className="my-1">
                            <label for="">Business Name</label>
                            <input
                              type="text"
                              name="BusinessName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.BusinessName}
                              placeholder="Business Name"
                              className="px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.BusinessName &&
                            formik.errors.BusinessName ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.BusinessName}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">GST Number</label>
                            <input
                              type="text"
                              name="GSTNumber"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.GSTNumber}
                              placeholder="GST Number"
                              className="px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.GSTNumber &&
                            formik.errors.GSTNumber ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.GSTNumber}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Business Website</label>
                            <input
                              type="text"
                              name="BusinessWebsite"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.BusinessWebsite}
                              placeholder="Business Website"
                              className="px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.BusinessWebsite &&
                            formik.errors.BusinessWebsite ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.BusinessWebsite}
                              </div>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <label htmlFor="" className="">
                              Select Your Country{" "}
                              <span className="text-red-600">*</span>
                            </label>

                            <select
                              className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2.5  dark:bg-[#020617]"
                              name="BusinessCountry"
                              value={formik.values.BusinessCountry || ""}
                              onChange={(e) => {
                                const countryId = e.target.value;
                                formik.setFieldValue(
                                  "BusinessCountry",
                                  countryId,
                                );
                                setCountryid(countryId);
                                fetchStates(countryId);
                              }}
                            >
                              <option
                                value=""
                                label="Please Select Your Country"
                              />
                              {countries.map((country) => (
                                <option key={country.id} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            {formik.errors.Country && (
                              <div>{formik.errors.Country}</div>
                            )}
                          </div>

                          <div className="mb-3">
                            <label htmlFor="" className="">
                              Select Your State{" "}
                              <span className="text-red-600">*</span>
                            </label>

                            <select
                              className="px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2.5 border border-zinc-200 rounded-md  dark:bg-[#020617]"
                              name="BusinessState"
                              value={formik.values.BusinessState || ""}
                              onChange={(e) => {
                                const stateId = e.target.value;
                                formik.setFieldValue("BusinessState", stateId);
                                setStateId(stateId);
                                fetchCities(stateId);
                              }}
                              disabled={!countryid}
                            >
                              <option
                                value=""
                                label="Please Select Your State"
                              />
                              {states.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            {formik.errors.State && (
                              <div>{formik.errors.State}</div>
                            )}
                          </div>

                          <div className="mb-3">
                            <label htmlFor="" className="">
                              Select Your City{" "}
                              <span className="text-red-600">*</span>
                            </label>

                            <select
                              className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2.5 dark:bg-[#020617]"
                              name="BusinessCity"
                              value={formik.values.BusinessCity || ""}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "BusinessCity",
                                  e.target.value,
                                )
                              }
                              disabled={!stateid}
                            >
                              <option
                                value=""
                                label="Please Select Your City"
                              />
                              {cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              ))}
                            </select>
                            {formik.errors.City && (
                              <div>{formik.errors.City}</div>
                            )}
                          </div>

                          <div className="my-1">
                            <label for="">Profile Picture</label>
                            <input
                              type="file"
                              name="ProfileImage"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "ProfileImage",
                                  event.currentTarget.files[0],
                                );
                              }}
                              onBlur={formik.handleBlur}
                              className="text-sm text-grey-500 file:mr-5 mt-3 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold file:text-white
                                file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none border-0 rounded"
                            />
                            {formik.touched.ProfileImage &&
                            formik.errors.ProfileImage ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.ProfileImage}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="my-3">
                          <label for="">Business Address</label>
                          <textarea
                            rows="3"
                            cols=""
                            name="BusinessAddress"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.BusinessAddress}
                            placeholder="Business Address"
                            className="px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          ></textarea>
                          {formik.touched.BusinessAddress &&
                          formik.errors.BusinessAddress ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.BusinessAddress}
                            </div>
                          ) : null}
                        </div>

                        <div className="flex justify-center my-3">
                          <button
                            type="submit"
                            className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-10"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvProfile;
